import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import * as URI from 'urijs';

import { BaseComponent } from '../../base.component';
import { User } from '../../shared/models/user';
import { SessionService } from '../../shared/session.service';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-preload',
  templateUrl: './preload.component.html',
  styleUrls: ['./preload.component.css']
})
export class PreloadComponent extends BaseComponent implements OnInit {
  public isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;

    this.hasFragment()
      .pipe(
        mergeMap(x => {
          return x ? this.resolveUserFromNonce() : this.resolveUserFromToken();
        }),
        take(1),
        tap(this.validate),
        takeUntil(this.unsubscribe)
      )
      .subscribe((user) => {
        this.isLoading = false;
      }, (err) => {
        this.router.navigateByUrl('log-out');
      });
  }

  private hasFragment(): Observable<boolean> {
    return this.route.fragment
      .pipe(
        map(x => {
          return !!x;
        })
      );
  }

  private resolveUserFromNonce(): Observable<User> {
    return this.route
      .fragment
      .pipe(
        map(x => URI(x).segment(-1)),
        mergeMap((token) => {
        return this.userService.nonceLogin(token);
        })
      );
  }

  private resolveUserFromToken(): Observable<User> {
    const tokenY = this.route.snapshot.queryParams['y'];
    const tokenT = this.route.snapshot.queryParams['t'];

    if (tokenY || tokenT) {
      setTimeout(() => {
        this.sessionService.setAccount(null);
      }, 100);
      return tokenY ? this.userService.accessTokenLogin(tokenY) : this.userService.impersonatedLogin(tokenT);
    }
    return this.sessionService.user;
  }

  private validate(x: User): User {
    if (!x) {
      throw new Error('User not found');
    }

    return x;
  }
}
