import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { BaseComponent } from '../../base.component';
import { SessionService } from '../../shared';
import { fadeInOut } from '../../shared/animations';
import { AnalyticsStore } from '../analytics-store.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { GuaranteeService } from '../guarantee.service';
import { Account } from '../../shared/models';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-seo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [fadeInOut()]
})
export class DashboardComponent extends BaseComponent implements OnInit {
  @ViewChild('updatePasswordModal') updatePasswordModal: TemplateRef<any>;
  public hasAnalytics: boolean;
  public guaranteePending: boolean;
  public account: Account;

  constructor(
    private analytics: AnalyticsStore,
    public sessionService: SessionService,
    private router: Router,
    private modalService: NgbModal,
    private service: AnalyticsService,
    private guaranteeService: GuaranteeService
  ) {
    super();
  }

  ngOnInit() {
    this.analytics.reload();

    zip(this.sessionService.account, this.guaranteeService.getGuarantee(), this.sessionService.user)
      .pipe(
        map((results) => {
          const user = results[2];
          if (user.needsPasswordUpdate) {
            this.modalService.open(this.updatePasswordModal).result
              .then(
                (accept) => this.router.navigateByUrl('user/change-password'),
                (dismiss) => {
                  user.needsPasswordUpdate = false;
                  this.sessionService.setUser(user);
                });
          }

          this.account = results[0];
          if (this.account) {
            this.service.loadVisitsRolling(this.account.id);
          }
          this.hasAnalytics = !!this.account && this.account.hasGoogleAnalyticsAccess;

          const guarantee = results[1];
          if (guarantee) {
            const today = new Date();
            this.guaranteePending = !guarantee.startDate || guarantee.startDate > today;
          }
        })
      ).subscribe();
  }
}
