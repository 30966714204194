import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { SessionService } from '../../shared';

@Component({
  selector: 'app-not-ready',
  templateUrl: './not-ready.component.html',
  styleUrls: ['./not-ready.component.scss']
})
export class NotReadyComponent extends BaseComponent implements OnInit {

  public workItems: { type: string, description: string }[] = [];

  private seoWork: { type: string, description: string }[] = [
    { type: 'pre_audit', description: 'pre_audit_description' },
    { type: 'keyword_research', description: 'keyword_research_description' }
  ];

  constructor(private sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        this.workItems = [];
        if (x && !x.seoReady) {
          this.workItems = this.workItems.concat(this.seoWork);
        }
      });
  }
}
