import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-total-keywords-improved',
  templateUrl: './total-keywords-improved.component.html',
  styleUrls: ['./total-keywords-improved.component.css']
})
export class TotalKeywordsImprovedComponent extends BaseComponent implements OnInit {

  public totalKeywordsImproved: number;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) {
          return;
        }

        this.totalKeywordsImproved = x.totalKeywordsImproved;
      });
  }
}
