<p>
  {{ 'campaign_not_ready' | translate }}
</p>
<div class="card">
  <div class="card-body">
    <h4 class="custom-tile-header d-inline text-primary">{{ 'setup_work_table_title' | translate }}</h4>
    <table class="table table-striped mt-3">
      <tbody>
        <ng-container *ngFor="let i of workItems">
          <tr>
            <td>
              {{ i.type | translate }}
            </td>
            <td>
              {{ i.description | translate }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>