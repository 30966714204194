<div class="card mt-3">
  <div class="card-body">
    <h1>Privacy Policy</h1>
    <hr>

    <h4>1. Effective Date</h4>
    <span>This privacy policy is effective as of September 2019.</span>

    <h4>2. Scope of this Privacy Policy</h4>
    <span>Your privacy is important to us and our Privacy Policy outlines how we protect and respect the privacy of our clients
    and users. This privacy policy applies to your use of MyMarketInfo’s website, products/services, and/or applications. It
    does not apply to your use of other sites, services, or applications, and it does not apply to information we collect
    through other methods or sources such as sites owned or operated by our affiliates or vendors. Please be sure to review
    the privacy policy of any site, service, or application that you use as privacy practices often differ. By using our
    services, website, and website tools you agree to the collection and use of the information you provide as outlined in
    our Privacy Policy.</span>

    <h4>3. Collecting Personally Identifiable Information</h4>
    <span>
      <span>We may collect, store, and use the following kinds of personal information:</span>
      <ul>
        <li>Information you provide to us when you register with our website, products/services, and/or applications including
        your first name, last name, e-mail address, physical address(es), telephone number(s), company name, website URL(s), job
        title, years in business, product interest, marketing budget, industry, department, and number of employees.</li>
        <li>Information you provide when you subscribe to a newsletter or other periodic report or notification that we provide,
        including first and last names and an email address.</li>
        <li>Information about your visits to and use of the website, products/services, and/or applications including how you use
        these such as the timing, length, frequency, and pattern of use, and the pages, screens, or other displays of
        information looked at by the user.</li>
        <li>Information that you provide to our Client Services Representatives for publication on the Internet, including first
        name, last name, e-mail address, physical address(es), telephone number(s), company name, company history, employee
        history or biographies, website URL(s), and/or images.
        MyMarketInfo uses the information you provide us in the following general ways: service provision, service improvement,
        contact, research and promotion.</li>
      </ul>
    </span>

    <h4>4. Email Policy</h4>
    <span>If you provide MyMarketInfo with your email address, you may be contacted for marketing and campaign update purposes.
    Please be aware that a member of our client success team may manually contact you via email for administrative or
    informational purposes, including follow-up messages regarding business transactions between MyMarketInfo and yourself.
    By law, such messages are not considered to be commercial e-mail.</span>

    <h4>5. Disclosing Personally Identifiable Information to Third Parties</h4>
    <span>
      <span>MyMarketInfo will not sell your email address and other personal information to any third parties. We may disclose
      personally identifiable information we collect from you to the following third parties, for the purposes specified:</span>
      <ul>
        <li>Agents. We use a third party payment processor (when applicable). If you sign up for our products/services we will
        share your first name, last name, physical address and credit card information with the credit card processing agent.
        These agents can retain, store, and use this information only for the purpose of making the recurring monthly payments
        necessary to retain your products/services with MyMarketInfo as outlined in your product/services contract.</li>
        <li>Service Providers. We use third parties to provide some online publishing services, business citation management,
        website hosting, customer relationship management, marketing analytics, and keyword research tools. If you sign up for
        our products/services, we will share information about your business and industry to the extent necessary in order for
        the third party to provide those services. These service providers do not retain, store, or use the information for any
        other purposes.</li>
        <li>Other Third Parties. If you sign up for our products/services, we will disclose information about your business to
        search engines in order to provide our services. These third parties do retain, store, and use that information in
        various other ways. When information is shared with search engines it is then subject to their terms of use.</li>
        <li>Other Disclosures. We may also disclose personally identifiable information we collect from you when we are required
        to do so by law, or when we believe that disclosure is necessary to protect our rights or to comply with a judicial
        proceeding, court order, or legal process served on our website, products/services, and/or applications.
        We store your data in a secure database provided by third-party vendors and hosting partners that is equipped with the
        necessary hardware, software and storage to perform the services you request.
        We take appropriate physical, electronic, and other security measures to help safeguard personal information from
        unauthorized access, alteration, or disclosure.</li>
      </ul>
    </span>

    <h4>6. Making Changes to Your Information</h4>
    <span>MyMarketInfo’s products/services, and/or applications permit you to update the personally identifiable information that
    we have collected from you that is specifically used within the services provided to you by MyMarketInfo. You can make
    updates to this information by contacting customer support. Updates can be made to your first name, last name, e-mail
    address, physical address(es), telephone number(s), company name, website URLs, and industry, which may be used in the services provided to you by MyMarketInfo. <br>
    MyMarketInfo’s website does not maintain a process by which you can review and make changes to the personally
    identifiable information we collect from you.</span>

    <h4>7. Changes to this Privacy Policy</h4>
    <span>We may modify this privacy policy at any time. If we do, we will post the revised version here. You should periodically
    check here for the most up-to-date version of this privacy policy. Any changes to the privacy policy will not be
    retroactively applied and will not alter how we handle personally identifiable information we previously collected from
    you.</span>

    <h4>8. Response to Do Not Track Signals</h4>
    <span>Certain web browsers may provide an option by which you may have the browser inform websites or internet services you
    visit that you do not wish to have personally identifiable information about your activities tracked by cookies or other
    persistent identifiers across time and across third-party Internet websites, online or cloud computing services, online
    applications, or mobile applications. These are commonly called "do not track" signals. Our website, service, or
    application is unable to take action to respond to such signals but will allow you to continue without using cookies or
    other information gathering tools if those tools are blocked. <br>
    We do not allow third parties to collect personally identifiable information about a user’s online activities, over time
    and across different sites, services, and applications, when that user uses our site, service, or application.
    MyMarketInfo uses cookies, tags, and other similar technologies. We use the information we collect to enhance your visit
    to MyMarketInfo.<br>
    You may elect to refuse cookies. Please refer to your browser Help instructions to learn more about cookies and how you
    can opt out and delete these cookies.</span>

    <h4>9. Visiting our Website from Outside the United States</h4>
    <span>This Privacy Policy is intended to cover collection of information on our website from residents of the United States.
    If you are visiting our website from outside the United States, please be aware that your information may be transferred
    to, stored, and processed in the United States where our servers are located and our central database is operated. The
    data protection and other laws of the United States and other countries might not be as comprehensive as those in your
    country. By using our services, you understand that your information may be transferred to our facilities and those
    service providers with whom we share it as described in this privacy policy.</span>

  </div>
</div>
