import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SessionProhibited, SessionRequired } from '../shared';
import { UserContactPermitted } from '../shared/account-guards';
import { ReloadRequired } from '../shared/reload-guard';
import { SharedModule } from '../shared/shared.module';
import {
  ChangePasswordComponent,
  ContactComponent,
  ForgotPasswordComponent,
  GoogleAccountComponent,
  GoogleService,
  LoginComponent,
  ProfileComponent,
  TermsComponent,
  WebsiteAccessComponent,
  WebsiteCredentialsComponent,
  SeoContentSurveyComponent
} from './';
import { LogoutComponent } from './logout/logout.component';
import { NonceService } from './nonce.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { GoogleAnalyticsSelectComponent } from './profile/google-analytics-select/google-analytics-select.component';
import { GoogleExchangeComponent } from './profile/google-exchange/google-exchange.component';
import { TermsService } from './terms.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'user',
    canActivateChild: [ReloadRequired],
    children: [
      {
        path: 'website-access',
        component: WebsiteAccessComponent,
        canActivate: [SessionRequired]
      },
      {
        path: 'seo-content-survey',
        component: SeoContentSurveyComponent,
        canActivate: [SessionRequired]
      },
      {
        path: 'contact-us',
        component: ContactComponent,
        canActivate: [SessionRequired]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [SessionRequired]
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [SessionRequired]
      },
      {
        path: 'google/accounts',
        component: GoogleAnalyticsSelectComponent
      }
    ]
  },
  {
    path: 'network',
    canActivateChild: [ReloadRequired],
    children: [
      {
        path: 'selection/6',
        component: GoogleExchangeComponent
      }
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [SessionProhibited, UserContactPermitted, ReloadRequired]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [SessionProhibited, UserContactPermitted, ReloadRequired]
  },
  {
    path: 'log-in',
    component: LoginComponent,
    canActivate: [SessionProhibited, ReloadRequired]
  },
  {
    path: 'log-out',
    component: LogoutComponent,
    canActivate: [ReloadRequired]
  },
  {
    path: 'login',
    redirectTo: 'log-in'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'accessibility-statement',
    component: AccessibilityStatementComponent
  }
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule
  ],
  exports: [LoginComponent, ProfileComponent, RouterModule],
  providers: [
    TermsService,
    GoogleService,
    NonceService,
    UserContactPermitted
  ],
  declarations: [
    WebsiteAccessComponent,
    WebsiteCredentialsComponent,
    SeoContentSurveyComponent,
    ChangePasswordComponent,
    ContactComponent,
    GoogleAccountComponent,
    LoginComponent,
    ProfileComponent,
    TermsComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    GoogleAnalyticsSelectComponent,
    GoogleExchangeComponent,
    PrivacyPolicyComponent,
    ResetPasswordComponent
  ]
})

export class UserModule { }
