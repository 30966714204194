


import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ProTip } from './models';
import { SessionService } from './session.service';

@Injectable()
export class ProTipService {
    private baseUrl: string;
    constructor(private http: HttpClient, private sessionService: SessionService, private translate: TranslateService) {
        this.baseUrl = environment.baseUrl;
    }

    public get(): Observable<ProTip[]> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    const query = new HttpParams().set('language', this.translate.currentLang);
                    return !!x ? this.http.get<ProTip[]>(`${this.baseUrl}account/${x.id}/protips`, { params: query }) : of([]);
                })
            );
    }
}
