<div class="row">
  <div class="col-md-3">
    <app-about [type]="type"></app-about>
  </div>
  <div class="col-md-9">
    <div class="card mb-4">
      <div class="card-body">
        <h5  class="card-title">{{ 'content' | translate }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ 'keyword' | translate }}: {{value.title}}</h6>
        <hr />
        <div [innerHTML]="value.body"></div>
      </div>
    </div>
  </div>