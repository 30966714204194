import { curry } from 'barely';

export function sum<T>(property: (y: T) => number, ys: T[]): number {
    if (ys.length === 0) { return 0; }

    const add = (x, y) => x + y;
    const result = ys.map(property).reduce(add);
    return result;
}

export const sumAll: (items: number[]) => number = curry(sum)(x => x);
