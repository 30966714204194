import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Maybe } from 'barely';
import { BaseComponent } from '../../base.component';
import { Account, SiteConfig } from '../../shared/models';
import { Features } from '../../shared/models/features.enum';
import { SessionService } from '../../shared/session.service';
import { englishLanguages } from '../../shared/language-selector/languages';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent extends BaseComponent implements OnInit {
  @Input() hasExternalToolbar: boolean;
  @Output() navigated: EventEmitter<any> = new EventEmitter();

  Features = Features;
  today = new Date();
  account: Account;
  sessionIsReady: boolean;
  siteConfig: SiteConfig;
  sessionIsAuthorized: boolean;
  canSwitchAccounts: boolean;
  isEnglishLanguage: boolean;
  isAccessibilityWidgetAppended: boolean;
  hideSeoListings: boolean = true;
  hideBusinessCitations: boolean = true;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    public sessionService: SessionService) {
    super();
  }

  featureVisibility: Record<Features, { hasFeature: boolean, isActive: boolean }> = {
    [Features.SEO]: { hasFeature: false, isActive: false },
    [Features.BusinessCitations]: { hasFeature: false, isActive: false },
    [Features.BusinessProfileManagement]: { hasFeature: false, isActive: false },
    [Features.ProfessionalBacklink]: { hasFeature: false, isActive: false }
  };

  ngOnInit() {
    this.sessionService.isReady()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((sessionIsReady) => {
        this.sessionIsReady = sessionIsReady;
      });

    this.sessionService.siteConfig
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((siteConfig) => {
        this.siteConfig = siteConfig;

        if (this.siteConfig && this.siteConfig.hiddenContent) {
          const seoCitationsKey = 'Seo-Business-Citations';
          const bpmKey = 'BusinessProfileManagement';

          this.hideSeoListings = this.siteConfig.hiddenContent.some(x => x === seoCitationsKey);
          this.hideBusinessCitations = this.siteConfig.hiddenContent.some(x => x === bpmKey);
          this.configureAccessibilityVisibility();
        }
      });

    this.sessionService.isAuthorized()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((sessionIsAuthorized) => {
        this.sessionIsAuthorized = sessionIsAuthorized;
      });

    this.sessionService.canSwitchAccounts
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((canSwitchAccounts) => {
        this.canSwitchAccounts = canSwitchAccounts;
      });


    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((account: Account) => {
        this.account = account;
        this.setFeatureVisbility(this.account);
      });
  }

  onNavigation() {
    this.navigated.emit(null);
  }

  logoff() {
    this.router.navigateByUrl('log-out');
  }

  switchAccounts() {
    this.navigated.emit(null);
    this.router.navigateByUrl('accounts');
  }

  toggleFeature(feature: Features) {
    this.featureVisibility[feature].isActive = !this.featureVisibility[feature].isActive;
  }

  private setFeatureVisbility(account: Account) {
    if (!account || !account.hasFeature) {
      return;
    }

    this.featureVisibility[Features.SEO].hasFeature = account.hasFeature(Features.SEO);
    this.featureVisibility[Features.BusinessCitations].hasFeature = account.hasFeature(Features.BusinessCitations);
    this.featureVisibility[Features.BusinessProfileManagement].hasFeature = account.hasFeature(Features.BusinessProfileManagement);
    this.featureVisibility[Features.ProfessionalBacklink].hasFeature = account.hasFeature(Features.ProfessionalBacklink);
    for (let feature of Object.values(this.featureVisibility)) {
      feature.isActive = feature.hasFeature;
    }
  }

  private configureAccessibilityVisibility() {
    if (this.isAccessibilityWidgetAppended) {
      return;
    }

    this.isEnglishLanguage = englishLanguages.indexOf(this.sessionService.locale) !== -1;

    const accessibilityHideKey = 'SIDEBAR-ACCESSIBILITY';
    const accessibilityIsHidden = this.siteConfig.hiddenContent.some(x => x.toUpperCase() === accessibilityHideKey);

    if (this.isEnglishLanguage && !accessibilityIsHidden && !this.isAccessibilityWidgetAppended) {
      this.isAccessibilityWidgetAppended = true;
      this.appendAccessibilityWidget();
    }
  }

  private appendAccessibilityWidget() {
    const widgetScript = document.createElement('script');
    widgetScript.type = 'text/javascript';
    widgetScript.src = 'assets/accessibility.js';
    this.elementRef.nativeElement.appendChild(widgetScript);
  }
}