import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from '../../base.component';
import { SessionService } from '../../shared/session.service';
import { UserService } from '../../shared/user.service';
import { SiteConfig } from '../../shared/models';
import { take, takeUntil } from 'rxjs/operators';
import Password from '../password';

@Component({
  selector: 'app-user-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
  loginForm: UntypedFormGroup;
  didFail = false;
  siteConfig: SiteConfig;
  newUser = false;

  constructor(
    fb: UntypedFormBuilder,
    private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public sessionService: SessionService) {
    super();
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.newUser = this.route.snapshot.queryParams['newuser'];

    this.sessionService.siteConfig
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(siteConfig => {
        this.siteConfig = siteConfig;
      });
  }

  public login(event: Event): void {
    event.preventDefault();

    this.didFail = false;

    if (this.loginForm.invalid) {
      return;
    }

    const username = this.loginForm.controls['email'].value;
    const password = this.loginForm.controls['password'].value;

    this.service.login(username, password)
      .subscribe(
        x => x ? this.handleSuccessfulLogin(password) : this.sessionService.clear(),
        (err) => this.didFail = true);
  }

  private handleSuccessfulLogin(password: string): void {
    if (!Password.valid(password)) {
      this.sessionService.user
        .pipe(
          take(1),
          takeUntil(this.unsubscribe)
        ).subscribe(user => {
          user.needsPasswordUpdate = true;
          this.sessionService.setUser(user);
          this.router.navigateByUrl('accounts');
        });
    } else {
      this.router.navigateByUrl('accounts');
    }
  }
}
