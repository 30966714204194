<div class="row">
  <div class="col-md-3">
    <app-about [type]="type"></app-about>
    <div class="card mt-2" *ngIf="value.download">
      <div class="card-body d-grid d-gap">
        <a class="btn btn-primary" *ngIf="!isDownload(value.download)" href="{{value.download}}" target="_blank" ><span class="fa fa-external-link-square-alt"></span> {{ 'button_view' | translate }}</a>
        <a class="btn btn-primary" *ngIf="isDownload(value.download)" href="{{value.download}}" target="_blank"><span class="fa fa-download"></span> {{ 'download' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-md-9">
    <div class="card mb-4">
      <div class="card-body">
        <h5 *ngIf="value.title" class="card-title">{{value.title}}</h5>
        <h5 *ngIf="!value.title" class="card-title">{{ 'content' | translate }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{value.url}}</h6>
        <hr />
        <h1 *ngIf="value.h1">{{value.h1}}</h1>
        <h2 *ngIf="value.h2">{{value.h2}}</h2>
        <div [innerHTML]="value.body"></div>
      </div>
    </div>
  </div>