import { Component, OnInit, Input } from '@angular/core';
import { WebsiteCopyApproval } from '../../../../shared/models/required-actions/website-copy-approval';

@Component({
  selector: 'app-website-copy-approval',
  templateUrl: './website-copy-approval.component.html',
  styleUrls: ['./website-copy-approval.component.css']
})
export class WebsiteCopyApprovalComponent implements OnInit {
  @Input() requiredAction: WebsiteCopyApproval;

  public isDescriptionCollapsed: boolean;

  constructor() { }
  ngOnInit() {
    this.isDescriptionCollapsed = true;
  }
}
