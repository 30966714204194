import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { BaseComponent } from '../../../base.component';
import { AnalyticsService } from '../analytics.service';
import { DataPoint } from '../models/data-point';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-by-city',
  templateUrl: './by-city.component.html',
  styleUrls: ['./by-city.component.css'],
  providers: [TranslatePipe]
})
export class ByCityComponent extends BaseComponent implements OnInit {
  public hasData = false;
  public chartData: Array<any> = [{ data: [], label: '' }];
  public chartLabels: Array<string> = [];
  public chartOptions: any = {
    responsive: true,
    tooltips: { enabled: true },
    scales: {
      xAxes: [{ display: true, barPercentage: 0.2 }],
      yAxes: [{
        display: true,
        ticks: { beginAtZero: true }
      }]
    }
  };
  public chartColors: Array<any>;

  constructor(private service: AnalyticsService, private translatePipe: TranslatePipe, private chartColorService: ChartColorService) {
    super();
  }

  ngOnInit() {
    this.chartColorService.bar
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
      });
    this.getVisitsByCity();
  }

  private getVisitsByCity(): void {
    this.service.visitsByCity
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x || !x.wasSuccessful) {
          return;
        }

        this.loadData(x.data);
      });
  }

  private loadData(dataPoints: Array<DataPoint>): void {
    this.hasData = false;
    this.chartData = [{ data: dataPoints.map(y => y.value), label: this.translatePipe.transform('visits_by_city') }];
    this.chartLabels = dataPoints.map(y => y.key);
    setTimeout(() => this.hasData = true, 0);
  }
}
