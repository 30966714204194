import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import {
    AccountService,
    ChooseCustomerComponent,
    ErrorComponent,
    HeaderComponent,
    NavigationComponent,
    NotFoundComponent,
    PreloadComponent,
    RoutingModule,
    LoadingOverlayComponent,
    ExternalToolbarComponent
} from './';
import { ExternalErrorComponent } from './external-error/external-error.component';
import { ReloadRequired } from '../shared/reload-guard';
import { HomepageComponent } from './homepage/homepage.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NoProductsLandingPageComponent } from './no-products-landing-page/no-products-landing-page.component';

const routes: Routes = [
    {
        path: 'not-found',
        component: NotFoundComponent,
        canActivateChild: [ReloadRequired]
    },
    {
        path: 'error',
        component: ErrorComponent,
        canActivateChild: [ReloadRequired]
    },
    {
        path: 'business-center-error',
        component: ExternalErrorComponent,
        canActivateChild: [ReloadRequired]
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '**',
        redirectTo: '/not-found',
        canActivateChild: [ReloadRequired]
    }
];

@NgModule({
    imports: [FormsModule,
        RoutingModule,
        SharedModule,
        CommonModule,
        RouterModule.forRoot(routes, {}),
        NgbCollapseModule,
        NgbModule],
    providers: [AccountService,
        DatePipe],
    declarations: [
        NavigationComponent,
        HeaderComponent,
        LoadingOverlayComponent,
        PreloadComponent,
        ChooseCustomerComponent,
        NotFoundComponent,
        ErrorComponent,
        ExternalErrorComponent,
        ExternalToolbarComponent,
        HomepageComponent,
        MaintenanceComponent,
        NoProductsLandingPageComponent],
    exports: [RoutingModule,
        NavigationComponent,
        HeaderComponent,
        LoadingOverlayComponent,
        ExternalToolbarComponent]
})
export class StructureModule { }
