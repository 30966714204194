export const categories = {
    linkPortfolioDevelopment: {
        translation: 'link_portfolio_development',
        id: '66834C11-9080-4DF2-84FB-69D57EF81722',
        sort: 1
    },
    websiteOptimization: {
        translation: 'website_optimization',
        id: '94593DE7-7B1B-4D0C-BEF1-28774A5B742C',
        sort: 2
    },
    businessProfileDevelopment: {
        translation: 'business_profile_development',
        id: '412170E8-7446-436E-A927-CD7827CAB904',
        sort: 3
    },
    customContentCreation: {
        translation: 'custom_content_creation',
        id: '8DBF53D4-81FC-431C-9E0B-5797D76D5FDA',
        sort: 4
    },
    seoConsultation: {
        translation: 'seo_consultation',
        id: '9C6816FC-1D24-4A87-8FE9-6079513703B6',
        sort: 5
    }
};

export const categoryMap: { translation: string, id: string, sort: number }[] = toArray(categories);

export function category(id: string): { translation: string, id: string, sort: number } {
    const cats = categoryMap.filter(x => x.id.toLocaleLowerCase() === id.toLocaleLowerCase());
    return cats.length > 0 ? cats[0] as any : null;
}

/**
 *
 * @param anObject Converts an object to an array.
 */
function toArray(anObject: any): any[] {
    const props = [];

    for (const prop in anObject) {
        if (Object.prototype.hasOwnProperty.call(anObject, prop)) {
            props.push(anObject[prop]);
        }
    }

    return props;
}

