import { Component, OnInit } from '@angular/core';
import { Account } from '../../shared/models';
import { fadeInOut, SessionService } from '../../shared/index';
import { BaseComponent } from '../../../app/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  animations: [fadeInOut()],
  selector: 'app-seo-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent extends BaseComponent implements OnInit {

  public account: Account;

  constructor(public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });
  }
}
