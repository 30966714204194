import { Component, Input } from '@angular/core';

import { isUploadedToS3 } from '../../../../shared/s3Check';
import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements IWorkItemComponent {
  private _value: any;

  @Input() public type: number;

  get value() {
    return this._value;
  }

  @Input() set value(v: any) {
    this._value = v;

    const body = this._value.body as string;

    if (this.hasArticlePendingFormat(body)) {
      const index = body.indexOf('Submitted Text:');
      this._value.body = body.substring(0, index) + '<br />' + body.substring(index, body.length);
    }
  }

  public isDownload(url: string) {
    return isUploadedToS3(url);
  }

  private hasArticlePendingFormat(value: string) {
    return /Target Keyword:/.test(value) && /Submitted Text:/.test(value);
  }
}
