<div class="row">
  <div class="col-8">
    <div class="card mt-4 p-4">
      <div class="card-body">
        <h1 class="card-title text-primary custom-tile-header">Your Data Visualized</h1>

        <h5>{{ 'site_purpose' | translate}}</h5>

        <div class="row my-4">
          <div class="col-6">
            {{ 'site_description_ga' | translate }}
          </div>
          <div class="col-6">
            {{ 'site_description_campaign' | translate }}
            <button class="btn btn-primary w-100 btn-lg mt-5" routerLink="/login">
              <i class="fa fa-sign-in-alt me-2"></i>{{ 'button_log_in' | translate }}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
