import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-current-rank',
  templateUrl: './current-rank.component.html',
  styleUrls: ['./current-rank.component.css']
})
export class CurrentRankComponent extends BaseComponent implements OnInit {

  public currentRank: number;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) {
          return;
        }

        this.currentRank = x.currentRank;
      });
  }
}
