<div class="card">
  <div class="card-body">
    <h4 class="custom-tile-header text-primary">{{ 'distribution_work_by_category' | translate }}</h4>
    <p class="custom-tile-subheader">{{ 'all_time_breakdown_work_completed' | translate }}</p>
    <div class="row">
      <div class="col-md-6 d-flex" style="max-height: 360px;">
        <canvas class="mx-auto my-auto" [ngClass]="{ 'muted': !hasData, 'loading': isLoading }"
          baseChart height="750" [data]="data" [type]="'pie'" [options]="options" [legend]="false">
        </canvas>
      </div>
      <div class="col-md-6" *ngIf="isLoading">
        <div>
          <h6><i class="fa fa-circle muted"></i> {{ 'please_wait' | translate }}</h6>
          <p>{{ 'searching_the_web' | translate }}</p>
        </div>
      </div>
      <div class="col-md-6" *ngIf="noResults">
          <div>
            <h6><i class="fa fa-exclamation-circle muted"></i> {{ 'not_found' | translate }}</h6>
            <p>{{ 'cant_find_details' | translate }}</p>
          </div>
        </div>
      <div class="col-md-6" *ngIf="results">
        <div>
          <h6><i [ngStyle]="{ 'color': categoryColors[cats.linkPortfolioDevelopment.sort - 1] }" class="fa fa-circle"></i> {{ cats.linkPortfolioDevelopment.translation | translate }}</h6>
          <p>{{ 'link_portfolio_development_desc' | translate }}</p>
        </div>

        <div>
          <h6><i [ngStyle]="{ 'color': categoryColors[cats.websiteOptimization.sort - 1] }" class="fa fa-circle"></i> {{ cats.websiteOptimization.translation | translate }}</h6>
          <p>{{ 'website_optimization_desc' | translate }}</p>
        </div>

        <div>
          <h6><i [ngStyle]="{ 'color': categoryColors[cats.businessProfileDevelopment.sort - 1] }" class="fa fa-circle"></i> {{ cats.businessProfileDevelopment.translation | translate }}</h6>
          <p>{{ 'business_profile_development_desc' | translate }}</p>
        </div>

        <div>
          <h6><i [ngStyle]="{ 'color': categoryColors[cats.customContentCreation.sort - 1] }" class="fa fa-circle"></i> {{ cats.customContentCreation.translation | translate }}</h6>
          <p>{{ 'custom_content_creation_desc' | translate }}</p>
        </div>

        <div>
          <h6><i [ngStyle]="{ 'color': categoryColors[cats.seoConsultation.sort - 1] }" class="fa fa-circle"></i> {{ cats.seoConsultation.translation | translate }}</h6>
          <p>{{ 'seo_consultation_desc' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
