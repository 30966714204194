import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../base.component';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BaseComponent {
  public form: UntypedFormGroup;
  public sent = false;

  constructor(fb: UntypedFormBuilder, private userService: UserService) {
    super();

    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      return;
    }

    this.userService.forgot(this.form.controls['email'].value)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.form.reset();
        this.sent = true;
      });
  }
}
