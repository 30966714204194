/* tslint:disable */
import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[tileContent]' })
export class TileContent {

  constructor(public tplRef: TemplateRef<any>) { }
}

@Component({
  selector: 'tile',
  exportAs: 'Tile',
  templateUrl: './tile.directive.html'
})
export class TileComponent {
  /**
   * Sets the main title for the tile.
   */
  @Input() title: string;


   /**
   * Sets a help for the tile.
   */
  @Input() help: string;

  /**
   * Sets a subtitle for the tile.
   */
  @Input() subtitle: string;

  @Input() subHelp: string;

  /**
   * Sets the direction the metric is moving (positive, negative, or neutral)
   */
  @Input() mode: 'positive' | 'negative' | 'neutral';

  /**
   * Sets a value of the metric
   */
  @Input() val: string;

  /**
  * Sets the icon for the metric
  */
  @Input() icon: string;

  /**
  * Sets a string to indicate the change in the metric (34% MTD and so on)
  */
  @Input() change: string;

  @Input() showChange: boolean;

  /**
   * Allows any content to be rendered in the tile
   */
  @ContentChild(TileContent, { read: TemplateRef }) bodyTemplate;

  public colorClassMap = {
    'positive': 'text-success',
    'neutral': '',
    'negative': ''
  };

  constructor() { }

}
