import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'app-external-error',
  templateUrl: './external-error.component.html',
  styleUrls: ['./external-error.component.scss']
})
export class ExternalErrorComponent extends BaseComponent implements OnInit {
  public showGeneralError = false;
  public showNotSetupError = false;
  public showAccountNotExistsError = false;
  public showInactiveAccountError = false;
  public showNotAuthorizedError = false;

  constructor(private route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((params: Params) => {
          switch (params['errorType']) {
            case 'notSetup':
              this.showNotSetupError = true;
              break;
            case 'accountNotExist':
              this.showAccountNotExistsError = true;
              break;
            case 'notAuthorized':
              this.showNotAuthorizedError = true;
              break;
            case 'inactiveAccount':
              this.showInactiveAccountError = true;
              break;
            default:
              this.showGeneralError = true;
              break;
          }
      });
    }
}
