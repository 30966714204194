import { state, style, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../base.component';
import { SiteConfig } from '../../shared/models';
import { SessionService } from '../../shared/session.service';
import { PendingRequestInterceptor } from '../../shared/pending-request-interceptor';
import { bufferTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.css'],
  animations: [
    trigger('visibilityChanged', [
      state('false', style({ transform: 'scale(0)' }))
    ])
  ]
})
export class LoadingOverlayComponent extends BaseComponent implements OnInit {
  public isLoading: boolean;

  constructor(interceptor: PendingRequestInterceptor,
    public sessionService: SessionService) {
    super();
    this.isLoading = false;
    interceptor.isLoading
      .pipe(
        bufferTime(500),
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x || !x.length) {
          return;
        }

        this.isLoading = x[x.length - 1];
      });
  }

  public siteConfig: SiteConfig;

  ngOnInit() {
    this.sessionService.siteConfig
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((siteConfig) => {
        this.siteConfig = siteConfig;
      });
  }
}
