<div class="row" [@fadeInOut]="'active'">
  <div class="col-md-12">
    <div class="card" *ngIf="account; let account">
      <div class="card-header">
        <h6 class="mb-0">{{ 'contact_description' | translate }} </h6>
      </div>
      <div class="card-body">
        <div class="row ms-4" *hideKey="'ContactUs-Phone'">
          <span class="col-2">
            <i class="fa fa-phone"></i> {{'contact_phone_support_label' | translate }}</span>
          <div class="col-8 form-label">
            {{ account?.supportPhoneNumber }}
          </div>
        </div>
        <div class="row ms-4" *hideKey="'ContactUs-Email'">
          <span class="col-2">
            <i class="fa fa-envelope"></i> {{'contact_email_support_label' | translate }}</span>
          <div class="col-8">
            <a href="mailto:{{ account?.supportEmail }}">{{ account?.supportEmail }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row pt-2" [@fadeInOut]="'active'">
  <div class="col-md-12">
    <div class="card alert alert-success" *ngIf="state === 'in'" [@fadeInOut]="state">
      <div class="card-body">
        <div class="">{{ 'contact_success' | translate }}</div>
      </div>
    </div>
    <div class="card" *ngIf="state === 'out'">
      <div class="card-body">
        <form class="form-signin" [formGroup]="contactForm" (submit)="send($event, submitBtn)" novalidate>
          <h2 class="form-signin-heading">{{ 'contact_us' | translate }}</h2>
          <div class="form-group mb-2">
            <label for="inputName" class="sr-only">{{ 'contact_name_label' | translate }}</label>
            <input type="text" id="inputName" class="form-control" placeholder="{{ 'contact_name_label' | translate }}" required autofocus=""
              autocomplete="off" formControlName="name" [ngClass]="{'is-invalid' : contactForm.controls['name'].dirty && contactForm.controls['name'].invalid }">
            <div class="invalid-feedback">
              {{ 'contact_name_invalid' | translate }}
            </div>
          </div>
          <div class="form-group mb-2">
            <label for="inputEmail" class="sr-only">{{ 'email' | translate }}</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="{{ 'email' | translate }}" required autofocus="" autocomplete="off"
              formControlName="email" [ngClass]="{'is-invalid' : contactForm.controls['email'].dirty && contactForm.controls['email'].invalid }">
            <div class="invalid-feedback">
              {{ 'email_required' | translate }}
            </div>
          </div>
          <div class="form-group mb-2">
            <label for="inputPhone" class="sr-only">{{ 'contact_phone_label' | translate }}</label>
            <input type="text" id="inputPhone" class="form-control" placeholder="{{ 'contact_phone_label' | translate }}" formControlName="phone"
              [ngClass]="{'is-invalid' : contactForm.controls['phone'].dirty && contactForm.controls['phone'].invalid }">
            <div class="invalid-feedback">
              {{ 'contact_phone_invalid' | translate }}
            </div>
          </div>
          <div class="form-group mb-2">
            <label for="inputMessage" class="sr-only">{{ 'contact_message_label' | translate }}</label>
            <textarea class="form-control" autofocus rows="3" required placeholder="{{ 'contact_message_label' | translate }}" formControlName="message"
              [ngClass]="{'is-invalid' : contactForm.controls['message'].dirty && contactForm.controls['message'].invalid }"></textarea>
            <div class="invalid-feedback">
              {{ 'contact_message_invalid' | translate }}
            </div>
          </div>
          <div class="d-grid">
            <button class="btn btn-lg btn-primary" type="submit" #submitBtn>{{ 'button_send' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
