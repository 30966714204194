import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GooglePin } from '../../../../shared/models/required-actions/google-pin';

@Component({
  selector: 'app-google-pin',
  templateUrl: './google-pin.component.html',
  styleUrls: ['./google-pin.component.css']
})
export class GooglePinComponent implements OnInit {
  @Input() requiredAction: GooglePin;

  public isDescriptionCollapsed: boolean;
  private redirectPath = '/user/contact-us';

  constructor(private router: Router) { }
  ngOnInit() {
    this.isDescriptionCollapsed = true;
  }

  public redirect() {
    this.router.navigate([this.redirectPath]);
  }
}
