import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Maybe } from 'barely';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { WorkService } from '../../work.service';
import { lookup } from '../types';
import { ContentComponent } from '../work-item/content/content.component';
import { NoDetailsComponent } from './no-details/no-details.component';

@Component({
  selector: 'app-work-item',
  templateUrl: './work-item.component.html',
  styleUrls: ['./work-item.component.css']
})
export class WorkItemComponent extends BaseComponent implements OnInit {
  public id: number;

  @ViewChild('target', { read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  private content: ContentComponent;

  constructor(private resolver: ComponentFactoryResolver, private route: ActivatedRoute, private work: WorkService) {
    super();
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map(x => x.get('id')),
        map(x => parseInt(x, 10)),
        mergeMap(x => {
          this.id = x;
          return this.work.details(x);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (x) {
          let type = Maybe.just(lookup(x.type))
            .or(() => {
              return { id: 0, component: NoDetailsComponent };
            });

          if (!x.value) {
            type = { id: 0, component: NoDetailsComponent };
          }

          const componentFactory = this.resolver.resolveComponentFactory(type.component);
          this.componentRef = this.target.createComponent(componentFactory);
          this.componentRef.instance.value = x.value;
          this.componentRef.instance.type = x.type;
        }
      });
  }
}
