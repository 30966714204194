import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../../shared/index';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.sessionService.isLoggingOut = true;
      this.sessionService.clear();
      window.location.replace('/log-in');
    }, 10);
  }
}
