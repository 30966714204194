import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.checkForParams();
  }

  private checkForParams(): void {
    const params = this.route.snapshot.queryParams;
    const hasParams = !!Object.keys(params).length;

    // if there are any params, i.e. Y token, pass them down and then let the preload component handle it
    if (hasParams) {
      this.router.navigate(['accounts'], { queryParams: params });
    }
  }

}
