<div class="card text-center">
    <div class="card-body d-flex flex-column">
        <h4 class="card-title text-primary text-center custom-tile-header" style="min-height:2em;">
            {{ 'total_work_completed' | translate }}
            <small>
                <helpPopover placement='top' html="{{ 'total_work_completed_help' | translate }}"></helpPopover>
            </small>
        </h4>
        <div>
            <div>
                <p class="text-muted mb-0 mt-0 custom-tile-subheader">{{ 'work_completed_all_time' | translate }} </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="p-2 text-center text-md-left d-flex flex-column h-100" *ngIf="isLoaded">
                <canvas class="chart-canvas" baseChart [datasets]="data" [labels]="labels" [options]="options" [legend]="false" [type]="'bar'"></canvas>
                </div>
            </div>
        </div>
        <div class="alert alert-info">
            {{ 'campaign_renews_on' | translate:{ nextRenewalDay: nextRenewalDate | ordinal } }}
            <helpPopover
              placement='bottom'
              html="{{ 'tooltip_renewal_example' | translate: {
                nextRenewalMonth: lastBilledDate | date:'MMMM',
                nextRenewalDay: lastBilledDate | ordinal,
                nextRenewalEndMonth: currentPeriodEndDate | date:'MMMM',
                nextRenewalEndDay: currentPeriodEndDate | ordinal } }}">
            </helpPopover>
        </div>
    </div>
</div>
