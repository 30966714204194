<nav id="custom-header" class="navbar navbar-light navbar-fixed-top navbar-expand-md mb-4 px-5 py-4" *ngIf="sessionIsAuthorized"
  (clickOutside)="clickedOutside()">
  <div *ngIf="user; let u">
    <span *hideKey="'Header-Welcome'">
      <span id="custom-welcome" class="navbar-brand d-none d-md-inline text-light">
        <span>{{ 'welcome_back' | translate }}</span><span *hideKey="'Header-Comma'">,</span><span *hideKey="'Header-Name'"> {{ u.firstName }}</span>!
      </span>
    </span>
  </div>
  <button aria-controls="navbarContent" aria-expanded="true" aria-label="Toggle navigation" class="btn-primary navbar-toggler mb-2 me-auto"
    type="button" (click)="isCollapsed = !isCollapsed">
    <span class="fa fa-bars"></span>
  </button>

  <img class="img-fluid d-md-none" src="{{ siteConfig?.logoSmallUrl }}" />

  <div class="navbar-collapse collapse w-100" id="navbarContent" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav ms-auto">
      <ng-container *ngIf="shouldAppendSurveyScript">
        <li class="nav-item d-none d-lg-inline">
          <div id='ZN_cCpILcXLHy2kXOd'><!--DO NOT REMOVE-CONTENTS PLACED HERE --></div>
        </li>
      </ng-container>
      <li class="nav-item d-none d-lg-inline" *hideKey="'Header-ContactUs'">
        <button class="btn btn-warning text-light" routerLink="/user/contact-us" *ngIf="sessionIsReady">
          <span class="fa fa-envelope"></span> {{ 'contact_us' | translate }}
        </button>
      </li>
      <li class="nav-item dropdown"  *hideKey="'Header-Profile-Menu'">
        <a class="nav-link dropdown-toggle text-light ps-4 d-none d-lg-block custom-profile-icon" (click)="isCollapsed = !isCollapsed"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="fa fa-user text-light custom-profile-icon"></span>
        </a>
        <div class="d-none d-md-block">
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink" [ngbCollapse]="isCollapsed">
            <h6 class="border border-secondary border-end-0 border-start-0 border-top-0 p-2 ps-4">{{ (account)?.name}}</h6>
            <span *hideKey="'Header-Profile'">
              <a class="dropdown-item" routerLink="/user/profile" *ngIf="sessionIsReady" (click)="isCollapsed = !isCollapsed">{{ 'profile' | translate }}</a>
            </span>
            <a (click)="switchAccounts()" class="dropdown-item btn btn-link nav-btn" *ngIf="canSwitchAccounts">{{ 'switch_accounts' | translate }}</a>
            <a (click)="logoff($event)" class="dropdown-item btn btn-link nav-btn">{{ 'log_off' | translate }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
