import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-top-keyword',
  templateUrl: './top-keyword.component.html',
  styleUrls: ['./top-keyword.component.css']
})
export class TopKeywordComponent extends BaseComponent implements OnInit {
  public topRankingKeyword: string;
  public fontSize = '32px';
  private defaultFontSize = 32;
  private minFontSize = 24;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x || !x.topRankingKeyword) {
          return;
        }

        this.topRankingKeyword = x.topRankingKeyword;
        const adjustedFontSize = Math.floor(this.defaultFontSize - (this.topRankingKeyword.length / 8));
        this.fontSize = Math.max(adjustedFontSize, this.minFontSize) + 'px';
      });
  }
}
