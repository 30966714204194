import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/en-au';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import { BaseComponent } from '../../base.component';
import { SessionService } from '../session.service';
import { environment } from '../../../environments/environment';
import { languages, Language } from './languages';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent extends BaseComponent implements OnInit {
  public lang: BehaviorSubject<string> = new BehaviorSubject(null);
  public languages = languages;
  private defaultLanguage = languages.find(l => l.name === Language.english).abbreviation;

  constructor(private translate: TranslateService, private sessionService: SessionService) {
    super();

    if (environment.production) {
      this.translate.setDefaultLang(this.defaultLanguage);
    }

    this.translate.addLangs(languages.map(l => l.abbreviation));
    this.translate.defaultLang = this.defaultLanguage;

    const browserLanguage = sessionService.locale;
    const browserRootLanguage = browserLanguage ? browserLanguage.substr(0, 2).toLowerCase() : '';

    const hasLanguage = languages.some(x => {
      return browserLanguage && x.abbreviation.toLowerCase() === browserLanguage.toLowerCase();
    });

    const hasRootLanguage = languages.some(x => {
      return browserLanguage && x.abbreviation.toLowerCase() === browserRootLanguage;
    });

    this.lang.next(hasLanguage ? browserLanguage : (hasRootLanguage ? browserRootLanguage : this.defaultLanguage));
  }

  ngOnInit() {
    this.lang
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(x => {
      if (!x) {
        x = this.defaultLanguage;
      }

      if (this.sessionService.locale !== x) {
        this.sessionService.locale = x;
        this.reload();
      }

      this.translate.use(x);
      moment.locale(x);
    });

    this.translate.onLangChange
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(x => {
      this.change(x.lang);
    });
  }

  public change(selected: string) {
    this.lang.next(selected);
  }

  public reload() {
    // Note: We need a method to mock this class.
    window.location.reload();
  }
}
