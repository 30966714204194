import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideCharts, withDefaultRegisterables, BaseChartDirective } from 'ng2-charts';
import { AngularOpenlayersModule } from 'ng-openlayers';

import { AppComponent } from './app.component';
import { AppExceptionHandler } from './app.exception.handler';
import { localeFactory } from './locale-factory';
import {
  AuthorizeRequestInterceptor,
  AuthorizeRequestInterceptorFactoryProvider,
  HttpExceptionInterceptor,
  HttpExceptionInterceptorFactoryProvider,
  PendingRequestInterceptor,
  PendingRequestInterceptorFactoryProvider,
  CustomUrlSerializer
} from './shared';
import { SessionService } from './shared/session.service';
import { SharedModule } from './shared/shared.module';
import { RoutingModule } from './structure';
import { StructureModule } from './structure/structure.module';

import { UrlSerializer, DefaultUrlSerializer } from '@angular/router';

import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeFi from '@angular/common/locales/fi';
// We don't support the Russian language but adding this to solve an issue where several of our users have their browser set to Russian.
import localeRu from '@angular/common/locales/ru';
// English-Austrailian is imported and used for date/time but other translations will fall back to English in production
import localeEnAu from '@angular/common/locales/en-AU';
import { ErrorService } from './shared/errors/error.service';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEnAu, 'en-au');

const PendingRequestInterceptorExistingProvider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: PendingRequestInterceptor,
  multi: true
};

const AuthorizeRequestInterceptorExistingProvider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: AuthorizeRequestInterceptor,
  multi: true
};

const HttpExceptionInterceptorExistingProvider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: HttpExceptionInterceptor,
  multi: true
};

//Outdated browsers, particularly on old iPhones, do not support animation and will throw errors if it is not disabled
const disableAnimations =
  !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations: disableAnimations }),
    StructureModule,
    RoutingModule,
    HttpClientModule,
    SharedModule.forRoot(),
    BaseChartDirective,
    AngularOpenlayersModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [],
  providers: [
    { provide: ErrorHandler, useClass: AppExceptionHandler },
    PendingRequestInterceptorFactoryProvider,
    PendingRequestInterceptorExistingProvider,
    AuthorizeRequestInterceptorExistingProvider,
    AuthorizeRequestInterceptorFactoryProvider,
    HttpExceptionInterceptorExistingProvider,
    HttpExceptionInterceptorFactoryProvider,
    {
      provide: LOCALE_ID,
      deps: [SessionService, ErrorService],
      useFactory: localeFactory
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    },
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
