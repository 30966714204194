import { Observable, of } from 'rxjs';
import { Maybe } from 'barely/dist';

export function queryParams(): Observable<{ [index: string]: string }> {
    // We can't rely on window.location or angular to parse the query string correctly becuase a few oAuth providers use # in their tokens.
    const r = Maybe.just(window.location.href.split('?')[1])
        .map(x => x.split('&'))
        .map(parameters => {
            return parameters.reduce((x, y) => {
                const kvp = y.split('=');
                x[kvp[0]] = decodeURIComponent(kvp[1]);
                return x;
            }, {});
        })
        .or(() => {
            return {};
        });

    return of(r);
}

/*
    All oAuth flows redirect to MMI, but we may have requested it from a white-labeled domain.
    This method checks and forces a client-side redirect if necessary.
*/
export function ensureDomain(query: { [index: string]: string }): boolean {
    const loc = window.location;

    // We store the host that started the flow as part of the token. Here are some thing that you should know:
    //  1. The query string variable is usually called state but it is called token by LinkedIn
    //  2. Sometimes, the oAuth provider puts a hash on the end of the request.
    //      We will never do that on the state param. So, we can safely remove it.
    //  3. We store the host at the end of nonce (for example, 23sd32d_mydomain.com)
    const hostThatInitiatedOAuthFlow = Maybe.just(query['state'] || query['token'])
        .map(x => x.split('#')[0])
        .map(x => x.split('_'))
        .map(x => x[x.length - 1])
        .or(() => loc.host);

    // If we are on the correct domain, get out of here.
    if (loc.host === hostThatInitiatedOAuthFlow) {
        return true;
    }

    // Reconstruct the URL to the requesting host. We have to put the query string together manually.
    const queryString = Object.keys(query).map(x => {
        return `${x}=${query[x]}`;
    }).reduce((a, b) => `${a}&${b}`);

    // We have a slight delay bfore a redirect. The delay allows us to return false (usually, used to stop the observable chain).
    setTimeout(() => {
        loc.assign(`${loc.origin.replace(loc.host, hostThatInitiatedOAuthFlow)}${loc.pathname}?${queryString}`);
    }, 10);

    return false;
}
