import { LocationBusinessCitation } from '../../models/location-business-citation';
import { BaseComponent } from '../../../base.component';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DirectoryTypes } from '../../models/enums/directory-types.enum';
import { BusinessCitationStatuses } from '../../models/enums/business-citation-statuses.enum';

@Component({
  selector: 'app-location-business-citations',
  templateUrl: './location-business-citations.component.html',
  styleUrls: ['./location-business-citations.component.scss']
})
export class LocationBusinessCitationsComponent extends BaseComponent implements OnChanges {

  @Input() locationBusinessCitation: LocationBusinessCitation;

  public assetPath: string;
  public totalCitationCount: number;
  public syncedCitationCount: number;
  public submittedCitationCount: number;
  public updatingCitationCount: number;

  public BusinessCitationStatuses = BusinessCitationStatuses;

  public totalListingsTooltip: string = this.translatePipe.transform('local_listings.status_total_tooltip');
  public syncStatusTooltip: string = this.translatePipe.transform('local_listings.status_synced_tooltip');
  public submittedStatusTooltip: string = this.translatePipe.transform('local_listings.status_submitted_tooltip');
  public updatingStatusTooltip: string = this.translatePipe.transform('local_listings.status_updating_tooltip');

  private directoryTypeTranslationKeys: { [directoryType: number] : string} = {};
  private statusTranslationKeys: { [status: number] : string} = {};

  constructor(private translatePipe: TranslatePipe) {
    super();
  }

  ngOnChanges() {
    this.assetPath = `assets/publisher-icons/${this.locationBusinessCitation.location.businessCitationProviderId}/`;
    this.totalCitationCount = this.locationBusinessCitation?.businessCitations.length;

    if (Object.keys(this.directoryTypeTranslationKeys).length === 0) {
      this.directoryTypeTranslationKeys = {
        [DirectoryTypes.SearchAndDiscovery]: this.translatePipe.transform('local_listings.directory_type_search_discovery'),
        [DirectoryTypes.SocialAndReputation]: this.translatePipe.transform('local_listings.directory_type_social_reputation'),
        [DirectoryTypes.NavigationServices]: this.translatePipe.transform('local_listings.directory_type_navigation_services'),
        [DirectoryTypes.VoiceSearch]: this.translatePipe.transform('local_listings.directory_type_voice_search'),
        [DirectoryTypes.BusinessDirectories]: this.translatePipe.transform('local_listings.directory_type_business_directories')
      }
    }
    
    if (Object.keys(this.statusTranslationKeys).length === 0) {
      this.statusTranslationKeys = {
        [BusinessCitationStatuses.Synced]: this.translatePipe.transform('local_listings.status_synced'),
        [BusinessCitationStatuses.Submitted]: this.translatePipe.transform('local_listings.status_submitted'),
        [BusinessCitationStatuses.Updating]: this.translatePipe.transform('local_listings.status_updating')
      }
    }

    this.locationBusinessCitation.businessCitations.forEach(citation => {
      citation.directoryTypeString = this.directoryTypeTranslationKeys[citation.directoryType];
      citation.statusString = this.statusTranslationKeys[citation.status];
    });
  }
}
