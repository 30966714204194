import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { bufferTime, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { Location } from '../../models/location';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent extends BaseComponent implements OnInit {
  @Input() location: Location;
  @Input() allowPan: boolean;

  private scrollEvents: BehaviorSubject<TouchEvent> = new BehaviorSubject(null);

  public zoom = 17;

  constructor() {
    super();
  }

  ngOnInit() {
    this.scrollEvents
      .pipe(
        takeUntil(this.unsubscribe),
        bufferTime(200)
      )
      .subscribe(x => {
        if (!x || !x.length || x.length < 2) {
          return;
        }

        const first = x[0].touches[0];
        const last = x[x.length - 1].touches[0];
        const yDiff = (first.pageY - last.pageY);

        const w = x[0].view.window;
        w.scrollBy(0, yDiff);
      });
  }

  public increaseZoom() {
    this.zoom = Math.min(this.zoom + 1, 18);
  }

  public decreaseZoom() {
    this.zoom = Math.max(this.zoom - 1, 1);
  }

  public touchMove(event: TouchEvent) {
    this.scrollEvents.next(event);
  }
}
