import { Component, OnInit } from '@angular/core';
import { GoogleService } from '../../google.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base.component';
import { Router } from '../../../../../node_modules/@angular/router';
import { SessionService } from '../../../shared';
import { GoogleAnalyticsAccount } from '../../../shared/models/ga-account';
import { mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-google-analytics-select',
  templateUrl: './google-analytics-select.component.html',
  styleUrls: ['./google-analytics-select.component.scss']
})
export class GoogleAnalyticsSelectComponent extends BaseComponent implements OnInit {
  public hasMultipleAccounts: boolean;
  public accounts: GoogleAnalyticsAccount[];
  public filteredAccounts = [];

  constructor(private google: GoogleService, private translate: TranslateService,
    private router: Router, private sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.google.accounts()
      .subscribe(this.handleAccounts.bind(this));
  }

  public handleAccounts(accounts: GoogleAnalyticsAccount[]) {
    this.accounts = accounts;
    this.filteredAccounts = this.accounts;
    this.hasMultipleAccounts = this.accounts && this.accounts.length > 1;
  }

  public select(analyticsAccount: any) {
    this.google
      .accountSelect(analyticsAccount)
      .pipe(
        mergeMap(() => this.sessionService.account.pipe(take(1)))
      )
      .subscribe(account => {
        account.hasGoogleAnalyticsAccess = true;
        this.sessionService.setAccount(account);

        // todo: once we have GoogleMyBusiness implemented, we will want to take them to a "location select" screen.
        // in the mean time we will take them to their profile page.
        this.router.navigateByUrl('/user/profile');
      });
  }

  public authorizeDifferentAccount() {
    this.google.connect();
  }

  public filter(input: string) {
    if (!input) {
      this.filteredAccounts = this.accounts;
      return;
    }

    this.filteredAccounts = this.accounts
      .filter(account => account.displayName.toLowerCase().includes(input.toLowerCase()) || account.profileId.includes(input));
  }
}
