<div class="d-flex justify-content-between gap-2">
  <button class="form-select" [ngbPopover]="dateRangeSelector" placement="bottom-left" autoClose="outside" (shown)="savePreviousState()" (hidden)="revertToPreviousState()">{{displayDateRange()}}</button>
  <button class="btn btn-outline-primary" (click)="clearAndSave()"><i class="fa fa-times"></i></button>
</div>

<ng-template #dateRangeSelector>
  <div class="d-flex justify-content-between gap-2">
    <ngb-datepicker class="text-secondary !important" #dp (dateSelect)="onDateSelection($event)" [dayTemplate]="t" (navigate)="currentMonth.set($event.next.month)"
      [minDate]="minDate()" [maxDate]="maxDate()"/>
    <div class="d-flex flex-column justify-content-end gap-2">
      <div class="d-flex flex-column gap-2">
        @for(lastDays of pastDaysSelection(); track lastDays) {
          <button class="btn" [class.btn-outline-primary]="pastDaysSelected() !== lastDays" [class.btn-primary]="pastDaysSelected() === lastDays" (click)="setDays(lastDays); dp.navigateTo();">
            {{ 'local_listings.stats_last_x_days' | translate: { days: lastDays } }}
          </button>
        }
      </div>
      <div class="d-flex justify-content-between gap-2">
        <button class="btn btn-outline-primary flex-fill" (click)="clear()"><i class="fa fa-times"></i></button>
        <button class="btn btn-primary flex-fill" (click)="save()" [disabled]="startDate() && !endDate()">{{ "shared.button_save" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #t let-date>
  <span
    class="custom-day align-middle p-1"
    [ngClass]="{'btn-primary !important rounded-circle': isRange(date),
      'btn-outline-primary !important': isHovered(date) || isInside(date),
      'faded': date.month !== currentMonth(),
      'blocked': cannotSelect(date) }"
    (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>