import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReloadService } from './reload.service';

@Injectable()
export class ReloadRequired  {

    constructor(private reloadService: ReloadService) {
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.reloadService.needsReload) {
            window.location.assign(state.url);
        }

        return !this.reloadService.needsReload;
    }
}
