import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { MatchValidator } from '../profile/change-password/match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  success: Boolean = false;
  private resetToken: string;
  private email: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^((?=.*[A-Z])(?=.*[a-z]).*){8,}$')
      ]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: MatchValidator.Match('confirmPassword', 'password')
    });

    this.resetToken = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];

    if (!this.resetToken || !this.email) {
      console.log('Invalid token');
      this.router.navigateByUrl('forgot-password');
      return;
    }

    this.router.navigateByUrl('reset-password');
  }

  save(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      return;
    }

    this.userService.resetPassword(
      this.email,
      this.resetToken,
      this.form.get('password').value,
      this.form.get('confirmPassword').value).subscribe(() => {
        this.form.reset();

        this.success = true;
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 5000);
      });
  }
}
