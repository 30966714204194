import { Component, Input } from '@angular/core';

import { PreAudit } from '../models/pre-audit';
import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-pre-audit',
  templateUrl: './pre-audit.component.html',
  styleUrls: ['./pre-audit.component.css']
})
export class PreAuditComponent implements IWorkItemComponent {
  @Input() public value: PreAudit;
  @Input() public type: number;
}
