import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { OnlinePresenceService } from '../../online-presence.service';

@Component({
  selector: 'app-online-presence',
  templateUrl: './online-presence.component.html',
  styleUrls: ['./online-presence.component.css']
})
export class OnlinePresenceComponent extends BaseComponent implements OnInit {
  public totalLinksBuilt: number;
  public mtdLinksBuild: number;
  public showChange: boolean;

  constructor(private service: OnlinePresenceService) {
    super();
  }

  ngOnInit() {
    this.service.summary()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (x) {
          this.totalLinksBuilt = x.lifeToDate;
          this.mtdLinksBuild = x.monthToDate;
          this.showChange = x.monthToDate > 0;
        }
      });
  }
}
