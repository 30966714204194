import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-avg-keyword-rank',
  templateUrl: './avg-keyword-rank.component.html',
  styleUrls: ['./avg-keyword-rank.component.css']
})
export class AvgKeywordRankComponent extends BaseComponent implements OnInit {

  public avgRank: number;
  public avgRankChangePercentage: number;
  public showChange: boolean;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) {
          return;
        }

        this.avgRank = x.avgRank;
        this.avgRankChangePercentage = x.avgRankChangePercentage;
        this.showChange = x.avgRankChangePercentage > 0;
      });
  }
}
