import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';

import { BaseComponent } from '../../../base.component';
import { WorkService } from '../../work.service';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-hours-by-month',
  templateUrl: './hours-by-month.component.html',
  styleUrls: ['./hours-by-month.component.css'],
  providers: [BaseChartDirective, TranslatePipe]
})
export class HoursByMonthComponent extends BaseComponent implements OnInit {

  public chartLabels: string[];
  public chartData: any[];
  public chartType = 'horizontalBar';
  public chartLegend = true;
  public nextRenewalDate: string;
  public lastBilledDate: string;
  public currentPeriodEndDate: string;
  public chartColors: Array<any>;

  constructor(
    private workService: WorkService,
    private translatePipe: TranslatePipe,
    private chartColorService: ChartColorService) {
    super();
  }

  public chartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      position: 'bottom'
    },
    tooltips: {
      mode: 'label',
      followPointer: true,
      callbacks: {
        label: function (tooltipItem, data) {
          const hours = this.translatePipe.transform('table_header_hours');
          return `${tooltipItem.xLabel} ${hours} ${data.datasets[tooltipItem.datasetIndex].label}`;
        }.bind(this)
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: false },
      }],
      yAxes: [{
        stacked: true,
        gridLines: { display: false }
      }]
    }
  };

  ngOnInit() {
    this.chartColorService.stackedBar
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
      });

    this.workService.getHoursByMonth()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        this.lastBilledDate = x.lastBilledDate;
        this.nextRenewalDate = x.nextRenewalDate;
        this.currentPeriodEndDate = moment(x.nextRenewalDate).add(-1, 'day').toISOString();

        this.chartLabels = x.hoursByMonthSummary.map(y => `${y.month}/${y.year}`);
        this.chartData = [
          { label: this.translatePipe.transform('table_header_completed'), data: x.hoursByMonthSummary.map(y => y.hoursCompleted) },
          { label: this.translatePipe.transform('table_header_pending'), data: x.hoursByMonthSummary.map(y => y.hoursPending) }
        ];
      });
  }
}
