import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared';
import { HoursByMonthResponse } from '../shared/models/hours-by-month-response';
import { CampaignSummary } from '../shared/models/campaign-summary';
import { Paged } from '../shared/models/paged';
import * as moment from 'moment';
import { map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategorySummary } from '../shared/models/category-summary';

@Injectable()
export class WorkService {
  private baseUrl: string;
  private _allTimeSummary: BehaviorSubject<any>;
  private _monthlySummary: BehaviorSubject<CategorySummary>;
  private _taskCount: BehaviorSubject<{ allTimeCount: number, sinceLastBillingDateCount: number }>;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this._allTimeSummary = new BehaviorSubject(null);
  }

  get allTimeSummary() {
    this.getAllTimeSummary();
    return this._allTimeSummary.asObservable();
  }

  get monthlySummary() {
    if (!this._monthlySummary) {
      this._monthlySummary = new BehaviorSubject(null);
      this.getMonthlySummary();
    }

    return this._monthlySummary.asObservable();
  }

  get taskCount() {
    if (!this._taskCount) {
      this._taskCount = new BehaviorSubject(null);
      this.getTaskCount();
    }

    return this._taskCount.asObservable();
  }

  public details(id: number): Observable<any> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<string>(`${this.baseUrl}account/${x.id}/work/${id}`) :
            of(null);
        }),
        map(x => {
          return !!x ? JSON.parse(x) : of(null);
        })
      );
  }

  public getHoursByMonth(): Observable<HoursByMonthResponse> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<HoursByMonthResponse>(`${this.baseUrl}account/${x.id}/work/hoursbymonth`) :
            of({
              hoursByMonthSummary: [],
              lastBilledDate: moment().toJSON(),
              nextRenewalDate: moment().toJSON()
            });
        })
      );
  }

  public getCampaignSummary(page: number = 0, pageSize: number = 10): Observable<Paged<CampaignSummary>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ?
            this.http.get<Paged<CampaignSummary>>(`${this.baseUrl}account/${x.id}/work/summary/notes`, { params: params }) :
            of(null);
        })
      );
  }

  private getMonthlySummary(): void {
    this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ?
            this.http.get<CategorySummary>
              (`${this.baseUrl}account/${x.id}/work/summary/monthly`) :
            of({
              lastBilledDate: moment().toJSON(),
              nextRenewalDate: moment().toJSON(),
              categorySummaryByMonth: [{ key: { workCategoryGroupId: '', elapsedMonths: 0 }, value: 0 }]
            });
        })
      )
      .subscribe(x => {
        this._monthlySummary.next(x);
      });
  }

  private getAllTimeSummary(): void {
    this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<{ key: string, value: number }[]>(`${this.baseUrl}account/${x.id}/work/summary`) :
            of([]);
        })
      )
      .subscribe(x => {
        this._allTimeSummary.next(x);
      });
  }

  private getTaskCount(): void {
    this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ?
            this.http.get<{ allTimeCount: number, sinceLastBillingDateCount: number }>
              (`${this.baseUrl}account/${x.id}/work/summary/monthly/count`) :
            of({ allTimeCount: 0, sinceLastBillingDateCount: 0 });
        })
      )
      .subscribe(x => {
        this._taskCount.next(x);
      });
  }
}
