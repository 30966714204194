<div class="row">
  <div class="col-md-3">
    <app-about [type]='type'></app-about>
    <div class="card mt-2" *ngIf="value.download">
      <div class="card-body">
        <a href="{{value.download}}" target="_blank" class="card-link"><span class="fa fa-download"></span> {{ 'download' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h5>{{ 'onsite_opts_title_recommendation' | translate }}</h5>
        <hr>
        <h6>{{ value.titleNew }}</h6>
        <h6 class="old">{{ value.titleOld }}</h6>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h5>{{ 'onsite_opts_description_recommendation' | translate }}</h5>
        <hr>
        <p>{{ value.descriptionNew }}</p>
        <p class="old">{{ value.descriptionOld }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card mb-4">
      <div class="card-body">
        <h5>{{ 'onsite_opts_h1_recommendation' | translate }}</h5>
        <hr>
        <div [innerHtml]="h1New"></div>
        <div [innerHtml]="h1Old" class="old"></div>
      </div>
    </div>
  </div>
</div>