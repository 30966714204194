import { AbstractControl } from '@angular/forms';

export class MatchValidator {
    static Match(first: string, second: string) {
        return (control: AbstractControl) => {
            const firstValue = control.get(first).value;
            const secondValue = control.get(second).value;

            if (firstValue === secondValue) {
                return null;
            }

            control.get(first).setErrors({ Match: true });
        };
    }
}
