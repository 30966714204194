import { Component, Input } from '@angular/core';
import { RequiredActionTypes } from '../../../../shared/models';

@Component({
  templateUrl: './seo-content-survey.component.html',
  styleUrls: ['./seo-content-survey.component.css']
})
export class SeoContentSurveyComponent {
  @Input() requiredAction: { type: RequiredActionTypes; };

  constructor() { }
}
