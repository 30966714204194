import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDatepickerI18nDefault, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class LocalizedDatePicker extends NgbDatepickerI18n {
    constructor(private defaultDatePicker: NgbDatepickerI18nDefault) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return moment.weekdaysShort()[weekday % 7];
    }

    getMonthShortName(month: number): string {
        return moment.monthsShort()[month - 1];
    }

    getMonthFullName(month: number): string {
        return moment.months()[month - 1];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
    
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this.defaultDatePicker.getWeekdayLabel(weekday, width);
    }
}
