import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../shared';
import { SessionService } from '../../shared/index';
import { englishLanguages } from '../../shared/language-selector/languages';

@Component({
  selector: 'app-user-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [fadeInOut()]
})
export class ProfileComponent implements OnInit {
  isEnglishLanguage: boolean = false;

  constructor(public sessionService: SessionService) {

  }

  ngOnInit(): void {
    this.isEnglishLanguage = englishLanguages.indexOf(this.sessionService.locale) !== -1;
  }
}
