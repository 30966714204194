import { Component, EventEmitter, Input, Output, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../../shared/session.service';
import { BaseComponent } from '../../../app/base.component';
import { Account, SiteConfig, User } from '../../../app/shared/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit {
  private _isCollapsed = true;
  public account: Account;
  public siteConfig: SiteConfig;
  public sessionIsAuthorized: boolean;
  public sessionIsReady: boolean;
  public canSwitchAccounts: boolean;
  public user: User;
  public shouldAppendSurveyScript = false;
  private isSurveyScriptAppended = false;
  private surveyPartnerId = 292;

  @Input() set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.isCollapsedChange.emit(this._isCollapsed);
  }
  @Output() isCollapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  ngOnInit() {
    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((account) => {
        this.account = account;

        this.shouldAppendSurveyScript = this.account && this.account.partnerId === this.surveyPartnerId;
      });

    this.sessionService.siteConfig
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((siteConfig) => {
        this.siteConfig = siteConfig;
      });

    this.sessionService.isAuthorized()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((sessionIsAuthorized) => {
        this.sessionIsAuthorized = sessionIsAuthorized;
      });

    this.sessionService.isReady()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((sessionIsReady) => {
        this.sessionIsReady = sessionIsReady;
      });

    this.sessionService.canSwitchAccounts
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((canSwitchAccounts) => {
        this.canSwitchAccounts = canSwitchAccounts;
      });

    this.sessionService.user
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngAfterViewInit() {
    if (this.shouldAppendSurveyScript) {
      this.appendSurveyScript();
    }
  }

  constructor(public sessionService: SessionService, private router: Router, private elementRef: ElementRef) {
    super();
  }

  public logoff(event: Event) {
    event.preventDefault();

    this.isCollapsed = false;
    this.router.navigateByUrl('log-out');
  }

  public switchAccounts() {
    this.isCollapsed = !this.isCollapsed;

    this.router.navigateByUrl('accounts');
  }

  public clickedOutside() {
    this.isCollapsed = true;
  }

  private appendSurveyScript() {
    if (this.isSurveyScriptAppended) {
      return;
    }
    this.isSurveyScriptAppended = true;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/survey.js';
    this.elementRef.nativeElement.appendChild(script);
  }
}
