import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared/index';
import { Paged } from '../shared/models/paged';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class WorkItemsService {
  private baseUrl: string;

  private _filter: WorkItemsFilter;

  private _workItems: BehaviorSubject<Paged<WorkItem>>;
  private items: WorkItem[] = [];

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.sessionService.account
      .subscribe(x => {
        // no want time zone
        const date = !!x ? moment(x.lastBilledDate.toString().substring(0, 10)).toDate() : new Date();
        this._filter = {
          date: new Date(date.getFullYear(), date.getMonth(), 1),
          category: '00000000-0000-0000-0000-000000000000'
        };
      });
  }

  get workItems() {
    if (!this._workItems) {
      this._workItems = new BehaviorSubject(null);
      this.getWorkItems();
    }

    return this._workItems.asObservable();
  }

  set filter(predicate: WorkItemsFilter) {
    this._filter = predicate;
    this.reset();
    this.getWorkItems();
  }

  get filter() {
    return this._filter;
  }

  public more(): void {
    this.getWorkItems(1000);
  }

  public updateFileDetails(workItem: WorkItem): void {
    this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<boolean>(`${this.baseUrl}account/${x.id}/work/${workItem.id}/hasFile`) :
            of(false);
        })
      ).subscribe((x) => {
        workItem.hasDetails = x;
      });
  }

  private getWorkItems(take: number = 10): void {
    this.sessionService.account
      .pipe(
        mergeMap((x) => {
          const skip = this.items.length.toString();
          const date = `${this._filter.date.getMonth() + 1}/${this._filter.date.getDate()}/${this._filter.date.getFullYear()}`;
          const categoryId = this._filter.category;

          const params = new HttpParams()
            .set('date', date)
            .set('skip', skip)
            .set('take', take.toString())
            .set('categoryId', categoryId || '');

          return !!x ? this.http.get<Paged<WorkItem>>(`${this.baseUrl}account/${x.id}/work`, { params: params }) : of(null);
        })
      )
      .subscribe(x => {
        if (x) {
          this.items = this.items.concat(...x.items);
          x.items = this.items;
          this._workItems.next(x);
        }
      });
  }

  private reset() {
    this.items = [];
  }
}

export interface WorkItem {
  id: number;
  date: Date;
  workCategoryId: number;
  workTypeId: number;
  name: string;
  url: string;
  workCategoryTypeId: number;
  hasDetails: boolean;
  customName: string;
  customDescription: string;
}

export interface WorkItemsFilter {
  date: Date;
  category?: string;
}
