import { Component, OnInit, Input } from '@angular/core';
import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-syndications',
  templateUrl: './infographic-syndications.component.html',
  styleUrls: ['./infographic-syndications.component.scss']
})
export class InfographicSyndicationsComponent implements IWorkItemComponent, OnInit {
  @Input() public value: any;
  @Input() public type: number;

  ngOnInit() {
    this.getUrls();
  }

  public getUrls() {
    for (let i = 0; i < this.value.urls.length; i++) {
      let link = document.createElement('a');
      link.setAttribute('href', this.value.urls[i]);
      const host = link.hostname;
      const protocol = link.protocol;
      this.value.urls[i] = `${protocol}//${host}`;
      link = null;
    }
  }
}
