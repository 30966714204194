<div class="row">
  <div class="col-md-6">
    <app-about [type]="type"></app-about>
  </div>
  <div class="col-md-5">
    <div class="card">
      <div class="card-body">
        <ol *ngIf="value.urls.length; else nourls">
          <li *ngFor="let url of value.urls">
            <a href="{{url}}" target="_blank">{{ url }}</a>
          </li>
        </ol>
        <ng-template #nourls>
          <div class="alert alert-primary">
            {{ 'cant_find_details' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
