<div class="card">
  <div class="card-body">
    <h4 class="custom-tile-header d-inline me-3 text-primary">{{ 'work_items_completed' | translate }}</h4>
    <div class="row mt-4 mb-5">
      <div class="col-md-4">
        <select class="form-select pt-1 pb-1" [ngModel]="date" (change)="dateChanged($event.target.value)">
          <option *ngFor="let p of periods" value="{{ p }}">{{ p | date:'MMMM y' }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <select class="form-select pt-1 pb-1" [ngModel]="category" (change)="categoryChanged($event.target.value)">
          <option value="00000000-0000-0000-0000-000000000000">{{ 'table_header_category' | translate }}</option>
          <option *ngFor="let c of categories" value="{{ c.id }}"> {{ c.translation | translate }}</option>
        </select>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="d-none d-md-table-cell">{{ 'table_header_date' | translate }}</th>
          <th>{{ 'table_header_type' | translate }}</th>
          <th>{{ 'URL' | translate }}</th>
          <th class="d-none d-sm-table-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="items.length === 0">
          <td class="table-info" colspan="4">{{ 'message_no_results' | translate }} {{ 'try_changing_date_or_category' | translate }}</td>
        </tr>
        <ng-container *ngFor="let i of items">
          <tr [ngClass]="{ 'highlight': id === i.id}">
            <td class="d-none d-md-table-cell">
              {{ i.date | date:'MMMM y':'UTC' }}
            </td>
            <td id="{{i.id}}">
              <ng-container *ngIf="i.customName">
                {{ i.customName }}<helpPopover placement='right' html="{{ i.customDescription }}"></helpPopover>
              </ng-container>
              <ng-container *ngIf="!i.customName">
                {{ key(i) | translate }}<helpPopover placement='right' html="{{ description(i) | translate }}"></helpPopover>
              </ng-container>
            </td>
            <td class="truncate w-50">
              <div *ngIf="isUrl(i.url)">
                <a *ngIf="isDownload(i.url)" class="btn btn-info" href="{{ i.url }}" target="_blank"><i class="fa fa-download"></i> {{ 'download' | translate }}</a>
                <a *ngIf="!isDownload(i.url)" class="d-none d-md-inline" href="{{ i.url }}" target="_blank">{{ i.url }}</a>
                <a *ngIf="!isDownload(i.url)" class="d-inline d-md-none btn btn-outline-primary float-sm-right" href="{{ i.url }}" target="_blank">Open</a>
              </div>
              <button *ngIf="i.hasDetails" routerLink="/seo/actions/{{i.id}}" class="d-inline-block d-sm-none mt-3 btn btn-outline-primary">{{ 'details' | translate }}</button>
            </td>
            <td class="text-center d-none d-sm-table-cell">
              <button *ngIf="i.hasDetails" routerLink="/seo/actions/{{i.id}}" class="btn btn-outline-primary">{{ 'details' | translate }}</button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer text-center">
    <button *ngIf="hasMore" class="btn btn-outline-primary mt-3" (click)="more()">{{ 'more' | translate }} <i class="fa fa-chevron-down ms-2"></i></button>
  </div>
</div>
