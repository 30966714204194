<div class="row">
  <div class="col-md-3">
    <app-about [type]="type"></app-about>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_google_analytics_found' | translate }}</label>
          <app-yes-no [value]="value.hasGoogleAnalytics"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_domain_age' | translate }}</label>
          <span>{{ value.domainAge | date:'shortDate'}}</span>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_domain_authority' | translate }}</label>
          <span>{{ value.domainAuthority }}</span>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_avg_site_speed' | translate }}</label>
          <span>{{ value.siteSpeed}} seconds</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_flash_found' | translate }}</label>
          <app-yes-no [value]="value.hasFlash"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_frames_found' | translate }}</label>
          <app-yes-no [value]="value.hasFrames"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_indexed_on_google' | translate }}</label>
          <app-yes-no [value]="value.isIndexedOnGoogle"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_mobile_friendly' | translate }}</label>
          <app-yes-no [value]="value.isMobileFriendly"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{'pre_audit_broken_links' | translate }}</label>
          <app-yes-no [value]="value.hasBrokenLinks"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_use_schema' | translate }} </label>
          <app-yes-no [value]="value.hasUseSchema"></app-yes-no>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_site_expiration' | translate }} </label>
          <span>{{ value.siteExpiration | date:'shortDate'}}</span>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_sitemap' | translate }}</label>
          <app-yes-no [value]="value.hasSiteMap"></app-yes-no>
        </div>
        <div class="d-flex p-2 justify-content-between">
          <label>{{ 'pre_audit_has_blog' | translate }}</label>
          <app-yes-no [value]="value.hasBlog"></app-yes-no>
        </div>
        <ng-template *ngIf="value.hasBlog">
          <div class="d-flex p-2 justify-content-between">
            <label>{{ 'pre_audit_blog_frequency' | translate }}</label>
          </div>
          <div class="d-flex p-2 justify-content-between">
            <label>{{ 'pre_audit_blog_frequency_month_1' | translate }}</label>
            <span>{{ value.totalMonth1BlogPosts}}</span>
          </div>
          <div class="d-flex p-2 justify-content-between">
            <label>{{ 'pre_audit_blog_frequency_month_2' | translate }}</label>
            <span>{{ value.totalMonth2BlogPosts}}</span>
          </div>
          <div class="d-flex p-2 justify-content-between">
            <label>{{ 'pre_audit_blog_frequency_month_3' | translate }}</label>
            <span>{{ value.totalMonth3BlogPosts}}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>