import { Features } from '../shared/models/features.enum';
import { FeatureRequired } from '../shared/account-guards';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseChartDirective } from 'ng2-charts';
import { SessionRequired } from '../shared';
import { SeoReady } from '../shared/';
import { SharedModule } from '../shared/shared.module';
import { ActionsComponent } from './actions/actions.component';
import { HoursByMonthComponent } from './actions/hours-by-month/hours-by-month.component';
import { WorkHistorySummaryAllTimeComponent } from './actions/work-history-summary-all-time/work-history-summary-all-time.component';
import { AboutComponent } from './actions/work-item/about/about.component';
import { ContentComponent } from './actions/work-item/content/content.component';
import { LocationComponent } from './actions/work-item/location/location.component';
import { NoDetailsComponent } from './actions/work-item/no-details/no-details.component';
import { OnsiteRecommendationComponent } from './actions/work-item/onsite-recommendation/onsite-recommendation.component';
import { PreAuditComponent } from './actions/work-item/pre-audit/pre-audit.component';
import { SiteInformationComponent } from './actions/work-item/site-information/site-information.component';
import { WorkItemComponent } from './actions/work-item/work-item.component';
import { YesNoComponent } from './actions/work-item/yes-no/yes-no.component';
import { WorkItemsComponent } from './actions/work-items/work-items.component';
import { AnalyticsStore } from './analytics-store.service';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsService } from './analytics/analytics.service';
import { ByCityComponent } from './analytics/by-city/by-city.component';
import { ByDayComponent } from './analytics/by-day/by-day.component';
import { ByPageComponent } from './analytics/by-page/by-page.component';
import { BySourceComponent } from './analytics/by-source/by-source.component';
import { DateRangeComponent } from './analytics/date-range/date-range.component';
import { NoAnalyticsMessageComponent } from './analytics/no-analytics-message/no-analytics-message.component';
import {
    AvgKeywordRankComponent,
    CurrentRankComponent,
    DashboardComponent,
    KeywordsTop10Component,
    KeywordsTop25Component,
    KeywordsTop50Component,
    TotalKeywordsImprovedComponent,
    OnlinePresenceComponent,
    RequiredActionsComponent,
    TopKeywordComponent,
    TotalWorkCompletedComponent,
    TotalWorkItemsCompletedComponent,
    TrafficComponent,
    OnsiteRecommendationApprovalComponent,
    OnsiteBlogApprovalComponent,
    WebsiteCopyApprovalComponent,
    CompleteOnboardingComponent,
    GooglePinComponent,
    ImplementOnsiteRecommendationsComponent,
    ImplementOnsiteCopyComponent,
    ImplementOnsiteBlogComponent,
    WebsiteAccessComponent,
    SeoContentSurveyComponent
} from './dashboard';
import { ProTipsComponent } from './dashboard/pro-tips/pro-tips.component';
import { KeywordsComponent, RankHistoryComponent } from './keywords';
import { OnlinePresenceService } from './online-presence.service';
import { WorkItemsService } from './work-items.service';
import { WorkService } from './work.service';
import { ArticleEngagementComponent } from './actions/work-item/article-engagement/article-engagement.component';
import { InfographicSyndicationsComponent } from './actions/work-item/infographic-syndications/infographic-syndications.component';
import { NotReadyComponent } from './not-ready/not-ready.component';
import { MbgStartDateComponent } from './dashboard/mbg-start-date/mbg-start-date.component';
import { MbgKeywordTargetComponent } from './dashboard/mbg-keyword-target/mbg-keyword-target.component';
import { GuaranteeService } from './guarantee.service';
import { CampaignSummaryComponent } from './actions/campaign-summary/campaign-summary.component';
import { ReloadRequired } from '../shared/reload-guard';

const routes: Routes = [
    {
        path: 'seo',
        canActivateChild: [ReloadRequired],
        children:
        [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
                data: { requiredFeature: Features.SEO }
            },
            {
                path: 'actions/:id',
                component: WorkItemComponent,
                canActivate: [SessionRequired, FeatureRequired, SeoReady],
                data: { requiredFeature: Features.SEO }
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [SessionRequired, FeatureRequired, SeoReady],
                data: { requiredFeature: Features.SEO }
            },
            {
                path: 'keywords',
                component: KeywordsComponent,
                canActivate: [SessionRequired, FeatureRequired, SeoReady],
                data: { requiredFeature: Features.SEO }
            },
            {
                path: 'work-history',
                component: ActionsComponent,
                canActivate: [SessionRequired, FeatureRequired, SeoReady],
                data: { requiredFeatures: [Features.SEO, Features.ProfessionalBacklink] }
            },
            {
                path: 'actions',
                redirectTo: 'work-history',
                pathMatch: 'full'
            },
            {
                path: 'analytics',
                component: AnalyticsComponent,
                canActivate: [SessionRequired, FeatureRequired, SeoReady],
                data: { requiredFeature: Features.SEO }
            }
        ]
    },
    {
        path: 'not-ready',
        component: NotReadyComponent,
        canActivate: [SessionRequired, ReloadRequired]
    }
];

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BaseChartDirective,
        SharedModule,
        CommonModule,
        RouterModule.forRoot(routes, {})
    ],
    declarations: [
        OnlinePresenceComponent,
        DashboardComponent,
        KeywordsComponent,
        ActionsComponent,
        ProTipsComponent,
        CurrentRankComponent,
        KeywordsTop10Component,
        KeywordsTop25Component,
        KeywordsTop50Component,
        TotalKeywordsImprovedComponent,
        AvgKeywordRankComponent,
        TopKeywordComponent,
        TotalWorkCompletedComponent,
        RequiredActionsComponent,
        WebsiteAccessComponent,
        SeoContentSurveyComponent,
        HoursByMonthComponent,
        OnlinePresenceComponent,
        TrafficComponent,
        RankHistoryComponent,
        AnalyticsComponent,
        ByCityComponent,
        ByDayComponent,
        BySourceComponent,
        ByPageComponent,
        DateRangeComponent,
        WorkHistorySummaryAllTimeComponent,
        WorkItemsComponent,
        ContentComponent,
        PreAuditComponent,
        WorkItemComponent,
        LocationComponent,
        OnsiteRecommendationComponent,
        SiteInformationComponent,
        NoDetailsComponent,
        YesNoComponent,
        AboutComponent,
        NoAnalyticsMessageComponent,
        TotalWorkItemsCompletedComponent,
        ArticleEngagementComponent,
        InfographicSyndicationsComponent,
        NotReadyComponent,
        MbgStartDateComponent,
        MbgKeywordTargetComponent,
        CampaignSummaryComponent,
        OnsiteRecommendationApprovalComponent,
        OnsiteBlogApprovalComponent,
        WebsiteCopyApprovalComponent,
        CompleteOnboardingComponent,
        GooglePinComponent,
        ImplementOnsiteRecommendationsComponent,
        ImplementOnsiteCopyComponent,
        ImplementOnsiteBlogComponent
    ],
    exports: [DashboardComponent, KeywordsComponent, ActionsComponent],
    providers: [
        AnalyticsStore,
        AnalyticsService,
        DatePipe,
        OnlinePresenceService,
        WorkService,
        WorkItemsService,
        GuaranteeService
    ]
})
export class SEOModule { }
