import { Component, OnInit } from '@angular/core';
import { WorkService } from '../../work.service';
import { CampaignSummary } from '../../../shared/models/campaign-summary';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss']
})
export class CampaignSummaryComponent extends BaseComponent implements OnInit {
  public campaignSummary: CampaignSummary[] = [];
  public hasMore = true;
  private pageNumber = 1;
  private pageSize = 10;

  constructor(private workService: WorkService) { super(); }

  ngOnInit() {
    this.loadSummary();
  }

  public loadSummary() {
    this.workService
      .getCampaignSummary(this.pageNumber, this.pageSize)
      .subscribe(summaries => {
        if (summaries) {
          this.hasMore = summaries.items.length === this.pageSize;
          if (this.hasMore) {
            this.pageNumber++;
          }
          this.addSummaries(summaries.items);
        }
      });
  }

  private addSummaries(items: CampaignSummary[]) {
    items.forEach(x => this.campaignSummary.push(x));
  }

}
