import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UnauthorizedError } from './errors/unauthorized-error';
import { ErrorService } from './errors/error.service';

@Injectable()
export class HttpExceptionInterceptor implements HttpInterceptor {
    private readonly noop = () => { };

    constructor(private router: Router, private errorService: ErrorService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    this.noop();
                }, (err: any) => {
                    if (!(err instanceof HttpErrorResponse)) {
                        return;
                    }
                    if (err.status === 401) {
                        this.router.navigateByUrl('log-out');
                        throw new UnauthorizedError();
                    }
                })
            );
    }
}

export function HttpExceptionInterceptorFactory(router: Router, errorService: ErrorService) {
    return new HttpExceptionInterceptor(router, errorService);
}

export let HttpExceptionInterceptorFactoryProvider = {
    provide: HttpExceptionInterceptor,
    deps: [ Router, ErrorService],
    useFactory: HttpExceptionInterceptorFactory
};
