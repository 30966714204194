<div [@fadeInOut]="'active'">
  <div class="row" *hideKey="'Actions-WorkHistorySummary'">
    <div class="col-md-12">
      <app-work-history-summary-all-time></app-work-history-summary-all-time>
    </div>
  </div>
  <div class="row mt-4" *hideKey="'Actions-HoursByMonth'">
    <div class="col-md-6">
      <app-hours-by-month></app-hours-by-month>
    </div>
  </div>
  <div class="row mt-4" *hideKey="'Actions-WorkItemsCompleted'">
    <div class="col-md-12">
      <app-work-items></app-work-items>
    </div>
  </div>
  <div class="row mt-4" *hideKey="'Actions-CampaignSummaryNotes'">
    <div class="col-md-12">
      <app-campaign-summary></app-campaign-summary>
    </div>
  </div>
  <div class="row mt-4" *ngIf="account; let a">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <p class="mb-0 text-muted text-center custom-tile-subheader" *hideKey="'Seo-CustomerUrl'">{{ a.url }}</p>
        </div>
      </div>
    </div>
  </div>
</div>