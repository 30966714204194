<div class="list-group-item action" *hideKey="'Seo-Dashboard-RequiredAction-OnsiteRecommendationApproval'">
  <div class="d-flex flex-wrap mb-2">
    <div *ngIf="!isActionComplete" class="mb-1 text-danger custom-required-actions-item-title" style="min-width: 100%">
      <i class="fa fa-exclamation-circle fa-2x text-danger float-start pe-2"></i>{{ 'ra_onsite_recommendation_approval'
      | translate }}
    </div>
    <div *ngIf="isActionComplete" class="mb-1 text-success custom-required-actions-item-title" style="min-width: 100%">
      <i class="fa fa-check-circle fa-2x text-success float-start pe-2"></i>{{ 'ra_onsite_recommendation_approval' |
      translate }}
    </div>
    <div class="text-muted ms-4 mb-2" style='min-width: 100%'>
      <small>
        <span *ngIf="isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
          <i class="fa fa-plus-circle customer-required-actions-text"></i> {{ 'view_details' | translate }}
        </span>
        <span *ngIf="!isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
          <i class="fa fa-minus-circle customer-required-actions-text"></i> {{'ra_onsite_recommendation_approval_desc'
          | translate: { date: requiredAction.date | date:'shortDate'} }}
        </span>
      </small>
    </div>
    <div class="text-muted ms-4 mb-2" style='min-width: 100%'>
      <small>
        <span *ngIf="isUrlCollapsed" (click)="isUrlCollapsed = !isUrlCollapsed" class="clickable customer-required-actions-text font-italic">
          <i class="fa fa-plus-circle customer-required-actions-text"></i> {{ 'URL' | translate }}
        </span>
        <span class="clickable customer-required-actions-text font-italic" *ngIf="!isUrlCollapsed" (click)="isUrlCollapsed = !isUrlCollapsed">
          <i class="fa fa-minus-circle customer-required-actions-text"></i> {{ requiredAction.displayUrl }}
        </span>
      </small>
    </div>
    <ng-container *hideKey="'Seo-Dashboard-RequiredAction-OnsiteRecommendationApproval-Button'">
      <ng-container *ngIf="!isActionComplete && !isActionRejected">
        <ng-container *ngIf="isDownloaded">
          <div class="ms-auto mb-2" style="min-width: 25%">
            <button (click)='approve()' [disabled]="isRejection || isCsr" [ngClass]="{disabled : isRejection}" target="_blank"
              class="btn btn-success btn-sm align-self-start custom-required-actions-item-button w-100">
              {{'approve' | translate }}
            </button>
          </div>
          <div class="ms-auto mb-2" style="min-width: 25%">
            <button (click)='startRejection()' [disabled]="isRejection || isCsr" [ngClass]="{disabled : isRejection}" target="_blank"
              class="btn btn-danger btn-sm align-self-start custom-required-actions-item-button w-100">
              {{'reject' | translate }}
            </button>
          </div>

        </ng-container>
        <div class="ms-auto mb-2" style="min-width: 25%" *hideKey="'Seo-Dashboard-RequiredAction-OnsiteRecommendation-Button'">
          <button (click)='download()' target="_blank" class="btn btn-primary btn-sm align-self-start custom-required-actions-item-button w-100">
            {{'download' | translate }}
          </button>
        </div>

        <ng-container *ngIf="isDownloaded && isRejection">
          <div [formGroup]="form" novalidate style="min-width: 100%">
            <div class="form-group">
              <textarea rows="7" id="inputNote" class="form-control" placeholder="{{ 'ra_onsite_recommendation_approval_rejection_placeholder' | translate }}"
                required="" autofocus="" autocomplete="off" formControlName="note" [ngClass]="{'is-invalid' : form.controls['note'].dirty && form.controls['note'].invalid }"></textarea>
              <div class="invalid-feedback">
                {{ 'ra_onsite_recommendation_approval_rejection_validation' | translate }}
              </div>
            </div>
          </div>
          <div class="ms-auto" style="min-width: 38%">
            <button (click)='cancelRejection()' class="btn btn-default btn-sm align-self-start custom-required-actions-item-button w-100">
              {{ 'shared.button_cancel' | translate }}
            </button>
          </div>
          <div class="ms-auto" style="min-width: 38%">
            <button (click)='reject()' class="btn btn-danger btn-sm align-self-start custom-required-actions-item-button w-100">
              {{ 'reject' | translate }}
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isActionComplete">
        <div *ngIf="isImplementedByCustomer" class="alert alert-success">
          {{ 'ra_onsite_recommendation_approval_customer_implemented_approval' | translate }}
        </div>
        <div *ngIf="!isImplementedByCustomer" class="alert alert-success">
          {{ 'ra_onsite_recommendation_approval_partner_implemented_approval' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="isActionRejected">
        <div class="alert alert-danger">
          {{ 'ra_onsite_recommendation_approval_feedback_submitted' | translate }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
