<div class="card text-center">
  <div class="card-body d-flex flex-column">
    <h4 class="card-title text-primary text-center custom-tile-header">
      {{ 'local_listings.duplicate_listing_supression' | translate}}
      <small>
        <helpPopover placement='top' html="{{ 'local_listings.duplicate_listing_supression_tooltip' | translate }}">
        </helpPopover>
      </small>
    </h4>
    <div class="row">
      @if (chartData && chartLabels && chartColors) {
      <div class="col-12">
        @if (totalCount > 0) {
        <div class="relative">
          <div class="p-2 text-center text-md-left d-flex flex-column h-100">
            <canvas height="350" width="450" class="mx-auto my-auto" baseChart [data]="data" [type]="'doughnut'"
              [options]="options" [legend]="true">
            </canvas>
          </div>
        </div>
        } @else {
        <div class="p-2 text-center text-md-left d-flex flex-column h-100">
          <div>
            <h5 class="text-muted">{{ 'local_listings.duplicate_listing_supression_no_duplicates' | translate}}</h5>
            <i class="fa fa-broom text-muted fa-5x align-text-top"></i>
          </div>
        </div>
        }
      </div>
      }
    </div>
  </div>
</div>