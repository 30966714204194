<div class="row">
  <div class="col-md-3">
    <app-about [type]="type"></app-about>
  </div>
  <div class="col-md-5">
    <div class="card">
      <div class="card-body">
        <h5>{{value.title}}</h5>
        <h6 *ngIf="value.url" class=""><a target="_blank" href="{{value.url}}">{{ value.url }}</a></h6>
        <p>{{ value.description }}</p>
        <ul>
          <li *ngFor="let i of value.keywords">
            <span class="badge bg-secondary">{{ i }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>