import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SeoContentSurvey } from './models/seo-content-survey';
import { SessionService } from './session.service';

@Injectable()
export class SeoContentSurveyService {
  private baseUrl: string;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  public get(): Observable<SeoContentSurvey> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          if (!x) {
            return of(null);
          }

          return this.http
            .get<SeoContentSurvey[]>(`${this.baseUrl}SeoContentSurvey/${x.id}/get`);
        })
      );
  }

  public save(seoContentSurvey: SeoContentSurvey): Observable<{}> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          if (!x) {
            return of(null);
          }

          return this.http
            .post(`${this.baseUrl}SeoContentSurvey/${x.id}/save`, seoContentSurvey);
        })
      );
  }
}
