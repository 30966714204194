<div class="card" id="forgot-password-card">
  <div class="card-body">
    <div class="container p-4" *hideKey="'Login-Forgot-Password'">
        <form class="form-signin p-4" [formGroup]="form" (submit)="submit($event)" novalidate>
          <div class="alert alert-info" *ngIf="sent">{{ 'recover_password_instructions' | translate }}</div>
          <h2 class="form-signin-heading pb-4">{{ 'button_forgot_password' | translate }}</h2>
          <div class="form-group pb-3">
            <label for="inputEmail" class="sr-only">{{ 'content_login_username' | translate }}</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="{{ 'content_login_username' | translate }}" required="" autofocus="" autocomplete="off"
              formControlName="email" [ngClass]="{'is-invalid' : form.controls['email'].dirty && form.controls['email'].invalid }">
            <div class="invalid-feedback">
                {{ 'enter_valid_email' | translate }}
            </div>
          </div>

          <div class="d-grid gap-2">
            <button class="btn btn-lg btn-primary" type="submit">{{ 'continue' | translate }}</button>
            <button class="btn btn-lg btn-outline-secondary" routerLink="/log-in">{{ 'button_back_to_login' | translate }}</button>
          </div>
        </form>
      </div>
  </div>
</div>
