import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { SessionService } from './session.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class SessionRequired  {
    constructor(private router: Router, private sessionService: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.queryParams['y']) {
            this.sessionService.redirectUrl = state.url.split('?')[0];
            this.router.navigate([''], { queryParams: route.queryParams });
            return false;
        }

        return this.sessionService.isAuthorized()
            .pipe(
                tap(x => {
                    if (!x) {
                        this.router.navigateByUrl('log-in');
                        return false;
                    }

                    return true;
                })
            );
    }
}

@Injectable()
export class SessionProhibited  {
    constructor(private router: Router, private sessionService: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.sessionService.isNotAuthorized()
            .pipe(
                tap(x => {
                    if (!x) {
                        this.router.navigateByUrl('accounts');
                        return false;
                    }

                    return true;
                })
            );
    }
}
