import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../shared';
import { environment } from '../../environments/environment';
import { GuaranteeAudit } from '../shared/models/guarantee-audit';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class GuaranteeService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  public getGuarantee(): Observable<GuaranteeAudit> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<GuaranteeAudit>(`${this.baseUrl}account/${x.id}/guarantee`) :
            of(null);
        })
      );
  }
}
