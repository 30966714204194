import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

import { BaseComponent } from '../../../base.component';
import { KeywordRankService } from '../../../shared/keyword-rank.service';
import { KeywordRank, KeywordRankHistory } from '../../../shared/models/';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';

@Component({
  selector: 'app-rank-history',
  templateUrl: './rank-history.component.html',
  styleUrls: ['./rank-history.component.css'],
  providers: [BaseChartDirective, DatePipe]
})
export class RankHistoryComponent extends BaseComponent implements OnInit {
  @Input() data: KeywordRank;

  @Input() set isVisible(visibility: boolean) {
    if (!visibility) { return; }

    if (this.dataRequested) { return; }

    this.load();
  }

  public lineChartData: Array<any> = [{ data: [], label: 'Main' }, { data: [], label: 'Halo' }];
  public chartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        label: 'Main',
        data: [],
        fill: false,
        tension: 0.4,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderWidth: 0.9,
        pointBorderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      {
        label: 'Halo',
        data: [],
        fill: false,
        tension: 0.4,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderWidth: 0.9,
        pointBorderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
    ]
  };
  public lineChartLabels: Array<Date>;
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const data = (tooltipItem.raw) as KeywordRankDataPoint;
            let featuredSnipped = data.includesFeaturedSnippet ? ' (Featured Snippet)' : '';
            return `# ${tooltipItem.parsed.y}${featuredSnipped}`;
          },
          title: (tooltipItem) => {
            return this.datePipe.transform(tooltipItem[0].label, 'shortDate', 'UTC');
          }
        },
        cornerRadius: 0,
        displayColors: false,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        caretPadding: 10
      }
    },
    scales: {
      y: {
        reverse: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        min: 0,
        max: 101,
        ticks: {
          stepSize: 20,
          callback: function (label, e, r) {
            const x = label as number;
            if (x === 0) {
              return '1';
            } else if (x >= 100) {
              return '100+';
            } else {
              return label;
            }
          }
        }
      },
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          maxRotation: 45,
          minRotation: 20
        },
        type: 'time',
        time: {
          displayFormats: {
            quarter: 'MM/YYYY',
            month: 'MM/YYYY',
            week: 'L',
            day: 'L'
          },
          minUnit: 'week'
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };

  public chartColors: Array<any>;
  public mainKeywordColor: any;
  public haloKeywordColor: any;

  private dataRequested = false;

  constructor(private keywordRankService: KeywordRankService, private datePipe: DatePipe, private chartColorService: ChartColorService) {
    super();
  }

  ngOnInit() {
    this.chartColorService.lineWithPoints
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.mainKeywordColor = chartColors[0].pointBorderColor;
        this.haloKeywordColor = chartColors[1].pointBorderColor;
        this.chartData.datasets[0].pointBackgroundColor = this.mainKeywordColor;
        this.chartData.datasets[0].borderColor = this.mainKeywordColor;
        this.chartData.datasets[1].pointBackgroundColor = this.haloKeywordColor;
        this.chartData.datasets[1].borderColor = this.haloKeywordColor;
      });
  }

  private load(): void {
    this.dataRequested = true;
    this.keywordRankService.getKeywordRankHistory(this.data)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        this.data.rankHistory = x;

        this.lineChartLabels = this.data.rankHistory.map((y: KeywordRankHistory) => {
          return (y && y.rankDate) ? y.rankDate : undefined;
        });

        const haloKeywords = this.data.rankHistory
          .filter((y: KeywordRankHistory) => {
            return (y && y.isTracking);
          });

        const mainKeywords = this.data.rankHistory
          .filter((y: KeywordRankHistory) => {
            return (y && !y.isTracking);
          });

        this.lineChartData[0].data = mainKeywords.map((y: KeywordRankHistory) => {
          return { x: y.rankDate, y: y.rank, includesFeaturedSnippet: y.includesFeaturedSnippet };
        });

        this.lineChartData[1].data = haloKeywords.map((y: KeywordRankHistory) => {
          return { x: y.rankDate, y: y.rank, includesFeaturedSnippet: y.includesFeaturedSnippet };
        });

        this.chartData.datasets[0].data = this.lineChartData[0].data;
        this.chartData.datasets[1].data = this.lineChartData[1].data;
      });
  }
}

interface KeywordRankDataPoint {
  x: Date;
  y: number;
  includesFeaturedSnippet: boolean;
}
