<div class="d-flex justify-content-center m-4">
  <div class="card text-white bg-danger mb-3" style="max-width: 60rem;">
    <div class="card-body">
      <p class="card-text">
       <span *ngIf='showGeneralError'>{{ 'error_general' | translate }}</span>
       <span *ngIf='showNotSetupError'>{{ 'error_not_setup' | translate }}</span>
       <span *ngIf='showAccountNotExistsError'>{{ 'error_account_not_exists' | translate }}</span>
       <span *ngIf='showInactiveAccountError'>{{ 'error_login_nocustomerusers' | translate }}</span>
       <span *ngIf='showNotAuthorizedError'>{{ 'error_not_authorized' | translate }}</span>
      </p>
    </div>
  </div>
</div>