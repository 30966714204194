

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Error } from './models/error';

@Injectable()
export class LogService {
    private baseUrl: string;
    private errorsToKeep = 10;
    private threshold = 5;
    private mostRecentErrors: Error[];
    constructor(private http: HttpClient) {
        this.baseUrl = environment.baseUrl;
        this.mostRecentErrors = [];
    }

    public logError(error: Error): void {
        // keep cached errors at a maximum
        if (this.mostRecentErrors.length === this.errorsToKeep) {
            this.mostRecentErrors.shift();
        }
        this.mostRecentErrors.push(error);

        // if we've seen the same error multiple times, don't log it
        const sameErrors = this.mostRecentErrors.map(x => x.message).filter(x => x === error.message).length;
        if (sameErrors >= this.threshold) {
            return;
        }

        this.http
            .post(`${this.baseUrl}log`, { Message: error.message, StackTrace: error.stackTrace })
            .subscribe(data => { }, err => console.log(err));
    }
}
