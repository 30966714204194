<div class="card">
  <div class="card-body">
    <h4 class="card-title custom-tile-header text-primary d-inline me-3">{{ 'top_organic_by_city' | translate }}</h4>
    <span class="custom-tile-subheader d-inline">{{ 'top_organic_by_city_desc' | translate }}</span>
    <div *ngIf="hasData" class="mt-3">
      <canvas
        baseChart height="60" class="d-none d-md-block"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
        [legend]="false"
        [colors]="chartColors"
        [chartType]="'bar'">
      </canvas>
      <canvas
        baseChart height="180" class="d-block d-md-none"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="chartOptions"
        [legend]="false"
        [colors]="chartColors"
        [chartType]="'bar'">
      </canvas>
    </div>
  </div>
</div>
