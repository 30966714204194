<div class="card" style="word-wrap: no-wrap;">
  <div class="card-body">
    <div>
      <form [formGroup]="editLocationForm" novalidate (submit)="save($event)">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label for="inputLocationAddress" class="form-label">{{ 'local_listings.location_edit_address' | translate }}</label>
                <input id="inputLocationAddress" class="form-control" placeholder="{{ 'local_listings.location_edit_address' | translate }}" required="" autocomplete="off"
                  formControlName="address" [ngClass]="{'is-invalid' : editLocationForm.controls['address'].dirty && editLocationForm.controls['address'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_address_error' | translate }}</div>
                <input id="inputLocationAddress" class="form-control" placeholder="{{ 'local_listings.location_edit_address2' | translate }}" autocomplete="off"
                  formControlName="address2" [ngClass]="{'is-invalid' : editLocationForm.controls['address2'].dirty && editLocationForm.controls['address2'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_address_error' | translate }}</div>
              </div>
              <div class="col-12">
                <label for="inputLocationCity" class="form-label">{{ 'local_listings.location_edit_city' | translate }}</label>
                <input id="inputLocationCity" class="form-control" placeholder="{{ 'local_listings.location_edit_city' | translate }}" required="" autocomplete="off"
                  formControlName="city" [ngClass]="{'is-invalid' : editLocationForm.controls['city'].dirty && editLocationForm.controls['city'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_city_error' | translate }}</div>
              </div>
              <div class="col-12">
                <label for="inputLocationState" class="form-label">{{ 'local_listings.location_edit_state' | translate }}</label>
                  <select id="selectLocationState" class="form-select pt-1 pb-1" (change)="stateChanged($event.target)"
                    formControlName="stateId" [ngClass]="{'is-invalid' : editLocationForm.controls['stateId'].dirty && editLocationForm.controls['stateId'].invalid }">
                    <option *ngFor="let s of states" value="{{ s.id }}">{{ s.abbreviation }}</option>
                  </select>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_state_error' | translate }}</div>
              </div>
              <div class="col-12">
                <label for="inputLocationZip" class="form-label">{{ 'local_listings.location_edit_zip' | translate }}</label>
                <input id="inputLocationZip" class="form-control" placeholder="{{ 'local_listings.location_edit_zip' | translate }}" required="" autocomplete="off"
                  formControlName="zip" [ngClass]="{'is-invalid' : editLocationForm.controls['zip'].dirty && editLocationForm.controls['zip'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_zip_error' | translate }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <label for="inputLocationPhone" class="form-label">{{ 'local_listings.location_edit_phone' | translate }}</label>
                <input id="inputLocationPhone" class="form-control" placeholder="{{ 'local_listings.location_edit_phone' | translate }}" required="" autocomplete="off"
                  formControlName="phone" [ngClass]="{'is-invalid' : editLocationForm.controls['phone'].dirty && editLocationForm.controls['phone'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_phone_error' | translate }}</div>
              </div>
              <div class="col-12">
                <label for="inputLocationWebsite" class="form-label">{{ 'local_listings.location_edit_website' | translate }}</label>
                <input id="inputLocationWebsite" class="form-control" placeholder="{{ 'local_listings.location_edit_website' | translate }}" autocomplete="off"
                  formControlName="website" [ngClass]="{'is-invalid' : editLocationForm.controls['website'].dirty && editLocationForm.controls['website'].invalid }"/>
                <div class="invalid-feedback">{{ 'local_listings.location_edit_website_error' | translate }}</div>
              </div>
              <div class="col-12" *ngIf="showContactUs || isEditMode">
                <div class="alert alert-warning mt-3">
                  <span *hideKey="'Header-ContactUs'" [innerHTML]="'local_listings.location_contact_us' | translate"></span>
                  <span *ngIf="showContactUs">&nbsp;</span>
                  <span *ngIf="isEditMode">{{ 'local_listings.location_edit_message' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 text-end">
            <ng-container *ngIf="isEditMode">
              <button class="btn btn-success me-2" type="submit">{{ 'shared.button_save' | translate }}</button>
              <button class="btn btn-danger" (click)="toggleEditMode(false)">{{ 'shared.button_cancel' | translate }}</button>
            </ng-container>
            <ng-container *ngIf="!isEditMode">
              <button class="btn btn-outline-primary" (click)="toggleEditMode(true);">{{ 'local_listings.location_edit' | translate }}</button>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="alert alert-success" *ngIf="state === 'in'" [@fadeInOut]="state">{{ 'local_listings.location_success' | translate }}</div>
