import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { share } from 'rxjs/operators';


@Injectable()
export class ChartColorService {

    private _chartColors: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(null);
    private _filledLine: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private _filledLineWithPoints: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private _lineWithPoints: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private _bar: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private _pie: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
    private _stackedBar: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);

    constructor(private sessionService: SessionService) {
        this.sessionService.siteConfig
            .subscribe(config => {
                if (!config.chartColors) {
                    return;
                }
                // TODO: Possibly us a generator to extend the size of the array.
                // We need to repeat the colors once so that we have an array with enough colors
                const allColors = config.chartColors.concat(config.chartColors);
                this.setChartColors(allColors);
                this.setFilledLine(allColors);
                this.setFilledLineWithPoints(allColors);
                this.setLineWithPoints(allColors);
                this.setPie(allColors);
                this.setStackedBar(allColors);
                this.setBar(allColors);
            });
    }

    private setChartColors(chartColors: Array<string>) {
        this._chartColors.next(chartColors);
    }

    get chartColors(): Observable<Array<string>> {
        return this._chartColors.asObservable()
            .pipe(
                share()
            );
    }

    private setFilledLine(chartColors: Array<string>) {
        const lineColors = [];
        chartColors.forEach(color => {
            const splitColor = color.split(',');
            const colorWithoutOpacity = `${splitColor[0]}, ${splitColor[1]}, ${splitColor[2]}`;
            const backgroundColor = `${colorWithoutOpacity}, 0.2)`;
            const borderColor = `${colorWithoutOpacity}, 1.0)`;
            lineColors.push({ backgroundColor: backgroundColor, borderColor: borderColor, borderWidth: 1 });
        });
        this._filledLine.next(lineColors);
    }

    get filledLine(): Observable<Array<any>> {
        return this._filledLine.asObservable()
            .pipe(
                share()
            );
    }

    private setFilledLineWithPoints(chartColors: Array<string>) {
        const lineColors = [];
        chartColors.forEach(color => {
            const splitColor = color.split(',');
            const colorWithoutOpacity = `${splitColor[0]}, ${splitColor[1]}, ${splitColor[2]}`;
            const borderColor = `${colorWithoutOpacity}, 1.0)`;
            const pointColor = `${colorWithoutOpacity}, 1.0)`;
            const backgroundColor = `${colorWithoutOpacity}, 0.2)`;
            lineColors.push({ backgroundColor: backgroundColor, borderColor: borderColor, pointBorderColor: pointColor,
                borderWidth: 1, pointBorderWidth: 1 });
        });
        this._filledLineWithPoints.next(lineColors);
    }

    get filledLineWithPoints(): Observable<Array<any>> {
        return this._filledLine.asObservable()
            .pipe(
                share()
            );
    }

    private setLineWithPoints(chartColors: Array<string>) {
        const lineColors = [];
        chartColors.forEach(color => {
            const splitColor = color.split(',');
            const colorWithoutOpacity = `${splitColor[0]}, ${splitColor[1]}, ${splitColor[2]}`;
            const borderColor = `${colorWithoutOpacity}, 1.0)`;
            const pointColor = `${colorWithoutOpacity}, 1.0)`;
            lineColors.push({ backgroundColor: 'rgba(0, 0, 0, 0.0)', borderColor: borderColor,
                pointBorderColor: pointColor, borderWidth: 1, pointBorderWidth: 1 });
        });
        this._filledLineWithPoints.next(lineColors);
    }

    get lineWithPoints(): Observable<Array<any>> {
        return this._filledLineWithPoints.asObservable()
            .pipe(
                share()
            );
    }

    private setBar(chartColors: Array<string>) {
        this._bar.next([{ backgroundColor: chartColors }]);
    }

    get bar(): Observable<Array<any>> {
        return this._bar.asObservable()
            .pipe(
                share()
            );
    }

    private setPie(chartColors: Array<string>) {
        this._pie.next([{ backgroundColor: chartColors }]);
    }

    get pie(): Observable<Array<any>> {
        return this._pie.asObservable()
            .pipe(
                share()
            );
    }

    private setStackedBar(chartColors: Array<string>) {
        const colors = [];
        chartColors.forEach((color: any) => {
          colors.push({backgroundColor: color});
        });
        this._stackedBar.next(colors);
    }

    get stackedBar(): Observable<Array<any>> {
        return this._stackedBar.asObservable()
            .pipe(
                share()
            );
    }
}
