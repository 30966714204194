<div class="row">
  <div class="col-md-3">
    <app-about [type]="type"></app-about>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h5>{{ 'address_title' | translate }}</h5>
        <address>
          <strong>{{value.name}}</strong>
          <br> {{value.address}}
          <br> {{value.city}}
          <span *ngIf="value.city && value.state">,</span> {{value.state}} {{value.zip}}
          <br> {{ 'telephone_label' | translate }} {{ value.phone}}
          <br> {{ value.email }}
        </address>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <h5>{{ 'description_title' | translate }}</h5>
        {{ value.description }}
      </div>
    </div>
  </div>
</div>