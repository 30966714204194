import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
  public minutes: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    let minutes = this.route.snapshot.queryParamMap.get('minutes');
    this.minutes = minutes ? `${minutes} minutes` : "Unknown";
  }
}