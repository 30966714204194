import { BaseComponent } from '../../../base.component';
import { Component, OnInit } from '@angular/core';

import { ProTip } from '../../../shared/models/';
import { ProTipService } from '../../../shared/pro-tip.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pro-tips',
  templateUrl: './pro-tips.component.html',
  styleUrls: ['./pro-tips.component.css']
})
export class ProTipsComponent extends BaseComponent implements OnInit {

  public proTips: ProTip[];

  constructor(private proTipService: ProTipService) {
    super();
  }

  ngOnInit() {
    this.proTipService
      .get()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(tips => {
        this.proTips = tips;
      });
  }
}
