<div class="card text-center">
  <div class="card-body d-flex flex-column mbg-card">
    <h4 class="card-title text-primary text-center custom-tile-header" style="min-height:2em;">
      {{ 'keyword_target' | translate }}
    </h4>
    <div class="row">
      <div class="col-md-7">
        <div class="pt-4 mt-1">
          <canvas *ngIf="isLoaded" baseChart [datasets]="data" [labels]="labels" [options]="options" [legend]="false"
            [chartType]="'horizontalBar'" [colors]="chartColors" height="71"></canvas>
        </div>
        <span class="text-muted pt-1" *ngIf="isLoaded && guaranteeAudit.numberOfKeywordsOnFirstPage === 1">
          {{ guaranteeAudit.numberOfKeywordsOnFirstPage }} {{ 'main_keyword_first_page' | translate }}
        </span>
        <h5 class="text-muted pt-1" *ngIf="isLoaded && guaranteeAudit.numberOfKeywordsOnFirstPage >= 2">
          {{ guaranteeAudit.numberOfKeywordsOnFirstPage }} {{ 'main_keywords_first_page' | translate }}
        </h5>
      </div>
      <div class="col-md-5">
        <div>
        <span class="tile-value text-success">
          <span class="fa fa-chart-line me-2 text-success c-fa custom-tile-icon"></span>{{ targetPercent | percent }}
        </span>
        </div>
        <span class="align-middle text-success">{{ 'keyword_target_to' | translate }}</span>
      </div>
    </div>
  </div>
</div>
