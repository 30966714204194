<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-7">
            <h4 class="card-title">{{ 'google_choose_website' | translate }}</h4>
            <div class="input-group mb-2">
              <input type="text" class="form-control" *ngIf="hasMultipleAccounts" placeholder="Search" #search (keyup)="filter(search.value)">
            </div>
          </div>
          <div class="col-md-5">
            <button (click)="authorizeDifferentAccount()" class="btn btn-primary float-end">
              <i class="fa fa-exchange-alt"></i> {{ 'google_choose_different_account' | translate }}
            </button>
          </div>
        </div>
      </div>

      <ul class="list-group">
        <li class="list-group-item flex-column align-items-start" *ngFor="let account of filteredAccounts">
          <div class="d-flex">
            <span class="p-2"> {{ account.displayName }} (property ID: {{ account.profileId }})</span>
            <span *ngIf="account.analyticsType == 2" class="badge badge-secondary my-auto">GA4</span>
            <button (click)="select(account)" class="btn btn-primary p-2 ms-auto">
              <i class="fa fa-plug"></i> {{ 'content_connect_account' | translate }} </button>
          </div>
        </li>
      </ul>
      <div class="m-4 alert alert-info" *ngIf="!filteredAccounts.length">
        {{ 'message_no_results' | translate }}
      </div>
    </div>
  </div>
</div>
