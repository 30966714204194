import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { fadeInOut } from '../../shared';
import { CustomerAccount } from '../../shared/models/customer-account';
import { CustomerAccountService } from '../../shared/customer-account.service';
import { CustomerAccountTypes } from '../../shared/models/customer-account-types';
import { forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-website-access',
  templateUrl: './website-access.component.html',
  styleUrls: ['./website-access.component.css'],
  animations: [fadeInOut()]
})
export class WebsiteAccessComponent extends BaseComponent implements OnInit {
  public credentialsForm: UntypedFormGroup;
  public customerAccounts: CustomerAccount[];
  public state: 'in' | 'out' = 'out';
  public showFormErrors = false;
  private mockPassword: string;

  constructor(
    public websiteAccessService: CustomerAccountService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService) {
    super();
  }

  private urlValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const value: string = control.value.toString().toLowerCase();
    const pattern = new RegExp(
      '^((https?|s?ftp):\\/\\/)?' + // protocol
      '((([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-z\\d]([\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-z\\d-]*[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-z\\d])*)\\.)+[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFa-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z-\\d%_&\\:.~+]*)*' + // port and path
      '(\\?[*@;:&a-z\\d%_\\./~+=-]*)?' + // query string
      '(#[!-a-z\\d_/]*)?$',
      'i'); // fragment locator

    if (!pattern.test(value)) {
        return { 'urlInvalid': true };
    }
    return null;
  }

  ngOnInit() {
    this.reloadForm();
  }

  private reloadForm(): void {
    const customerAccountTypes = [
      CustomerAccountTypes.CMSCustomerAccess,
      CustomerAccountTypes.FTP,
      CustomerAccountTypes.DomainRegistrar,
      CustomerAccountTypes.WebsiteHosting
    ];
    forkJoin({
      mockPassword: this.websiteAccessService.getMockPassword().pipe(first()),
      customerAccounts: this.websiteAccessService.get(customerAccountTypes).pipe(first())
    }).subscribe(results => {
        this.state = 'out';
        this.mockPassword = results.mockPassword;
        this.customerAccounts = results.customerAccounts;
        this.customerAccounts.filter(x => x.isPasswordSet).forEach(x => {
          x.setPassword = results.mockPassword;
        });

        const forms = this.customerAccounts.map(x => {
          return [CustomerAccountTypes[x.customerAccountTypeId], this.fb.group({
            url: [x.url, this.urlValidator],
            port: [x.port],
            username: [x.username],
            password: [x.setPassword]
          }, { validators: this.generateMissingDetailsValidator(x) })];
        });
        this.credentialsForm = this.fb.group(Object['fromEntries'](forms));
        this.credentialsForm.valueChanges
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            if (this.credentialsForm && this.credentialsForm.valid) {
              this.showFormErrors = false;
            }
          });
      });
  }

  public getFormName(customerAccount: CustomerAccount): string {
    return CustomerAccountTypes[customerAccount.customerAccountTypeId];
  }

  public getForm(customerAccount: CustomerAccount): UntypedFormGroup {
    return <UntypedFormGroup>this.credentialsForm.get(this.getFormName(customerAccount));
  }

  public getErrorMessage(form: UntypedFormGroup): string {
    if (form.get('url').invalid) {
      return this.translateService.instant('error_website_invalid');
    } else if (form.get('username').value && form.get('password').value) {
      return this.translateService.instant('error_website_required');
    } else {
      return this.translateService.instant('error_login_username_password_required');
    }
  }

  private isDirty(customerAccount: CustomerAccount, form: UntypedFormGroup): boolean {
    const newValues = this.extractForm(form);
    return customerAccount.url !== newValues.url
      || customerAccount.port !== newValues.port
      || customerAccount.username !== newValues.username
      || (!!newValues.setPassword && newValues.setPassword !== this.mockPassword);
  }

  private extractForm(form: UntypedFormGroup): CustomerAccount {
    const url = form.get('url').value;
    const port = form.get('port').value;
    const username = form.get('username').value;
    const password = form.get('password').value;
    return <CustomerAccount>{
      url: url,
      port: port,
      username: username,
      setPassword: password
    };
  }

  private generateMissingDetailsValidator(customerAccount: CustomerAccount): (form: UntypedFormGroup) => ValidationErrors | null {
    return (form: UntypedFormGroup) => {
      const isDirty = this.isDirty(customerAccount, form);

      if (isDirty) {
        const newValues = this.extractForm(form);
        const empty = !newValues.url && !newValues.port && !newValues.username && !newValues.setPassword;
        const missingRequiredValue = !newValues.url || !newValues.username || !newValues.setPassword;
        if (!empty && missingRequiredValue) {
          return { missingDetails: true };
        }
      }

      return null;
    };
  }

  public save(event: Event): void {
    event.preventDefault();

    this.showFormErrors = false;
    if (this.credentialsForm.invalid) {
      this.showFormErrors = true;
      return;
    }

    const accountsToSave = this.customerAccounts.map(x => {
      const form = this.getForm(x);
      const updated = Object.assign({}, x);
      const newValues = this.extractForm(form);

      updated.url = newValues.url;
      updated.port = newValues.port;
      updated.username = newValues.username;
      updated.setPassword = newValues.setPassword;
      return updated;
    });

    this.credentialsForm.disable();
    this.websiteAccessService.save(accountsToSave).subscribe(() => {
      this.credentialsForm.markAsPristine();
      this.state = 'in';
      setTimeout(() => {
        this.reloadForm();
      }, 5000);
    });
  }
}
