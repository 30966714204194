import { SessionService } from './session.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class AuthorizeRequestInterceptor implements HttpInterceptor {
    constructor(private sessionService: SessionService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.sessionService.token
            .pipe(
                take(1),
                map(x => {
                    return x ? x.accessToken : '';
                }),
                mergeMap(token => {
                    if (token) {
                        const cloned = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
                        return next.handle(cloned);
                    } else {
                        return next.handle(req);
                    }
                })
            );
    }
}

export function AuthorizeRequestInterceptorFactory(sessionService: SessionService) {
    return new AuthorizeRequestInterceptor(sessionService);
}

export let AuthorizeRequestInterceptorFactoryProvider = {
    provide: AuthorizeRequestInterceptor,
    deps: [SessionService],
    useFactory: AuthorizeRequestInterceptorFactory
};
