import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-keywords-top-10',
  templateUrl: './keywords-top-10.component.html',
  styleUrls: ['./keywords-top-10.component.css']
})
export class KeywordsTop10Component extends BaseComponent implements OnInit {

  public keywordsTop10: number;
  public keywordsTop10ChangePercentage: string;
  public showChange: boolean;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) {
          return;
        }

        this.keywordsTop10 = x.keywordsTop10;
        this.keywordsTop10ChangePercentage = `${x.keywordsTop10ChangePercentage}%`;
        this.showChange = x.keywordsTop10ChangePercentage > 0;
      });
  }
}
