import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../base.component';
import { fadeInOut } from '../../shared';
import { SeoContentSurvey } from '../../shared/models/seo-content-survey';
import { SeoContentSurveyService } from '../../shared/seo-content-survey.service';

@Component({
  selector: 'app-seo-content-survey',
  templateUrl: './seo-content-survey.component.html',
  styleUrls: ['./seo-content-survey.component.css'],
  animations: [fadeInOut()]
})
export class SeoContentSurveyComponent extends BaseComponent implements OnInit {
  public seoContentSurveyForm: UntypedFormGroup;
  public seoContentSurvey: SeoContentSurvey;
  public state: 'in' | 'out' = 'out';

  constructor(
    public seoContentSurveyService: SeoContentSurveyService,
    private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.reloadForm();
  }

  private reloadForm(): void {
    this.seoContentSurveyService.get()
      .subscribe(seoContentSurvey => {
        this.state = 'out';
        this.seoContentSurvey = seoContentSurvey;

        this.seoContentSurveyForm = this.fb.group({
          businessDescription: [this.seoContentSurvey.businessDescription],
          limitations: [this.seoContentSurvey.limitations],
          sellingPoints: [this.seoContentSurvey.sellingPoints],
          demographic: [this.seoContentSurvey.demographic],
          persona: [this.seoContentSurvey.persona],
          goalsTactics: [this.seoContentSurvey.goalsTactics],
        });
      });
  }

  public save(event: Event): void {
    event.preventDefault();

    if (this.seoContentSurveyForm.invalid) {
      return;
    }

    const updated = Object.assign({}, this.seoContentSurvey);
    updated.businessDescription = this.seoContentSurveyForm.get('businessDescription').value;
    updated.limitations = this.seoContentSurveyForm.get('limitations').value;
    updated.sellingPoints = this.seoContentSurveyForm.get('sellingPoints').value;
    updated.demographic = this.seoContentSurveyForm.get('demographic').value;
    updated.persona = this.seoContentSurveyForm.get('persona').value;
    updated.goalsTactics = this.seoContentSurveyForm.get('goalsTactics').value;

    this.seoContentSurveyForm.disable();
    this.seoContentSurveyService.save(updated).subscribe(() => {
      this.seoContentSurveyForm = undefined;
      this.state = 'in';
      setTimeout(() => {
        this.reloadForm();
      }, 5000);
    });
  }
}
