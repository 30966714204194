import { Component, Input } from '@angular/core';

import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements IWorkItemComponent {
  @Input() public value: any;
  @Input() type: number;
}
