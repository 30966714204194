<div [@fadeInOut]="'active'">
  <div class="flex-box" style="min-width: 68%;padding: 0px !important">
    <div class="flex-container">
      <app-keywords-top-10 class="flex-box sm-tile" *hideKey="'Seo-Dashboard-KeywordsTop10'"></app-keywords-top-10>
      <app-keywords-top-25 class="flex-box sm-tile" *hideKey="'Seo-Dashboard-KeywordsTop25'"></app-keywords-top-25>
      <app-keywords-top-50 class="flex-box sm-tile" *hideKey="'Seo-Dashboard-KeywordsTop50'"></app-keywords-top-50>
      <app-total-keywords-improved class="flex-box sm-tile" *hideKey="'Seo-Dashboard-TotalKeywordsImproved'">
      </app-total-keywords-improved>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-primary custom-tile-header">{{ 'keywords' | translate }}</h4>
      <div class="text-end m-2">
        <button class="btn btn-sm btn-primary" type="button" (click)="export()"><i class="fa fa-download" aria-hidden="true"></i> {{ 'export' | translate }}</button>
      </div>

      <table class="table table-hover">
        <thead>
          <tr>
            <th class="text-start sortable d-none d-md-table-cell" (click)="sort('isTracking')">{{ 'table_header_type' | translate }}
              <sortIcon class="p-0" column="isTracking" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
              <helpPopover class="p-0" placement='auto' html="<p>{{ 'main_keywords_help' | translate }}</p><p>{{ 'halo_keywords_help' | translate }}</p>">
              </helpPopover>
            </th>
            <th class="text-start sortable" (click)="sort('keyword')">{{ 'table_header_keyword' | translate }}
              <sortIcon column="keyword" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
            </th>
            <th class="text-center sortable d-none d-md-table-cell" (click)="sort('startDate')">{{
              'table_header_start_date' | translate }}
              <sortIcon class="p-0" column="startDate" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
              <helpPopover class="p-0" placement='auto' html="{{ 'start_date_help' | translate }}">
              </helpPopover>
            </th>
            <th class="text-center sortable d-none d-sm-table-cell" (click)="sort('startRank')">{{
              'table_header_start_rank' | translate }}
              <sortIcon class="p-0" column="startRank" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
              <helpPopover class="p-0" placement='auto' html="{{ 'start_rank_help' | translate }}">
              </helpPopover>
            </th>
            <th class="text-center sortable" (click)="sort('currentRank')">{{ 'table_header_current_rank' | translate }}
              <sortIcon class="p-0" column="currentRank" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
              <helpPopover class="p-0" placement='auto' html="{{ 'current_rank_help' | translate }}">
              </helpPopover>
            </th>
            <th class="text-center sortable d-none d-lg-table-cell" (click)="sort('firstPageUrl')">{{
              'table_header_top_10' | translate }}
              <sortIcon class="p-0" column="firstPageUrl" sortColumn="{{sortColumn}}" sortOrder="{{sortOrder}}"></sortIcon>
              <helpPopover class="p-0" placement='auto' html="{{ 'top_10_help' | translate }}">
              </helpPopover>
            </th>
            <th class="text-center d-none d-lg-table-cell">{{ 'table_header_history' | translate }}
              <helpPopover class="p-0" placement='auto' html="{{ 'history_help' | translate }}">
              </helpPopover>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="keywordRanks.length === 0">
            <td class="table-info" colspan="9">{{ 'no_keywords_found' | translate }}</td>
          </tr>
          <ng-container *ngFor="let row of keywordRanks">
            <tr>
              <td class="text-start d-none d-md-table-cell">
                {{ (row.isTracking ? 'keyword_type_tracking' : 'keyword_type_main') | translate }}
                <i *ngIf="row.includesFeaturedSnippet" class="far fa-star fa-sm" title="{{ 'keyword_is_featured_snippet' | translate }}"></i>
                <i *ngIf="row.hasBeenActive" class="fas fa-exchange-alt fa-sm" title="{{ 'keyword_was_active' | translate }}"></i>
              </td>
              <td class="text-start">
                {{ row.keyword }}
              </td>
              <td class="text-center d-none d-md-table-cell">
                {{ row.startDate | localDate }}
              </td>
              <td class="text-center d-none d-sm-table-cell">
                {{ row.startRank | undefinedNumber }}
              </td>
              <td class="text-center">
                <span class="text-success" *ngIf="row.currentRank < row.startRank"><i class="fa fa-caret-up" aria-hidden="true"></i></span> {{ row.currentRank | undefinedNumber }}
              </td>
              <td class="text-center d-none d-lg-table-cell">
                <a *ngIf="!!row.firstPageUrl" href="{{row.firstPageUrl}}" target="blank">
                  <button class="btn btn-sm btn-success">
                    <i class="fa fa-trophy" aria-hidden="true"></i> {{row.firstPageDate | localDate}}
                  </button>
                </a>
              </td>
              <td class="text-end d-none d-lg-table-cell">
                <button class="btn btn-sm btn-primary" (click)="row.rankHistoryIsCollapsed = !row.rankHistoryIsCollapsed">
                  <i class="fa fa-chart-line" aria-hidden="true"></i>
                  <ng-container *ngIf="row.rankHistoryIsCollapsed"> {{ 'button_view' | translate }}</ng-container>
                  <ng-container *ngIf="!row.rankHistoryIsCollapsed"> {{ 'button_hide' | translate }}</ng-container>
                  <span class="fa fa-graph"></span>
                </button>
              </td>
            </tr>
            <tr [ngbCollapse]="row.rankHistoryIsCollapsed">
              <td colspan="9" class="d-none d-lg-table-cell">
                <div class="m-4">
                  <app-rank-history [data]="row" [isVisible]="!row.rankHistoryIsCollapsed "></app-rank-history>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>


      <div *ngIf="someKeywordsHaveSnippets">
        <i class="far fa-star"></i> {{ 'keyword_is_featured_snippet' | translate }}
      </div>
      <div *ngIf="someKeywordsConvertedToHalo">
        <i class="fas fa-exchange-alt"></i> {{ 'keyword_was_active' | translate }}
      </div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="account; let a">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <p class="mb-0 text-muted text-center custom-tile-subheader" *hideKey="'Seo-CustomerUrl'">{{ a.url }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
