import { Component, Input } from '@angular/core';

import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-site-information',
  templateUrl: './site-information.component.html',
  styleUrls: ['./site-information.component.css']
})
export class SiteInformationComponent implements IWorkItemComponent {
  @Input() public value: any;
  @Input() public type: number;
}
