import { Component } from '@angular/core';

@Component({
  selector: 'app-no-products-landing-page',
  templateUrl: './no-products-landing-page.component.html',
  styleUrls: ['./no-products-landing-page.component.scss']
})
export class NoProductsLandingPageComponent {

  constructor() { }

}
