<div>
  <div class="alert alert-success" *ngIf="state === 'in'" [@fadeInOut]="state">
    {{ 'seo_content_survey.saved' | translate }}
  </div>
  <form [formGroup]="seoContentSurveyForm" (submit)="save($event)" *ngIf="seoContentSurveyForm">
    <div class="card card-default">
      <div class="card-body">
        <h4 class="card-title text-primary custom-tile-header">
          {{'seo_content_survey.content_survey' | translate}}
        </h4>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.business_overview' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.business_overview' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="businessDescription" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.site_goals' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.site_goals' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="goalsTactics" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.audience' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.audience' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="demographic" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.restrictions' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.restrictions' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="limitations" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.company_persona' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.company_persona' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="persona" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-10">{{ 'seo_content_survey.value_proposition' | translate }}
            <helpPopover placement='auto'
              html="{{ 'seo_content_survey.helptext.value_proposition' | translate}}"></helpPopover>
          </label>
          <div class="col-md-10">
            <textarea formControlName="sellingPoints" maxlength="4000" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 float-end">
      <button class="btn btn-lg btn-success w-100" type="submit"
        [disabled]="!seoContentSurveyForm.dirty">{{ 'shared.button_save' | translate }}</button>
    </div>
  </form>
</div>
