<div class="card">
    <div class="card-body">
      <h4 class="card-title custom-tile-header text-primary d-inline me-3">{{ 'organic_traffic' | translate }}</h4>
      <span class="custom-tile-subheader d-inline">{{ 'organic_traffic_desc' | translate }}</span>
      <div class="mt-3">
        <canvas
          *ngIf="hasData"
          baseChart height="90"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [legend]="false"
          [colors]="chartColors"
          [chartType]="'line'">
        </canvas>
      </div>
    </div>
  </div>
