import { Component, Input, ViewChild } from '@angular/core';
import { SuppressedDuplicates } from '../../models/suppressed-duplicates';
import { ChartOptions } from 'chart.js';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-suppressed-duplicates',
  templateUrl: './suppressed-duplicates.component.html'
})
export class SuppressedDuplicatesComponent extends BaseComponent {
  @Input()
  set suppressedDuplicates(suppressedDuplicates: SuppressedDuplicates) {
    this.totalCount = suppressedDuplicates.totalCount;
    const sortedDirectories = suppressedDuplicates.countByDirectory.sort((a, b) => b.count - a.count);
    const byDirectory = sortedDirectories.slice(0, 3);

    if (sortedDirectories.length > 3) {
      byDirectory.push({ directory: 'Other', count: sortedDirectories.slice(3).reduce((acc, dir) => acc + dir.count, 0) });
    }

    this.chartData = byDirectory.map(dir => dir.count);
    this.chartLabels = byDirectory.map(dir => `${dir.directory} (${dir.count})`);
    this.drawChart();
  }
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  data: any = {
    datasets: [{
      data: null,
      backgroundColor: null
    }],
    labels: null
  };

  totalCount: number;
  chartData: number[];
  chartLabels: string[];
  chartColors: Array<any>;

  options: ChartOptions<'pie'> = {
    responsive: false,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'right'
      }
    }
  };

  constructor(
    private chartColorService: ChartColorService) {
    super();
  }

  ngOnInit() {
    this.chartColorService.pie
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors.map(x => x.backgroundColor)[0];
        this.drawChart();
      });
  }

  drawChart() {
    if (this.chartData && this.chartLabels && this.chartColors) {
      this.data = {
        datasets: [{
          data: this.chartData,
          backgroundColor: this.chartColors
        }],
        labels: this.chartLabels
      };
    }
  }
}
