import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class SeoReady  {
  constructor(private router: Router, private sessionService: SessionService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.sessionService.account
      .pipe(
        map(x => x.seoReady),
        tap(x => {
          if (!x) {
            this.router.navigateByUrl('not-ready');
            return false;
          }

          return true;
        })
      );
  }
}
