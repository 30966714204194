export const languages: { name: string; abbreviation: string }[] = [
  { name: 'Deutsch', abbreviation: 'de' },
  { name: 'English', abbreviation: 'en' },
  { name: 'English - Australian', abbreviation: 'en-AU' },
  { name: 'English - UK', abbreviation: 'en-GB' },
  { name: 'Español', abbreviation: 'es' },
  { name: 'Français', abbreviation: 'fr' },
  { name: 'Français - Canada', abbreviation: 'fr-CA' },
  { name: 'Nederlands', abbreviation: 'nl' },
  { name: 'Suomi', abbreviation: 'fi' }
];

export enum Language { english = 'English' }

export const englishLanguages: string[] = [
  'en',
  'en-AU',
  'en-GB'
];
