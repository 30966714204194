<div class="card mt-3">
  <div class="card-body">
    <h1>Accessibility Statement</h1>
    <hr>
    <span>
      We are committed to providing SEO Reporting tools that are accessible to the widest possible audience, regardless of technology or ability.
    </span>
    <br />
    <br />
    <span>
      We are working to conform with all applicable standards, inclusive of the best practice guidelines found within the Principles of Accessible Design and Web Content Accessibility Guidelines (WCAG) 2.1.
    </span>
    <br />
    <br />
    <span>
      If you would like to request accessibility-related assistance, report any accessibility problems, or request any information in accessible alternative formats, please use one of the methods found within the Contact Us button.
    </span>
  </div>
</div>
