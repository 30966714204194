import { Component, computed, OnInit, output, signal, input } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../base.component';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrl: './date-range-selector.component.scss'
})
export class DateRangeSelectorComponent extends BaseComponent implements OnInit {
  minDate = input<NgbDate, Date>(this.calendar.getNext(this.calendar.getToday(), 'y', -10), {
    transform: x => x ? new NgbDate(x.getFullYear(), x.getMonth() + 1, x.getDate()) : this.minDate()
  });
  maxDate = input<NgbDate, Date>(this.calendar.getNext(this.calendar.getToday(), 'y', 10), {
    transform: x => x ? new NgbDate(x.getFullYear(), x.getMonth() + 1, x.getDate()) : this.maxDate()
  });
  defaultSelectedDays = input<number>();
  pastDaysSelection = input<number[]>([]);
  rangeSelected = output<[Date, Date]>();
  rangeCleared = output<void>();
  pastDaysSelected = signal<number>(null);
  startDate = signal(this.calendar.getToday());
  endDate = signal(this.calendar.getToday());
  currentMonth = signal(this.calendar.getToday().month);
  hoveredDate: NgbDate | null = null;

  displayDateRange = computed(() => {
    if (!this.startDate()) {
      return '';
    } else if (!this.endDate()) {
      return moment(this.toDate(this.startDate())).format('L');
    }
    else {
      return `${moment(this.toDate(this.startDate())).format('L')} - ${moment(this.toDate(this.endDate())).format('L')}`;
    }
  });

  private previousStartDate: NgbDate;
  private previousEndDate: NgbDate;
  private previousPastDaysSelected: number;
  private today = this.calendar.getToday();

  constructor(private calendar: NgbCalendar) {
    super();
  }

  ngOnInit(): void {
    if (this.defaultSelectedDays()) {
      this.setDays(this.defaultSelectedDays());
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.startDate() && !this.endDate()) {
      this.startDate.set(date);
    } else if (this.startDate() && !this.endDate()) {
      if (date.after(this.startDate())) {
        this.endDate.set(date);
      } else {
        this.endDate.set(this.startDate());
        this.startDate.set(date);
      }
    }
    else {
      this.startDate.set(date);
      this.endDate.set(null);
    }

    this.pastDaysSelected.set(null);
  }

  isHovered(date: NgbDate) {
    return (
      this.startDate() && !this.endDate() && this.hoveredDate &&
      ((date.after(this.startDate()) && date.before(this.hoveredDate))
        || (date.before(this.startDate()) && date.after(this.hoveredDate)))
      || (date.equals(this.hoveredDate))
    );
  }

  clear() {
    this.startDate.set(null);
    this.endDate.set(null);
    this.pastDaysSelected.set(null);
  }

  save() {
    if (!this.startDate()) {
      this.savePreviousState();
      this.rangeCleared.emit();
      return;
    }

    this.savePreviousState();
    this.rangeSelected.emit([new Date(this.startDate().year, this.startDate().month - 1, this.startDate().day), new Date(this.endDate().year, this.endDate().month - 1, this.endDate().day)]);
  }

  clearAndSave() {
    this.clear();
    this.save();
  }

  savePreviousState() {
    this.previousStartDate = this.startDate();
    this.previousEndDate = this.endDate();
    this.previousPastDaysSelected = this.pastDaysSelected();
  }

  revertToPreviousState() {
    this.startDate.set(this.previousStartDate);
    this.endDate.set(this.previousEndDate);
    this.pastDaysSelected.set(this.previousPastDaysSelected);
  }

  isInside(date: NgbDate) {
    return this.endDate() && date.after(this.startDate()) && date.before(this.endDate());
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.startDate()) ||
      (this.endDate() && date.equals(this.endDate())) ||
      date.equals(this.hoveredDate)
    );
  }

  cannotSelect(date: NgbDate) {
    return this.minDate().after(date) || this.maxDate().before(date);
  }

  setDays(days: number) {
    this.pastDaysSelected.set(days);
    this.endDate.set(this.today.before(this.maxDate()) ? this.today : this.maxDate());
    this.startDate.set(this.calendar.getNext(this.today, 'd', -days));
  }

  private toDate(date: NgbDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }
}
