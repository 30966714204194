<aol-map [width]="'100%'" [height]="'100%'" (touchmove)="touchMove($event)">
  <aol-interaction-doubleclickzoom></aol-interaction-doubleclickzoom>
  <aol-interaction-dragpan *ngIf="allowPan"></aol-interaction-dragpan>
  <aol-interaction-pinchzoom></aol-interaction-pinchzoom>
  <aol-view [zoom]="zoom">
    <aol-coordinate [x]="location.longitude" [y]="location.latitude" [srid]="'EPSG:4326'"></aol-coordinate>
  </aol-view>
  <aol-layer-tile>
    <aol-source-osm></aol-source-osm>
  </aol-layer-tile>
  <aol-layer-vector>
    <aol-source-vector>
      <aol-feature>
        <aol-geometry-point>
          <aol-coordinate [x]="location.longitude" [y]="location.latitude" [srid]="'EPSG:4326'"></aol-coordinate>
        </aol-geometry-point>
        <aol-style>
          <aol-style-icon [src]="'/assets/map-marker.png'" [scale]="0.5" [anchorOrigin]="'top-left'"></aol-style-icon>
        </aol-style>
      </aol-feature>
    </aol-source-vector>
  </aol-layer-vector>
</aol-map>

<div class="overlay d-flex flex-row ol-unselectable ol-control">
  <button class="btn-overlay zoom btn btn-secondary btn-sm me-2" (click)="increaseZoom()">
    <i class="fa fa-search-plus"></i>
  </button>
  <button class="btn-overlay zoom btn btn-secondary btn-sm" (click)="decreaseZoom()">
    <i class="fa fa-search-minus"></i>
  </button>
  <div class="ms-auto align-self-end">
    <span class="copyright">&copy;
      <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> {{ 'local_listings.contributors' | translate }}</span>
  </div>
</div>
