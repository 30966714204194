import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { SessionService } from './shared/session.service';
import { AccountService } from './structure/account.service';
import { ExternalToolbarConfig } from './shared/models/external-toolbar-config';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  // TODO: Title should probably match site title.
  public title = 'Reporting';
  public isCollapsed = true;
  public isLoaded = false;
  public externalToolbarConfig = null;
  public hasExternalToolbar = false;
  public underMaintenance = false;

  constructor(private sessionService: SessionService, private accountService: AccountService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    //If the site is under maintenance we should hide the nav bar and other elements normally shown on every page
    if (this.location.path().toLocaleLowerCase().startsWith('/maintenance')) {
      this.underMaintenance = true;
    }

    this.accountService.getSiteConfig()
      .pipe(
        mergeMap(siteConfig => {
          this.sessionService.setSiteConfig(siteConfig, 'site');
          return this.getExternalToolbarConfig();
        })
      )
      .subscribe((externalToolbarConfig: ExternalToolbarConfig) => {
        this.externalToolbarConfig = externalToolbarConfig;
        if (externalToolbarConfig) {
          this.hasExternalToolbar = true;
        }
        this.isLoaded = true;
      });
  }

  getExternalToolbarConfig(): Observable<ExternalToolbarConfig> {
    return this.route.queryParams
      .pipe(
        mergeMap((params: Params) => {
          return this.sessionService.externalToolbarConfig
            .pipe(
              map((config) => {
                let externalToolbarConfig: ExternalToolbarConfig;
                externalToolbarConfig = config;

                // If we have an appId param then we assume we have the rest
                if (params['appId']) {
                  externalToolbarConfig = {
                    appId: params['appId'],
                    accountId: params['accountId'],
                    productNavBarJs: params['productNavBarJs'],
                    productNavBarDataUrl: params['productNavBarDataUrl']
                  };
                }
                return externalToolbarConfig;
              })
            );
        })
      );
  }

  onNavigated() {
    this.isCollapsed = true;
  }
}
