import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { AnalyticsStore } from '../../analytics-store.service';

@Component({
  selector: 'app-keywords-top-50',
  templateUrl: './keywords-top-50.component.html',
  styleUrls: ['./keywords-top-50.component.css']
})
export class KeywordsTop50Component extends BaseComponent implements OnInit {

  public keywordsTop50: number;
  public keywordsTop50ChangePercentage: string;
  public showChange: boolean;

  constructor(private analytics: AnalyticsStore) {
    super();
  }

  ngOnInit() {
    this.analytics.data
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) {
          return;
        }

        this.keywordsTop50 = x.keywordsTop50;
        this.keywordsTop50ChangePercentage = `${x.keywordsTop50ChangePercentage}%`;
        this.showChange = x.keywordsTop50ChangePercentage > 0;
      });
  }
}
