<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body">
    <p>{{ 'sure_disconnect_google_analytics' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-grey" (click)="c(false)">{{'shared.button_cancel' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="c(true)">{{ 'disconnect' | translate }}</button>
  </div>
</ng-template>

<div class="row">
  <div class="col-7">
    <div *ngIf="account && profile" class="card">
      <div class="card-body">
        <h4 class="card-title text-primary custom-tile-header">{{ 'google_analytics' | translate }}</h4>
        <div *ngIf="!account.hasGoogleAnalyticsAccess" class="alert alert-danger">
          <i class="fa fa-exclamation-circle"></i> {{ 'content_account_not_authorized' | translate }}</div>
        <div class="mt-2" *ngIf="!account.hasGoogleAnalyticsAccess || !profile.displayName">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>{{ 'google_multiple_accounts' | translate }}</a>
              <ng-template ngbNavContent>
                <p>{{ 'similar_screen_will_appear_pick_account' | translate }}</p>
                <p class="text-center">
                  <img src="{{ 'image_grant_analytics_access_multiple_accounts' | translate }}" style="max-width: 100%;" />
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>{{ 'google_single_account' | translate }}</a>
              <ng-template ngbNavContent>
                <p>
                  <span>{{ 'similar_screen_will_enter_email_and_password' | translate }}</span>
                </p>
                <p class="text-center">
                  <img src="{{ 'image_grant_analytics_access_single_account' | translate }}" style="max-width: 100%;" />
                </p>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>{{'google_already_logged_in' | translate}}</a>
              <ng-template ngbNavContent>
                <p>
                  <span>{{ 'review_the_information_on_screen' | translate }}</span>
                </p>
                <p class="text-center">
                  <img src="{{ 'image_grant_analytics_access_logged_in' | translate }}" style="max-width: 100%;" />
                </p>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
        <div *ngIf="account.hasGoogleAnalyticsAccess && !!profile.displayName" class="p-4 text-center">
          <div class="alert alert-success">
            <span *ngIf="!!profile.displayName" class="text-bold"><i class="fa fab-google"></i> {{ profile.displayName }}</span>
            <span>({{ profile.profileId }})</span>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button *ngIf="account.hasGoogleAnalyticsAccess" class="btn btn-danger btn-lg"
            (click)="confirmDisconnect(content)">
            <i class="fa fa-times"></i> {{ 'disconnect' | translate }}</button>
          <button *ngIf="!account.hasGoogleAnalyticsAccess" class="btn btn-primary btn-lg" (click)="connect()">
            <i class="fa fab-google"></i> {{ 'content_connect_account' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
