import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// todo: We will want the date format to be culture-specific.
// This formatter changes the display from the default ISO (yyyy-MM-dd) to MM/dd/yyyy.
// Here are some examples of other custom date parsing formatters.
// https://gist.github.com/nrobinaubertin/61ff1c3db355c74f4e56f485b566ab22
// https://plnkr.co/edit/NDBiq59OOYoHpMYgFgOe?p=preview
@Injectable()
export class DatepickerCustomFormatter extends NgbDateParserFormatter {
    public constructor(private datePipe: DatePipe) {
        super();
    }

    public parse(value: string): NgbDateStruct {
        // TODO: This CustomFormatter is only useful for when the input the directive is used on is disabled
        //       add the ability to parse if we need it in another scenario.
        return null;
    }

    public format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }
        return this.datePipe.transform(new Date(date.year, date.month - 1, date.day), 'shortDate');
    }

    private ensureTwoDigits(value: number): string {
        return `0${value}`.slice(-2);
    }

    private isNumber(value: any): boolean {
        return !isNaN(this.asNumber(value));
    }

    private asNumber(value: any): number {
        return parseInt(`${value}`, 10);
    }
}
