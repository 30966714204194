import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SEOModule } from '../../seo';
import { ReloadRequired } from '../../shared/reload-guard';
import { UserModule } from '../../user/user.module';
import { HomepageComponent } from '../homepage/homepage.component';
import { PreloadComponent } from '../preload/preload.component';
import { NoProductsLandingPageComponent } from '../no-products-landing-page/no-products-landing-page.component';
import { BusinessCitationsModule } from '../../business-citations/business-citations.module';

const routes: Routes = [
  {
    path: 'accounts',
    component: PreloadComponent,
    canActivate: [ReloadRequired]
  },
  {
    path: 'no-products',
    component: NoProductsLandingPageComponent
  },
  {
    path: '',
    component: HomepageComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    SEOModule,
    BusinessCitationsModule,
    RouterModule.forRoot(routes, {}),
    UserModule
  ],
  exports: [RouterModule],
  declarations: []
})

export class RoutingModule { }
