import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AnalyticsResult } from './models/analytics-result';

@Injectable()
export class AnalyticsService {
    private baseUrl: string;
    private sourceVisitsStream: Subject<AnalyticsResult> = new Subject();
    private cityVisitsStream: Subject<AnalyticsResult> = new Subject();
    private dailyVisitsStream: Subject<AnalyticsResult> = new Subject();
    private pageVisitsStream: Subject<AnalyticsResult> = new Subject();
    private rollingVisitsStream: Subject<{ monthToDate: AnalyticsResult, yearToDate: AnalyticsResult }> = new Subject();

    constructor(private client: HttpClient) {
        this.baseUrl = environment.baseUrl;
    }

    get visitsBySource(): Observable<AnalyticsResult> {
        return this.sourceVisitsStream.asObservable();
    }

    get visitsByCity(): Observable<AnalyticsResult> {
        return this.cityVisitsStream.asObservable();
    }

    get visitsByDate(): Observable<AnalyticsResult> {
        return this.dailyVisitsStream.asObservable();
    }

    get visitsByPage(): Observable<AnalyticsResult> {
        return this.pageVisitsStream.asObservable();
    }

    get visitsRolling(): Observable<{ monthToDate: AnalyticsResult, yearToDate: AnalyticsResult }> {
        return this.rollingVisitsStream.asObservable();
    }

    public loadVisitsBySource(accountId: number, start: Date, end: Date): void {
        const parameters = this.generateParams(accountId, start, end);
        this.client.get<AnalyticsResult>(`${this.baseUrl}account/${accountId}/google/analytics/sources`, { params: parameters })
            .subscribe(x => {
                this.sourceVisitsStream.next(x);
            });
    }

    public loadVisitsByCity(accountId: number, start: Date, end: Date): void {
        const parameters = this.generateParams(accountId, start, end);
        this.client.get<AnalyticsResult>(`${this.baseUrl}account/${accountId}/google/analytics/cities`, { params: parameters })
            .subscribe(x => {
                this.cityVisitsStream.next(x);
            });
    }

    public loadVisitsByDate(accountId: number, start: Date, end: Date): void {
        const parameters = this.generateParams(accountId, start, end);
        this.client.get<AnalyticsResult>(`${this.baseUrl}account/${accountId}/google/analytics/days`, { params: parameters })
            .subscribe(x => {
                this.dailyVisitsStream.next(x);
            });
    }

    public loadVisitsRolling(accountId: number): void {
        const parameters = new HttpParams().set('accountId', String(accountId));
        this.client.get<{ monthToDate: AnalyticsResult, yearToDate: AnalyticsResult }>(
            `${this.baseUrl}account/${accountId}/google/analytics/rolling`,
            { params: parameters })
            .subscribe(x => {
                this.rollingVisitsStream.next(x);
            });
    }

    public loadVisitsByPage(accountId: number, start: Date, end: Date): void {
        const parameters = this.generateParams(accountId, start, end);
        this.client.get<AnalyticsResult>(`${this.baseUrl}account/${accountId}/google/analytics/pages`, { params: parameters })
            .subscribe(x => {
                this.pageVisitsStream.next(x);
            });
    }

    private generateParams(accountId: number, start: Date, end: Date): HttpParams {
        return new HttpParams()
            .set('accountId', String(accountId))
            .set('start', start.toDateString())
            .set('end', end.toDateString());
    }
}
