import { ContentComponent } from './work-item/content/content.component';
import { LocationComponent } from './work-item/location/location.component';
import { OnsiteRecommendationComponent } from './work-item/onsite-recommendation/onsite-recommendation.component';
import { PreAuditComponent } from './work-item/pre-audit/pre-audit.component';
import { SiteInformationComponent } from './work-item/site-information/site-information.component';
import { ArticleEngagementComponent } from './work-item/article-engagement/article-engagement.component';
import { InfographicSyndicationsComponent } from './work-item/infographic-syndications/infographic-syndications.component';

const typeMap: { id: number, component: any }[] = [
    { id: 1434, component: LocationComponent },
    { id: 602, component: LocationComponent },
    { id: 603, component: ContentComponent },
    { id: 604, component: ContentComponent },
    { id: 615, component: ContentComponent },
    { id: 616, component: ContentComponent },
    { id: 619, component: ContentComponent },
    { id: 620, component: ContentComponent },
    { id: 1005, component: ContentComponent },
    { id: 1003, component: ContentComponent },
    { id: 903, component: ContentComponent },
    { id: 902, component: ContentComponent },
    { id: 911, component: ContentComponent},
    { id: 912, component: ContentComponent},
    { id: 913, component: ContentComponent},
    { id: 914, component: ContentComponent},
    { id: 917, component: ContentComponent},
    { id: 918, component: ContentComponent},
    { id: 919, component: ContentComponent},
    { id: 920, component: ContentComponent},
    { id: 921, component: ContentComponent},
    { id: 1423, component: PreAuditComponent },
    { id: 704, component: OnsiteRecommendationComponent },
    { id: 707, component: OnsiteRecommendationComponent},
    { id: 708, component: OnsiteRecommendationComponent},
    { id: 504, component: SiteInformationComponent },
    { id: 500, component: SiteInformationComponent },
    { id: 502, component: ArticleEngagementComponent },
    { id: 610, component: ContentComponent },
    { id: 850, component: InfographicSyndicationsComponent },
    { id: 600, component: ContentComponent },
    { id: 1551, component: ContentComponent }
];

export function lookup(id: number): any {
    const types = typeMap.filter(x => x.id === id);
    return types.length > 0 ? types[0] as any : null;
}
