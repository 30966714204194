import { Component, Input } from '@angular/core';
import { RequiredActionTypes } from '../../../../shared/models';

@Component({
  templateUrl: './website-access.component.html',
  styleUrls: ['./website-access.component.css']
})
export class WebsiteAccessComponent {
  @Input() requiredAction: { type: RequiredActionTypes; };

  constructor() { }
}
