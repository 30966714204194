<div id="custom-pro-tip" *ngIf="proTips && proTips.length > 0" class="card bg-dark my-3 text-center">
  <div class="card-body">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let row of proTips">
        <h4 id="custom-pro-tip-title" *ngIf="row.title" class="card-title text-white">{{row.title}}</h4>
        <img *ngIf="row.imageUrl" class="img-fluid" src="{{row.imageUrl}}" />
        <h5 id="custom-pro-tip-text" *ngIf="row.body" class="text-muted my-2">{{row.body}}</h5>
        <a id="custom-pro-tip-link" *ngIf="row.hyperlink && row.hyperlinkText" class="btn btn-warning my-3" target="_blank" href="{{row.hyperlink}}">{{row.hyperlinkText}}</a>
        <a id="custom-pro-tip-learn-more" *ngIf="row.hyperlink && !row.hyperlinkText" class="btn btn-warning my-3" target="_blank" href="{{row.hyperlink}}">{{'learn_more' | translate }}</a>
        <hr />
      </ng-template>
    </ngb-carousel>
  </div>
</div>