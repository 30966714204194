/* tslint:disable:no-access-missing-member */
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n, NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from '../../../base.component';
import { LocalizedDatePicker } from '../../../shared/localized-date-picker';
import { SessionService } from '../../../shared/session.service';
import { AnalyticsService } from '../analytics.service';
import { DatepickerCustomFormatter } from './datepicker-custom-formatter';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DatepickerCustomFormatter },
    { provide: NgbDatepickerI18n, useClass: LocalizedDatePicker },
    { provide: NgbDatepickerI18nDefault }
  ]
})
export class DateRangeComponent extends BaseComponent implements OnInit {
  public start: NgbDateStruct;
  public end: NgbDateStruct;
  public isValid = true;
  public errorName: string;

  constructor(private service: AnalyticsService, private sessionService: SessionService, private datePipe: DatePipe) {
    super();
  }

  ngOnInit() {
    this.setDefaults();
    this.applyDates();
  }

  public applyDates(): void {
    this.validate();

    if (!this.isValid) {
      return;
    }

    this.sessionService.account
      .pipe(
        take(1)
      )
      .subscribe(x => {
        if (x) {
          const startDate = this.asDate(this.start);
          const endDate = this.asDate(this.end);

          this.service.loadVisitsByDate(x.id, startDate, endDate);
          this.service.loadVisitsByCity(x.id, startDate, endDate);
          this.service.loadVisitsBySource(x.id, startDate, endDate);
          this.service.loadVisitsByPage(x.id, startDate, endDate);
        }
      });
  }

  private validate(): void {
    const endDate = this.asDate(this.end);
    const startDate = this.asDate(this.start);

    if (!endDate || !startDate) {
      this.isValid = false;
      this.errorName = 'analytics_dates_invalid';
      return;
    }

    const now = new Date(Date.now());
    if (endDate > now || startDate > now) {
      this.isValid = false;
      this.errorName = 'analytics_dates_in_future';
      return;
    }

    if (startDate > endDate) {
      this.isValid = false;
      this.errorName = 'analytics_dates_start_date_invalid';
      return;
    }

    this.isValid = true;
    this.errorName = '';
  }

  private setDefaults(): void {
    const today = new Date(Date.now());
    this.end = { day: today.getDate(), month: today.getMonth() + 1, year: today.getFullYear() };

    const oneMonthBack = moment().subtract(1, 'months');
    this.start = { day: oneMonthBack.get('D'), month: oneMonthBack.get('M') + 1, year: oneMonthBack.get('y') };
  }

  private asDate(date: NgbDateStruct): Date {
    return new Date(this.datePipe.transform(`${date.year}-${date.month}-${date.day}`, 'MM/dd/yyyy'));
  }
}

interface MonthOfYear {
  month: number;
  year: number;
}
