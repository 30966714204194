import { Component, OnInit, Output, Input } from '@angular/core';

import { BaseComponent } from '../../base.component';
import { SessionService } from '../../shared/session.service';
import { ExternalToolbarConfig } from '../../shared/models/external-toolbar-config';

@Component({
  selector: 'app-external-toolbar',
  templateUrl: './external-toolbar.component.html',
  styleUrls: ['./external-toolbar.component.css']
})
export class ExternalToolbarComponent extends BaseComponent implements OnInit {
  constructor(
    public sessionService: SessionService) {
    super();
  }

@Input() config: ExternalToolbarConfig;

ngOnInit() {
    // Had to create the script element from code because Angular will remove all script tags in the html file
    const script = document.createElement('script');
    script.src = '//' + this.config.productNavBarJs;
    script.id = 'external-toolbar';
    script.setAttribute('data-url', this.config.productNavBarDataUrl);
    script.setAttribute('data-account-id', this.config.accountId);
    script.setAttribute('data-app-id', this.config.appId);
    document.body.appendChild(script);

    // Save the toolbar config to the session because we remove the querystring params when page loads
    this.sessionService.setExternalToolBarConfig(this.config);
  }
}
