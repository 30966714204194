import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    public get<T>(key: string): T {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (err) {
            return null;
        }
    }

    public set(key: string, item: any) {
        if (!item) {
            return;
        }

        localStorage.setItem(key, JSON.stringify(item));
    }

    public clear(): void {
        localStorage.clear();
    }
}
