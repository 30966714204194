import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CustomerAccount } from './models/customer-account';
import { CustomerAccountTypes } from './models/customer-account-types';
import { SessionService } from './session.service';

@Injectable()
export class CustomerAccountService {
  private baseUrl: string;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  public getMockPassword(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}customeraccount/getMockPassword`);
  }

  public get(customerAccountTypes: CustomerAccountTypes[]): Observable<CustomerAccount[]> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          if (!x) {
            return of(null);
          }

          return this.http
            .post<CustomerAccount[]>(`${this.baseUrl}customeraccount/get`, {
              'accountId': x.id,
              'customerAccountTypes': customerAccountTypes
            });
        })
      );
  }

  public save(customerAccounts: CustomerAccount[]): Observable<{}> {
    return this.http
      .post(`${this.baseUrl}customeraccount/save`, { 'customerAccounts': customerAccounts });
  }
}
