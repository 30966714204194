import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { fadeInOut, SessionService } from '../../shared/';
import { Account } from '../../shared/models';
import { AnalyticsService } from './analytics.service';

@Component({
  animations: [fadeInOut()],
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent extends BaseComponent implements OnInit {
  public isLoaded = false;
  public isSetUp = false;
  public account: Account;

  constructor( 
    private service: AnalyticsService,
    public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    const isSetUpLoaded = new Subject<void>();
    const accountLoaded = new Subject<void>();

    zip(isSetUpLoaded.asObservable(), accountLoaded.asObservable())
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe(x => {
      this.updateHasGoogleAnalyticsAccess();
    });

    zip(
      this.service.visitsByCity,
      this.service.visitsByDate,
      this.service.visitsByPage,
      this.service.visitsBySource
    )
    .pipe(
      takeUntil(this.unsubscribe)
    )
    .subscribe(x => {
      this.isLoaded = x.every(y => !!y);
      this.isSetUp = x.every(y => y && y.wasSuccessful);
      isSetUpLoaded.next();
    });

    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
        accountLoaded.next();
      });
  }

  private updateHasGoogleAnalyticsAccess() {
    if (this.account.hasGoogleAnalyticsAccess !== this.isSetUp) {
      // Account in Session thought it had a connection, but got an exception when actually downloading data
      this.account.hasGoogleAnalyticsAccess = this.isSetUp;
      this.sessionService.setAccount(this.account);
    }
  }
}
