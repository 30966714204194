import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BaseComponent } from '../../base.component';
import { fadeInOut } from '../../shared';
import { SessionService } from '../../shared/session.service';
import { UserService } from '../../shared/user.service';
import { Account } from '../../shared/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  animations: [fadeInOut()]
})
export class ContactComponent extends BaseComponent implements OnInit {
  public contactForm: UntypedFormGroup;
  public state: 'in' | 'out' = 'out';
  public account: Account;

  constructor(
    public sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      message: ['', Validators.required]
    });

    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });
  }


  public send(event: Event, submitBtn: HTMLButtonElement): void {
    event.preventDefault();
    if (this.contactForm.valid) {
      submitBtn.disabled = true;
      this.userService.contact(
        this.value('name'),
        this.value('email'),
        this.value('phone'),
        this.value('message')
      ).subscribe(() => {
        this.state = 'in';
        this.contactForm.reset();
        setTimeout(() => { this.state = 'out'; }, 9000);
      });
    }
  }

  private value(key: string) {
    return this.contactForm.controls[key].value;
  }
}
