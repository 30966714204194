/*
This structural directive removes entire elements and all of their content,
if the SiteConfig.hiddenContent (found in session) contains a matching key.
The keys are managed through Launchpad.
*/
/* tslint:disable */
import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from './session.service';

@Directive({ selector: '[hideKey]'})

export class HideKey {
  private hasView = false;
  
  constructor(
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionService: SessionService) {}

  @Input() 
  set hideKey(key: string) {
    this.viewContainer.clear();
    this.sessionService.siteConfig
      .subscribe(config => {
        let contentShouldBeHidden = config && config.hiddenContent.indexOf(key) >= 0
        if(!this.hasView && !contentShouldBeHidden) {
          this.viewContainer.createEmbeddedView(this.template);
          this.hasView = true;
        } else if (contentShouldBeHidden && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }
}