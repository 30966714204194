<div class="card">
  <div class="card-body">
    <h4 class="custom-tile-header text-primary mb-3">{{ 'campaign_summary' | translate }}</h4>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <th>{{ 'table_header_date' | translate }}</th>
            <th>{{ 'table_header_contact_type' | translate }}</th>
            <th>{{ 'table_header_note' | translate }}</th>
            <th>{{ 'table_header_inserted_by' | translate }}</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let summary of campaignSummary">
              <tr>
                <td>{{ summary.insertedDate | localDate }}</td>
                <td>{{ summary.contactTypeName }}</td>
                <td>{{ summary.text }}</td>
                <td>{{ summary.userFullName.substr(0, summary.userFullName.indexOf(" ")) }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card-footer text-center">
    <button *ngIf="hasMore" class="btn btn-outline-primary mt-3" (click)="loadSummary()">{{ 'more' | translate }} <i class="fa fa-chevron-down ms-2"></i></button>
  </div>
</div>
