import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared';
import { KeywordAnalytics } from '../shared/models/keyword-analytics';

@Injectable()
export class AnalyticsStore {

    private baseUrl: string;

    constructor(private http: HttpClient, private sessionService: SessionService) {
        this.baseUrl = environment.baseUrl;
    }

    private _stream: BehaviorSubject<KeywordAnalytics> = new BehaviorSubject(null);

    get data(): Observable<KeywordAnalytics> {
        return this._stream.asObservable();
    }

    public reload() {
        this.sessionService.account
            .pipe(
                mergeMap(x => {
                    if (!x) {
                        return of(null);
                    }

                    return this.http.get<KeywordAnalytics>(`${this.baseUrl}account/${x.id}/keyword/analytics`);
                })
            )
            .subscribe(x => {
                this._stream.next(x);
            });
    }
}
