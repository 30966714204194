/* tslint:disable */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sortIcon',
  exportAs: 'SortIcon',
  template: `<i *ngIf="sortColumn !== column" class="fa fa-sort text-muted" aria-hidden="true"></i>
    <i *ngIf="sortColumn === column && sortOrder == 1" class="fa fa-sort-up text-info" aria-hidden="true"></i>
    <i *ngIf="sortColumn === column && sortOrder == -1" class="fa fa-sort-down text-info" aria-hidden="true"></i>`
})
export class SortIcon {
  @Input() column: string;
  @Input() sortColumn: string;
  @Input() sortOrder: number;

  constructor() { }

}
