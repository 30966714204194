import { LocationService } from '../location.service';
import { BaseComponent } from '../../base.component';
import { Component, OnInit, Signal, ViewChild, WritableSignal, computed, effect, signal } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SessionService } from '../../shared/index';
import { LocationSyncHistory } from '../models/location-sync-history';
import { takeUntil } from 'rxjs/operators';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-business-citations-sync-history',
  templateUrl: './business-citations-sync-history.component.html',
  styleUrls: ['./business-citations-sync-history.component.scss'],
  providers: [TranslatePipe]
})
export class BusinessCitationsSyncHistoryComponent extends BaseComponent implements OnInit {

  @ViewChild(NgbAccordionDirective) accordion: NgbAccordionDirective;
  
  syncHistory: LocationSyncHistory[] = [];
  localProfiles: { localProfileId: number, title: string, syncDate: Date }[] = [];
  selectedLocalProfileId: WritableSignal<number> = signal(0);
  selectedHistory: Signal<LocationSyncHistory[]> = computed(() => this.selectedLocalProfileId() ? this.syncHistory.filter(x => x.localProfileId === this.selectedLocalProfileId()) : []);

  constructor(private locationService: LocationService,
    public sessionService: SessionService) {
    super();

    effect(() => {
      if (this.selectedLocalProfileId()) {
        setTimeout(() => {
          this.accordion.collapseAll();
          this.accordion.expand('history_0');
        }, 0);
      }
    });
  }

  ngOnInit() {
    this.locationService
      .getLocationSyncHistory()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(syncHistory => {
        this.selectedLocalProfileId.set(syncHistory.length ? syncHistory[0].localProfileId : this.selectedLocalProfileId());
        let unsortedLocalProfiles: { localProfileId: number, title: string, syncDate: Date }[] = [];
        syncHistory.forEach(history => {
          const snapshot = JSON.parse(history.locationSnapshot);
          history.locationSnapshot = snapshot;
          history.title = `${snapshot.city.toUpperCase()}, ${snapshot.state}`;

          if (!unsortedLocalProfiles.some(x => x.localProfileId === history.localProfileId)) {
            unsortedLocalProfiles.push({ localProfileId: history.localProfileId, title: history.title, syncDate: history.syncDate });
          }
          this.syncHistory.push(history);
        });

        this.localProfiles = unsortedLocalProfiles.sort((a, b) => a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase() ? -1 : 1);
      });
  }


  sortAsProvided(): number {
    return 0;
  }
}
