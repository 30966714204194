<div *ngIf="chartData" class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <h4 class="card-title text-start text-primary custom-tile-header">{{ 'title_action_time_spent_by_month' | translate }}
              <small>
                <helpPopover
                  placement='bottom'
                  html="{{ 'tooltip_action_time_spent_by_month' | translate }}">
                </helpPopover>
              </small>
            </h4>
            <div class="alert alert-info">
              {{ 'campaign_renews_on' | translate:{ nextRenewalDay: nextRenewalDate | ordinal } }}
              <helpPopover
                placement='bottom'
                html="{{ 'tooltip_renewal_example' | translate: {
                  nextRenewalMonth: lastBilledDate | date:'MMMM',
                  nextRenewalDay: lastBilledDate | ordinal,
                  nextRenewalEndMonth: currentPeriodEndDate | date:'MMMM',
                  nextRenewalEndDay: currentPeriodEndDate | ordinal } }}">
              </helpPopover>
            </div>
          </div>
          <div class="d-flex p-2">
            <canvas baseChart [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" [colors]="chartColors"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
