import { Component, OnInit } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { BaseComponent } from '../../../base.component';
import { GuaranteeService } from '../../guarantee.service';
import { GuaranteeAudit } from '../../../shared/models/guarantee-audit';
import * as pattern from 'patternomaly';
import { map, takeUntil } from 'rxjs/operators';
import { zip } from 'rxjs';

@Component({
  selector: 'app-mbg-keyword-target',
  templateUrl: './mbg-keyword-target.component.html',
  styleUrls: ['./mbg-keyword-target.component.scss'],
  providers: [BaseChartDirective]
})
export class MbgKeywordTargetComponent extends BaseComponent implements OnInit {
  public guaranteeAudit: GuaranteeAudit;
  public isLoaded = false;
  public targetPercent: number;

  public data: any[] = [];
  public labels: string[] = ['0'];
  public options: any = {
    responsive: true,
    borderWidth: 10,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        stacked: true,
        display: true,
        ticks: {
          stepSize: 1
        }
      }],
      yAxes: [{
        stacked: true,
        display: false,
      }]
    }
  };
  public chartColors: Array<any> = [];

  private guaranteeAchievedAmount: number;
  private guaranteeRemainingAmount: number;
  private targetAchieved = 1;
  private noRemainingAmount = 0;

  constructor(private guaranteeService: GuaranteeService, private chartColorService: ChartColorService) {
    super();
  }

  ngOnInit() {
    zip(this.guaranteeService.getGuarantee(), this.chartColorService.stackedBar)
      .pipe(
        map(result => {
          const guarantee = result[0];
          const colors = result[1];
          if (!guarantee || !colors) {
            return;
          }
          this.handleGuaranteeAudit(guarantee);
          this.handleChartColors(colors);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  public handleGuaranteeAudit(guaranteeAudit: GuaranteeAudit): void {
    this.guaranteeAudit = guaranteeAudit;

    if (this.guaranteeAudit.numberOfKeywordsOnFirstPage >= this.guaranteeAudit.numberOfKeywordsGuaranteed) {
      this.targetPercent = this.targetAchieved;
      this.guaranteeAchievedAmount = this.guaranteeAudit.numberOfKeywordsGuaranteed;
      this.guaranteeRemainingAmount = this.noRemainingAmount;
    } else {
      this.targetPercent = (this.guaranteeAudit.numberOfKeywordsOnFirstPage / this.guaranteeAudit.numberOfKeywordsGuaranteed);
      this.guaranteeAchievedAmount = this.guaranteeAudit.numberOfKeywordsOnFirstPage;
      this.guaranteeRemainingAmount = this.guaranteeAudit.numberOfKeywordsGuaranteed - this.guaranteeAchievedAmount;
    }

    this.data.push(
      { data: [this.guaranteeAchievedAmount], label: 'First Page'},
      { data: [this.guaranteeRemainingAmount], label: 'Remaining' }
    );

    this.isLoaded = true;
  }

  public handleChartColors(chartColors: Array<any>): void {
    this.chartColors.push(
      { backgroundColor: chartColors[0].backgroundColor, borderColor: chartColors[0].backgroundColor, borderWidth: 3 },
      { backgroundColor: pattern.draw('diagonal-right-left', chartColors[1].backgroundColor) }
    );
  }

}
