import { ViewChild, ViewContainerRef, ComponentFactoryResolver, Component, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { RequiredActionTypes } from '../../../shared/models';
import { RequiredActionService } from '../../../shared/required-action.service';
import { OnsiteRecommendationApprovalComponent } from './onsite-recommendation-approval/onsite-recommendation-approval.component';
import { WebsiteCopyApprovalComponent } from './website-copy-approval/website-copy-approval.component';
import { GooglePinComponent } from './google-pin/google-pin.component';
import { ImplementOnsiteRecommendationsComponent } from './implement-onsite-recommendations/implement-onsite-recommendations.component';
import { ImplementOnsiteCopyComponent } from './implement-onsite-copy/implement-onsite-copy.component';
import { ImplementOnsiteBlogComponent } from './implement-onsite-blog/implement-onsite-blog.component';
import { CompleteOnboardingComponent } from './complete-onboarding/complete-onboarding.component';
import { OnsiteBlogApprovalComponent } from './onsite-blog-approval/onsite-blog-approval.component';
import { WebsiteAccessComponent } from './website-access/website-access.component';
import { SeoContentSurveyComponent } from './seo-content-survey/seo-content-survey.component';
import { takeUntil } from 'rxjs/operators';

const componentsRegistry: { [Type: number]: any; } = {
  [RequiredActionTypes.OnsiteRecommendationApproval]: OnsiteRecommendationApprovalComponent,
  [RequiredActionTypes.OnsiteBlogApproval]: OnsiteBlogApprovalComponent,
  [RequiredActionTypes.WebsiteCopyApproval]: WebsiteCopyApprovalComponent,
  [RequiredActionTypes.CompleteOnboarding]: CompleteOnboardingComponent,
  [RequiredActionTypes.GooglePin]: GooglePinComponent,
  [RequiredActionTypes.ImplementOnsiteRecommendations]: ImplementOnsiteRecommendationsComponent,
  [RequiredActionTypes.ImplementOnsiteCopy]: ImplementOnsiteCopyComponent,
  [RequiredActionTypes.ImplementOnsiteBlog]: ImplementOnsiteBlogComponent,
  [RequiredActionTypes.WebsiteAccess]: WebsiteAccessComponent,
  [RequiredActionTypes.SeoContentSurvey]: SeoContentSurveyComponent
};

@Component({
  selector: 'app-required-actions',
  templateUrl: './required-actions.component.html',
  styleUrls: ['./required-actions.component.css']
})
export class RequiredActionsComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('requiredActionContent', { read: ViewContainerRef }) requiredActionContent: ViewContainerRef;

  public showRequiredActions: boolean;

  constructor(private requiredActionService: RequiredActionService, private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.showRequiredActions = true;
  }

  ngAfterViewInit() {
    this.requiredActionService.requiredActions
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(requiredActions => {
        this.showRequiredActions = requiredActions && requiredActions.length > 0;
        if (this.requiredActionContent) {
          this.requiredActionContent.clear();

          for (const requiredAction of requiredActions) {
            const componentFactory: any = this.componentFactoryResolver.resolveComponentFactory(componentsRegistry[requiredAction.type]);
            const instance: any = this.requiredActionContent.createComponent(componentFactory);
            instance.instance.requiredAction = requiredAction;
          }
          this.changeDetectorRef.detectChanges();
        }
      });
    this.requiredActionService.loadRequiredActions();
  }
}
