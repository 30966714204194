export const environment = {
  production: true,
  baseUrl: 'https://service.mymarketinfo.com/api/mmi/v2/',
  google: {
    clientId: '1061942537623-m7m8rqsnb676l0l5nqk0d3fsgslujk2j.apps.googleusercontent.com',
    callbackUrl: 'https://www.mymarketinfo.com/network/selection/6',
    // tslint:disable-next-line:max-line-length
    scope: encodeURIComponent('https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/userinfo.email'),
    analyticsAccountId: 'UA-108289255-1'
  }
};
