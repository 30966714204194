import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { compose, curry } from 'barely/dist';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { SessionService } from '../../../shared';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { sumAll } from '../../../shared/sum';
import { categories } from '../../categories';
import { WorkService } from '../../work.service';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-total-work-completed',
  templateUrl: './total-work-completed.component.html',
  styleUrls: ['./total-work-completed.component.css'],
  providers: [BaseChartDirective]
})
export class TotalWorkCompletedComponent extends BaseComponent implements OnInit {
  public data: any[] = [];
  public isLoaded = false;
  public labels: string[] = [];
  public options: ChartConfiguration<'bar'>['options'] = {
    maintainAspectRatio: false,
    responsive: true,
  };
  public chartColors: Array<any>;
  public lastBilledDate: Date;
  public nextRenewalDate: Date;
  public currentPeriodEndDate: Date;
  private allCategories = categories;
  private months: MonthSummary[];

  constructor(
    private translate: TranslateService,
    private work: WorkService,
    private chartColorService: ChartColorService,
    private sessionService: SessionService) {
    super();

    // we want seven months of data in case the customer hasn't billed yet in the current cycle
    this.months = this.calculatePreviousMonths(new Date(), 7);
    this.translate.onLangChange
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => this.loadAxisLabels());
  }

  ngOnInit(): void {
    this.chartColorService.stackedBar
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
        this.loadData();
      });
  }

  private calculatePreviousMonths(d: Date, numMonths: number) {
    const results: MonthSummary[] = [];

    let x = 0;
    do {
      const date = new Date(d.getFullYear(), d.getMonth());

      date.setMonth(date.getMonth() - x);

      results.push({
        month: date.getMonth() + 1,
        key: `date_month_${date.getMonth() + 1}`,
        year: date.getFullYear(),
        elapsedMonths: date.getFullYear() * 12 + date.getMonth() + 1
      });

      x++;
    } while (x < numMonths);

    return results.reverse();
  }

  private loadAxisLabels() {
    // show only the previous six months if the customer hasn't billed this month
    const observables = this.billedThisMonth() ?
      this.months.slice(1).map(x => this.getDisplayMonthAndYear(x)) :
      this.months.slice(0, 6).map(x => this.getDisplayMonthAndYear(x));

    return forkJoin(...observables)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((x) => {
        this.labels = [];

        // combine translated month with the year
        for (let i = 0; i < x.length; i++) {
          this.labels.push(`${x[i].monthName} ${x[i].year}`);
        }
      });
  }

  private getDisplayMonthAndYear(monthSummary: MonthSummary): Observable<DisplayMonthAndYear> {
    return this.translate.get(monthSummary.key)
      .pipe(
        map(x => ({ monthName: x, year: monthSummary.year }))
      );
  }

  private loadData() {
    this.work.monthlySummary
      .pipe(
        mergeMap(x => {
          if (!x) {
            this.lastBilledDate = moment().toDate();
            this.nextRenewalDate = moment().toDate();
            this.currentPeriodEndDate = moment().toDate();
            return of([]);
          }

          this.lastBilledDate = moment(x.lastBilledDate).toDate();
          this.nextRenewalDate = moment(x.nextRenewalDate).toDate();
          this.currentPeriodEndDate = moment(x.nextRenewalDate).add(-1, 'day').toDate();
          this.loadAxisLabels();

          const results = this.setData(x.categorySummaryByMonth);
          this.data = results.data;

          const observables = this.data.map(row => this.translate.get(row.label));

          return forkJoin(...observables);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe((results) => {
        if (results.length === 0) { return; }

        for (let i = 0; i < results.length; i++) {
          this.data[i].label = results[i];
        }

        this.isLoaded = true;
      });
  }

  /**
   * Returns the data for the chart.js graph. The graph data must be in the following format for a stacked chart:
   *  Each category is a row in the y-axis ({ data: [2, 4], label: 'LinkBuilding' })
   *  Data point is a point in the x-axis ({ data: [2, 4], label: 'LinkBuilding' })
   * For Example => [{ data: [2, 8], label: 'O' }, { data: [8, 4], label: 'X' }]
   *
   * Generates =>
   * =================
   *          OO
   *    OO    OO
   *    XX    OO
   *    XX    OO
   *    XX    XX
   *    XX    XX
   * =================
   *
   * Note: Missing points must be filled with a zero. In addition, the color array must match the order of the category.
   */
  private setData(results: CategoryGroup[]): { data: any } {
    const data = [];

    const twoDigits = curry(this.toPrecision)(2);
    const sumToHours = compose(twoDigits, this.toHours, sumAll);
    const elapsedMonths = this.billedThisMonth() ?
      this.months.slice(1).map(x => x.elapsedMonths) :
      this.months.slice(0, 6).map(x => x.elapsedMonths);

    Object.keys(this.allCategories).forEach((cat, i) => {
      // Push an empty row in the correct order.
      // We store the translation key for now and we will replace it later.
      // debugger;
      data.push({ data: [], label: this.allCategories[cat].translation, stack: '', backgroundColor: this.chartColors[i].backgroundColor });

      // Get the data for the row by the category and the month.
      for (const month of elapsedMonths) {
        // Find the value for the month and category.
        const monthlyValues = results
          .filter(x => x.key.elapsedMonths <= month
            && x.key.workCategoryGroupId.toUpperCase() === this.allCategories[cat].id.toUpperCase())
          .map(x => x.value);

        const hours = sumToHours(monthlyValues);
        // If we don't have a value, we need to set it to zero.
        data[data.length - 1].data.push(hours);
      }
    });

    return { data: data };
  }

  private toHours(minutes: number) {
    if (!minutes) {
      return 0;
    }

    return minutes / 60;
  }

  private toPrecision(precision: number, hours: number) {
    return hours.toFixed(precision);
  }

  private billedThisMonth() {
    return this.lastBilledDate && this.lastBilledDate.getMonth() === new Date().getMonth();
  }
}

interface CategoryGroup {
  key: CategoryGroupKey;
  value: number;
}

interface CategoryGroupKey {
  // GUID identifier for the work category group
  workCategoryGroupId: string;

  // Total months elapsed since the begining of time (year * 12 + monthNumber)
  elapsedMonths: number;
}

interface MonthSummary {
  key: string;
  month: number;
  year: number;
  elapsedMonths: number;
}

interface DisplayMonthAndYear {
  monthName: string;
  year: number;
}
