import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared';

@Injectable()
export class TermsService {
  private baseUrl: string;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.baseUrl = environment.baseUrl;
  }

  public getUrl(): Observable<string> {
    return this.sessionService.account
      .pipe(mergeMap(x => {
        return this.http.get<string>(`${this.baseUrl}account/${x.id}/terms/url`);
      }));
  }
}
