import { BaseComponent } from '../../../base.component';
import { Component, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';

import { WorkService } from '../../work.service';
import { categories, category, categoryMap } from '../../categories';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { sumAll } from '../../../shared/sum';
import { delay, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-work-history-summary-all-time',
  templateUrl: './work-history-summary-all-time.component.html',
  styleUrls: ['./work-history-summary-all-time.component.css']
})
export class WorkHistorySummaryAllTimeComponent extends BaseComponent implements OnInit {
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  public cats = categories;

  public data: any;
  public hasData = false;
  public isLoading = true;
  public options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return !!categoryMap[context.dataIndex]
              ? ` ${this.translate.instant(categoryMap[context.dataIndex].translation)} ${context.dataset.data[context.dataIndex]}%`
              : '';
          }
        }
      }
    }
  };
  public chartColors: Array<any>;
  public categoryColors: Array<any>;

  constructor(private work: WorkService, private translate: TranslateService, private chartColorService: ChartColorService) {
    super();
  }

  public get results() {
    return !this.isLoading && this.hasData;
  }

  public get noResults() {
    return !this.isLoading && !this.hasData;
  }

  ngOnInit() {
    this.chartColorService.pie
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
      });

    this.chartColorService.chartColors
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.categoryColors = chartColors;
      });

    this.get();

    this.translate
      .onLangChange
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        this.get();
      });
  }

  private get(): void {
    this.work.allTimeSummary
      .pipe(
        mergeMap(x => {
          return !!x ? this.load(x) : EMPTY;
        }),
        delay(1),
        tap(this.forceChartRefresh.bind(this)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private load(values: { key: string, value: number }[]): Observable<{}> {
    if (!values.length) {
      // We don't trust the length to data to determine if we have data.
      // Because we want to show the chart to reserve the DOM space (B+W spinning chart)
      this.hasData = false;
      return of([]);
    }

    const missingCategories = categoryMap
      .filter(x => !values.map(y => y.key.toLowerCase()).includes(x.id.toLowerCase()))
      .map((x) => {
        return { key: x.id, value: 0 };
      });

    values = values.concat(missingCategories);

    this.hasData = true;
    values = values.sort(this.sort.bind(this));
    const allCategories = values.map(x => category(x.key));
    const total = sumAll(values.map(x => x.value));
    this.data = {
      datasets: [{
        data: values.map(x => Math.round(x.value / total * 100)),
        backgroundColor: this.chartColors.map(x => x.backgroundColor)[0]
      }]
    };

    return forkJoin(...allCategories.map(x => this.translate.get(x.translation)));
  }

  private forceChartRefresh(): void {
    this.isLoading = false;

    if (!this.charts) {
      return;
    }

    this.charts.forEach(x => {
      x.ngOnChanges({} as SimpleChanges);
    });
  }

  private sort(x: { key: string, sort: number }, y: { key: string, sort: number }): number {
    const first = category(x.key);
    const second = category(y.key);

    if (first.sort > second.sort) { return 1; }
    if (first.sort < second.sort) { return -1; }
    return 0;
  }
}
