import { Component, OnInit, Input } from '@angular/core';
import { ImplementOnsiteRecommendations } from '../../../../shared/models/required-actions/implement-onsite-recommendations';

@Component({
  selector: 'app-implement-onsite-recommendations',
  templateUrl: './implement-onsite-recommendations.component.html',
  styleUrls: ['./implement-onsite-recommendations.component.css']
})
export class ImplementOnsiteRecommendationsComponent implements OnInit {
  @Input() requiredAction: ImplementOnsiteRecommendations;

  public isDescriptionCollapsed: boolean;
  public isUrlCollapsed: boolean;

  constructor() {}

  ngOnInit() {
    this.isDescriptionCollapsed = true;
    this.isUrlCollapsed = true;
  }
}
