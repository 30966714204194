import { WorkService } from '../../work.service';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-total-work-items-completed',
  templateUrl: './total-work-items-completed.component.html',
  styleUrls: ['./total-work-items-completed.component.scss']
})
export class TotalWorkItemsCompletedComponent extends BaseComponent implements OnInit {

  public allTimeCount: number;
  public sinceLastBillingDateCount: number;
  public showChange: boolean;

  constructor(private translate: TranslateService, private work: WorkService) {
    super();
  }

  ngOnInit(): void {
    this.work.taskCount
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) { return; }

        this.allTimeCount = x.allTimeCount;
        this.sinceLastBillingDateCount = x.sinceLastBillingDateCount;
        this.showChange = this.sinceLastBillingDateCount > 0;
    });
  }
}
