<div class="text-center overlay">
  <div class="card text-white bg-danger">
    <div class="card-header">{{ 'analytics_not_set_up_header' | translate }}</div>
    <div class="card-body">
      <h4 class="card-title">
        <span class="fa fa-exclamation-triangle"></span>
        {{ 'analytics_not_set_up_message' | translate }}
      </h4>
      <p class="card-text">
        {{ 'analytics_not_set_up_description' | translate }}
        <br />{{ 'analytics_not_set_up_call_to_action_message' | translate }}
      </p>
      <a routerLink="/user/profile" class="btn btn-primary">{{ 'analytics_not_set_up_call_to_action_button_text' | translate }}</a>
    </div>
  </div>
</div>