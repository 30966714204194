import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  constructor(private router: Router, private location: Location) { }

  public errorMessage: string;

  ngOnInit() {
    this.errorMessage = history.state['message'];
  }

  public back() {
    this.location.back();
  }
  public home() {
    this.router.navigateByUrl('accounts');
  }
}
