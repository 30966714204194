import { Component, OnInit, Input } from '@angular/core';
import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-article-engagement',
  templateUrl: './article-engagement.component.html',
  styleUrls: ['./article-engagement.component.scss']
})
export class ArticleEngagementComponent implements IWorkItemComponent {
  @Input() value: any;
  @Input() type: number;
}
