import { Component, OnInit } from '@angular/core';
import { GuaranteeService } from '../../guarantee.service';
import { GuaranteeAudit } from '../../../shared/models/guarantee-audit';
import { BaseComponent } from '../../../base.component';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mbg-start-date',
  templateUrl: './mbg-start-date.component.html',
  styleUrls: ['./mbg-start-date.component.scss']
})
export class MbgStartDateComponent extends BaseComponent implements OnInit {
  public guaranteeAudit: GuaranteeAudit;
  public startPending: boolean;
  public isLoaded = false;
  public helpText: string;

  constructor(private guaranteeService: GuaranteeService, private translate: TranslateService) { super(); }

  ngOnInit() {
    this.guaranteeService.getGuarantee()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(guarantee => {
        if (!guarantee) {
          return this.helpText = '';
        }

        this.guaranteeAudit = guarantee;
        this.isLoaded = true;

        const today = new Date();
        this.startPending = !guarantee.startDate || guarantee.startDate > today;

        this.translate.get('guarantee_start_date_help')
          .pipe(
            takeUntil(this.unsubscribe)
          )
          .subscribe(translation => {
            this.helpText = this.startPending ? translation : '';
          });
      });
  }

}
