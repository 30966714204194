<div class="row mt-3 mb-5" [@fadeInOut]="'active'">
  <div class="col-md-7">
    <app-google-account></app-google-account>
    <div class="mt-5 d-flex justify-content-start">
      <div class="btn-toolbar">
        <button *hideKey="'Profile-WebsiteAccess'" class="btn btn-primary me-2" routerLink="/user/website-access">
          {{ 'website_access' | translate }}
        </button>
        <button *hideKey="'Profile-SeoContentSurvey'" class="btn btn-primary me-2"
          routerLink="/user/seo-content-survey">
          {{ 'seo_content_survey.content_survey' | translate }}
        </button>
        <button *hideKey="'Profile-ChangePassword'" class="btn btn-primary me-2" routerLink="/user/change-password">
          {{'content_change_password' | translate}}
        </button>
        <ng-container *ngIf="isEnglishLanguage">
          <app-terms></app-terms>
        </ng-container>
      </div>
    </div>
  </div>
</div>
