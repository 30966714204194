import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { fadeInOut } from '../../../shared';
import { UserService } from '../../../shared/user.service';
import { MatchValidator } from './match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  animations: [fadeInOut()]
})
export class ChangePasswordComponent {
  public changePasswordForm: UntypedFormGroup;
  public state: 'in' | 'out' = 'out';
  public didFail = false;

  constructor(private fb: UntypedFormBuilder, private userService: UserService) {
    const noWhitespacePattern = /^\S*$/;

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^((?=.*[A-Z])(?=.*[a-z]).*){8,}$')]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: MatchValidator.Match('confirmPassword', 'newPassword')
    });
  }

  public change(event: Event) {
    event.preventDefault();

    if (this.changePasswordForm.invalid) {
      return;
    }

    this.userService.changePassword(this.changePasswordForm.value)
      .subscribe(() => {
        this.changePasswordForm.reset();
        this.state = 'in';

        setTimeout(() => {
          this.state = 'out';
        }, 5000);
      });
  }
}
