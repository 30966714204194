import { animate, state, style, transition, trigger } from '@angular/animations';

export function flyInOut() {
    return trigger('flyInOut', [
        transition('void => *', [
            style({ transform: 'translateX(-100%)' }),
            animate(300)
        ]),
        transition('* => void', [
            animate(300, style({ transform: 'translateX(100%)' }))
        ])
    ]);
}

export function fadeInOut() {
    return trigger('fadeInOut', [
        state('in', style({ opacity: 1, transform: 'translateY(10%)' })),
        state('out', style({ opacity: 0, transform: 'translateY(10%)' })),
        transition('in => out', animate('300ms ease-out')),
        transition('out => int', animate('300ms ease-out')),
        transition('void => *', [
            style({ opacity: 0.3, transform: 'translateY(10%)' }),
            animate('300ms ease-out')
        ]),
        transition('* => void', [
            animate('300ms ease-out', style({ opacity: 0, transform: 'translateY(10%)' }))
        ])
    ]);
}

export function fadeAndDrop() {
    return trigger('fadeAndDrop', [
        state('in', style({ opacity: 0, transform: 'translateY(0)' })),
        transition('void => *', [
            style({ transform: 'translateX(-15%)', opacity: 0.5 }),
            animate(300)
        ]),
        transition('* => void', [
            animate(300, style({ opacity: 0, transform: 'translateY(10%)' }))
        ])
    ]);
}

export function addAndRemove() {
    return trigger('addAndRemove', [
        transition('void => *', [
            style({ opacity: 0.5, transform: 'translateY(-50%)' }),
            animate('500ms ease-out')
        ]),
        transition('* => void', [
            animate('400ms ease-out', style({ opacity: 0, transform: 'translateY(-50%) rotateX(-90deg)', perspective: '100px' }))
        ])
    ]);
}
