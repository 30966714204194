<div [@fadeInOut]="'active'">
  <div class="flex-container">
    <div class="flex-box" style="min-width: 68%;padding: 0px !important">
      <div class="flex-container">
        <app-mbg-start-date class="flex-box sm-tile" *hideKey="'Seo-Dashboard-MBGStartDate'"></app-mbg-start-date>
        <app-mbg-keyword-target [hidden]="guaranteePending" class="flex-box sm-tile" *hideKey="'Seo-Dashboard-MBGKeywordTarget'" style="min-width: 60%"></app-mbg-keyword-target>
        <app-top-keyword class="flex-box sm-tile" *hideKey="'Seo-Dashboard-TopRankingKeyword'"></app-top-keyword>
        <app-current-rank class="flex-box sm-tile" *hideKey="'Seo-Dashboard-CurrentRank'"></app-current-rank>
        <app-keywords-top-10 class="flex-box sm-tile" *hideKey="'Seo-Dashboard-KeywordsFirstPage'"></app-keywords-top-10>
        <app-total-keywords-improved class="flex-box sm-tile" *hideKey="'Seo-Dashboard-TotalKeywordsImproved'"></app-total-keywords-improved>
        <app-avg-keyword-rank class="flex-box sm-tile" *hideKey="'Seo-Dashboard-Avg-Keyword-Rank'"></app-avg-keyword-rank>
        <app-online-presence class="flex-box sm-tile" *hideKey="'Seo-Dashboard-OnlinePresence'"></app-online-presence>
        <app-traffic [hidden]="!hasAnalytics" class="flex-box sm-tile" *hideKey="'Seo-Dashboard-Traffic'"></app-traffic>
        <app-total-work-items-completed class="flex-box sm-tile" *hideKey="'Total-Work-Items-Completed'"></app-total-work-items-completed>
        <app-total-work-completed class="flex-box sm-tile" *hideKey="'Total-Work-Completed'" style="min-width: 60%"></app-total-work-completed><br />
      </div>
    </div>
    <div class="flex-box sm-tile" *hideKey="'Seo-Dashboard-RequiredActionsAndProTips'">
      <app-required-actions *hideKey="'Seo-Dashboard-RequiredActions'"></app-required-actions>
      <app-pro-tips *hideKey="'Seo-Dashboard-ProTips'"></app-pro-tips>
    </div>
  </div>
  <div class="flex-container" *ngIf="account; let a">
    <div class="flex-box">
      <div class="card">
        <div class="cardbody">
          <p class="mb-0 text-muted text-center custom-tile-subheader" *hideKey="'Seo-CustomerUrl'">{{ a.url }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #updatePasswordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'update_password' | translate }}</h4>
  </div>
  <div class="modal-body">
    <span>{{ 'password_security_standards' | translate }}</span>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss()" class="btn btn-link">{{ 'password_update_later' | translate }}</button>
    <button (click)="modal.close(true)" class="btn btn-primary">{{ 'update_password' | translate }}</button>
  </div>
</ng-template>