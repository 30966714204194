import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { previousMonths } from '../../../shared/previousMonths';
import { category, categoryMap } from '../../categories';
import { WorkItem, WorkItemsService } from '../../work-items.service';
import { lookup } from '../types';

enum WorkType {
  task = 1,
  ticket = 2,
  systemCheck = 3
}

@Component({
  selector: 'app-work-items',
  templateUrl: './work-items.component.html',
  styleUrls: ['./work-items.component.css'],
  providers: [WorkItemsService]
})
export class WorkItemsComponent extends BaseComponent implements OnInit, AfterViewChecked {
  public categories = categoryMap;
  public category = '00000000-0000-0000-0000-000000000000';
  public date: Date;
  public id: string;
  public items: WorkItem[] = [];
  public periods: Date[];
  public selected: string;

  private needsToScroll = true;
  private total = 0;

  constructor(private work: WorkItemsService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.periods = previousMonths(12);
    this.date = this.work.filter.date;
    this.category = this.work.filter.category;

    this.work.workItems.subscribe(x => {
      if (!x) { return; }

      this.total = x.count;
      this.items = x.items;
      this.items.forEach((item) => item.hasDetails = this.hasDetails(item));
      this.items.filter((item) => item.workCategoryTypeId === 610 || item.workCategoryTypeId === 1551)
        .forEach((item) => this.work.updateFileDetails(item));
    });

    this.needsToScroll = true;

    this.route.fragment
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x) { return; }
        this.id = x;
      });
  }

  get hasMore(): boolean {
    return this.total > this.items.length;
  }

  ngAfterViewChecked() {
    if (!this.needsToScroll) {
      return;
    }

    const subscription =
      this.route.fragment
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe(fragment => {
          setTimeout(() => {
            subscription.unsubscribe();
          }, 200);

          if (!fragment) {
            return;
          }

          const element = document.getElementById(fragment);

          if (element) {
            element.scrollIntoView();
          }

          this.needsToScroll = false;
        });
  }

  public hasDetails(workItem: WorkItem): boolean {
    return !!lookup(workItem.workCategoryTypeId);
  }

  public categoryChanged(value: string) {
    this.category = value;
    this.updateFilter();
  }

  public dateChanged(value: string) {
    const d = new Date(value);
    this.date = d;
    this.updateFilter();
  }

  public isDownload(url: string) {
    return (url || '').toLowerCase().indexOf('s3staticfile') !== -1;
  }

  public isUrl(url: string) {
    return (url || '').toLocaleLowerCase().startsWith('http');
  }

  public more() {
    this.work.more();
  }

  public description(item: WorkItem): string {
    return `${this.key(item)}description`;
  }

  public key(item: WorkItem): string {
    let type: string;

    // note: this dynamically generates translation keys, which must exist in translation files
    // even though they are not referenced explicitly.
    // for example:
    // the 'system' case uses the '{typeid}system' and '{typeid}systemdescription' translation keys.
    // (currently, the only 'system' translation keys numerically start with 1-4.)
    // the 'task' case uses '{typeid}task' and '{typeid}taskdescription', and
    // the 'ticket' case uses '{typeid}ticket" and '{typeid}ticketdescription', respectively.

    switch (item.workTypeId) {
      case (WorkType.task): {
        type = 'task';
        break;
      }
      case (WorkType.ticket): {
        type = 'ticket';
        break;
      }
      case (WorkType.systemCheck): {
        type = 'system';
        break;
      }
    }

    return `${item.workCategoryTypeId}${type}`;
  }

  private updateFilter() {
    this.work.filter = {
      date: this.date,
      category: this.category
    };
  }
}
