import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { timer } from 'rxjs';
import { map, mergeMap, retryWhen } from 'rxjs/operators';

@Injectable()
export class ReloadService {
    private readonly pollSeconds: number = 60;
    private readonly pollUrl = '/reload.json';

    private _needsReload = false;
    private retryAttempts = 0;
    private reloadToken: string;

    constructor(private httpClient: HttpClient) {
        this.startPoll();
    }

    get needsReload(): boolean {
        return this._needsReload;
    }

    private startPoll() {
        timer(0, this.pollSeconds * 1000)
            .pipe(
                mergeMap(() => {
                    const url = this.pollUrl + '?v=' + Date.now();
                    return this.httpClient.get<any>(url);
                }),
                map(x => {
                    this.retryAttempts = 0;
                    if (!this.reloadToken) {
                        this.reloadToken = x.reloadToken;
                    }

                    return this.reloadToken !== x.reloadToken;
                }),
                retryWhen((errors) => {
                    return errors
                        .pipe(
                            mergeMap(() => {
                                this.retryAttempts++;
                                return this.retryAttempts > 10 ? timer(this.pollSeconds * 10 * 1000) : timer(this.pollSeconds * 1000);
                            })
                        );
                })
            )
            .subscribe(x => {
                this._needsReload = x;
            });
    }
}
