import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { State } from './models';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class StateService {
    private baseUrl: string;
    constructor(private http: HttpClient, private sessionService: SessionService) {
        this.baseUrl = environment.baseUrl;
    }

    public getStates(): Observable<State[]> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.get<State[]>(`${this.baseUrl}account/${x.id}/get-states`);
                })
            );
    }
}
