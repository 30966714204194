<div class="card card-default">
  <div class="card-body">
    <h4 class="card-title text-primary custom-tile-header">
      {{titleTranslateKey | translate}}
      <small *ngIf="translationExists(helptextTranslateKey)">
        <helpPopover placement='auto' html="{{ helptextTranslateKey | translate}}"></helpPopover>
      </small>
    </h4>
    <div [formGroup]="credentialForm">
      <div class="form-group">
        <label class="control-label col-md-10">{{ 'login_url' | translate }}</label>
        <div class="col-md-10">
          <div class="input-group">
            <input type="url" class="form-control" formControlName="url"
              [ngClass]="{'is-invalid' : credentialForm.controls['url'].dirty && credentialForm.controls['url'].invalid }" />
            <a target="_blank" [href]="asExternalUrl(credentialForm.controls['url'].value)"
              class="btn btn-primary">{{ 'button_view' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="showPort">
        <label class="control-label col-md-10">
          {{ 'port' | translate }}
          <small>({{ 'optional' | translate }})</small>
        </label>
        <div class="col-md-10">
          <input formControlName="port"
            [ngClass]="{'is-invalid' : credentialForm.controls['port'].dirty && credentialForm.controls['port'].invalid }"
            style="min-width: 55px;" type="number" min="0" step="1" class="form-control" />
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-10">{{'content_login_username' | translate }}</label>
        <div class="col-md-10">
          <input formControlName="username"
            [ngClass]="{'is-invalid' : credentialForm.controls['username'].dirty && credentialForm.controls['username'].invalid }"
            type="text" autocomplete="new-username" class="form-control" />
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-10">{{'content_login_password' | translate }}</label>
        <div class="col-md-10">
          <input formControlName="password"
            [ngClass]="{'is-invalid' : credentialForm.controls['password'].dirty && credentialForm.controls['password'].invalid }"
            type="password" autocomplete="new-password" class="form-control" (focus)="$event.target.select()" />
        </div>
      </div>
    </div>
  </div>
</div>
