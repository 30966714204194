import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../base.component';
import { fadeInOut } from '../../../shared';
import { AccountFragment } from '../../../shared/models';
import { Features } from '../../../shared/models/features.enum';
import { SessionService } from '../../../shared/session.service';
import { AccountService } from '../../account.service';
import { tap, switchMap, takeUntil, flatMap, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-choose-customer',
  templateUrl: './choose-customer.component.html',
  styleUrls: ['./choose-customer.component.css'],
  animations: [fadeInOut()]
})
export class ChooseCustomerComponent extends BaseComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private accountService: AccountService,
    private router: Router) {
    super();
  }

  private defaultAccount = 0;
  private translationToFeatureMap = [
    { value: 'navigation_seo', key: Features.SEO }
  ];

  public accounts: AccountFragment[] = [];
  public defaultPath = '';
  public isLoaded = false;

  get showNoAccountWarning() {
    return this.accounts.length === 0 && this.isLoaded;
  }

  ngOnInit() {
    this.getDefaultAccountId()
      .pipe(
        mergeMap(() => {
          return this.list();
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe((accounts) => {
        this.accounts = accounts;
      });
  }

  public list(): Observable<AccountFragment[]> {
    return this.accountService.list()
      .pipe(
        tap(accountFragments => {
          const numberOfAccounts = (accountFragments || []).length;
          this.sessionService.setCanSwitchAccounts(numberOfAccounts > 1);

          if (numberOfAccounts === 1) {
            this.select(accountFragments[0]);
            return;
          } else if (this.defaultAccount) {
            const results = accountFragments.filter(x => x.id === this.defaultAccount);
            if (results.length) {
              this.select(results[0]);
              return;
            }
          }

          this.isLoaded = true;
        })
      );
  }

  public select(fragment: AccountFragment) {
    let isCsr: boolean;
    return this.sessionService.isCsr.pipe(
      tap((i => isCsr = i)),
      switchMap(() => this.accountService.account(fragment.id)),
      takeUntil(this.unsubscribe)
    ).subscribe(account => {
      if (!account) {
        return;
      }

      // as a CSR you still want access to features even if the customer no longer has the feature
      if (isCsr) {
        for (let i = 0; i < account.previousFeatures.length; i++) {
          if (!account.features.includes(account.previousFeatures[i])) {
            account.features.push(account.previousFeatures[i]);
          }
        }
      }

      this.sessionService.setAccount(account);
      const optionalPath = this.activatedRoute.snapshot.queryParams['path'];

      if (!this.defaultPath) {
        if (account.hasFeature(Features.SEO)) {
          this.defaultPath = '/seo/dashboard';
        } else if (account.hasFeature(Features.BusinessCitations)) {
          this.defaultPath = '/business-citations/local-listings';
        } else if (account.hasFeature(Features.ProfessionalBacklink)) {
          this.defaultPath = '/seo/work-history'
        } else if (!account.features.length) {
          this.defaultPath = '/no-products';
        }
      }

      this.defaultPath = this.sessionService.redirectUrl ? this.sessionService.redirectUrl : this.defaultPath;

      this.sessionService.clearRedirectUrl();

      this.router.navigateByUrl(optionalPath ? optionalPath : this.defaultPath);

      this.isLoaded = true;
    });
  }

  public keysForFeatures(features: Features[]) {
    return features
      .map(x => this.translationToFeatureMap.filter(y => y.key === x).map(z => z.value))
      .reduce((x, y) => x.concat(y));
  }

  private getDefaultAccountId() {
    const yToken = this.activatedRoute.snapshot.queryParams['y'];
    const tToken = this.activatedRoute.snapshot.queryParams['t'];

    if (tToken) {
      // If we have a T token, we can safely assume its an CSR.
      this.sessionService.setIsCsr(true);
      return this.accountService.defaultImpersonation(tToken)
        .pipe(
          tap(x => {
            this.defaultAccount = x;
          })
        );
    }

    return this.accountService.default(yToken)
      .pipe(
        tap(x => {
          this.defaultAccount = x;
        })
      );
  }
}
