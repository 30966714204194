
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class PendingRequestInterceptor implements HttpInterceptor {
    private _requestCount: number;
    private _isLoading: Subject<boolean>;
    private _excludedRequests: string[] = ['reload.json'];

    constructor() {
        this._requestCount = 0;
        this._isLoading = new Subject<boolean>();
    }

    get isLoading(): Observable<boolean> {
        return this._isLoading.asObservable();
    }

    get requestCount(): number {
        return this._requestCount;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isUrlExcluded(req.url)) {
            this._requestCount++;
        }

        if (this.requestCount > 0) {
            this._isLoading.next(true);
        }

        return next.handle(req)
            .pipe(
                map(event => {
                    return event;
                }),
                catchError(err => {
                    return observableThrowError(err);
                }),
                finalize(() => {
                    if (!this.isUrlExcluded(req.url)) {
                        this._requestCount--;
                    }

                    if (this.requestCount === 0) {
                        this._isLoading.next(false);
                    }
                })
            );
    }

    private isUrlExcluded(url: string) {
        for (let i = 0; i < this._excludedRequests.length; i++) {
            if (url.indexOf(this._excludedRequests[i]) >= 0) {
                return true;
            }
        }

        return false;
    }
}

export function PendingRequestInterceptorFactory() {
    return new PendingRequestInterceptor();
}

export let PendingRequestInterceptorFactoryProvider = {
    provide: PendingRequestInterceptor,
    useFactory: PendingRequestInterceptorFactory
};
