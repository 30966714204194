<div class="card" *ngIf="isLoaded">
  <div class="card-body">
    <div class="row justify-content-md-center" [@fadeInOut]="'active'">
      <div *ngIf="showNoAccountWarning">
        <div class="alert alert-danger text-center m-4">{{ 'error_login_nocustomerusers' | translate }}
          <div>
            <button routerLink="/user/contact-us" class="m-3 btn btn-lg btn-danger">
              <span class="fa fa-envelope"></span> {{ 'contact_us' | translate }}</button>
          </div>
        </div>
      </div>
      <div *ngIf="accounts.length" class="col-md-12">
        <h4 class="card-title text-primary custom-tile-header">Select an account</h4>
        <table class="table table-striped">
          <tr *ngFor="let x of accounts">
            <td>
              {{ x.name}}
            </td>
            <td class="d-none d-md-table-cell">
              {{ x.url}}
            </td>
            <td class="text-center">
              <button class="btn btn-sm btn-primary" (click)="select(x)">
                <span class="fa fa-sign-in-alt"></span> {{ 'button_log_in' | translate }}</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>