<div [@fadeInOut]="'active'">
    <div *ngIf="isLoaded">
        <div *ngIf="!isSetUp" class="text-center col-md-6">
            <app-no-analytics-message></app-no-analytics-message>
        </div>
    </div>
    <div [ngClass]="{ 'bg-disabled': !isSetUp }">
        <div class="row">
            <div class="col-md-12">
                <app-date-range></app-date-range>
            </div>
        </div>
        <div class="row mt-2" *hideKey="'Analytics-OrganicTraffic'">
            <div class="col-md-12">
                <app-by-day></app-by-day>
            </div>
        </div>
        <div class="row mt-2" *hideKey="'Analytics-OrganicTrafficByCity'">
            <div class="col-md-12">
                <app-by-city></app-by-city>
            </div>
        </div>
        <div class="row mt-2" *hideKey="'Analytics-TopOrganicTrafficSources'">
            <div class="col-md-12">
                <app-by-source></app-by-source>
            </div>
        </div>
        <div class="row mt-2" *hideKey="'Analytics-TopOrganicTrafficPages'">
            <div class="col-md-12">
                <app-by-page></app-by-page>
            </div>
        </div>
        <div class="row mt-2" *ngIf="account; let a">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <p class="mb-0 text-muted text-center custom-tile-subheader" *hideKey="'Seo-CustomerUrl'">{{ a.url }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>