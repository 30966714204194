<div class="card mt-3" id="forgot-password-card">
  <div class="card-body">
    <div class="container p-4">
      <form class="form-signin p-4" [formGroup]="form" (submit)="save($event)" novalidate>
        <h2 class="form-signin-heading">{{ 'reset_password' | translate }}</h2>
        <div class="form-group">
          <label for="password" class="sr-only">{{ 'content_login_username' | translate }}</label>
          <input type="text" id="password" class="form-control" placeholder="{{ 'content_login_password' | translate }}"
            autocomplete="off" formControlName="password"
            [ngClass]="{'is-invalid' : form.controls['password'].dirty && form.controls['password'].invalid }">
          <div class="invalid-feedback" *ngIf="form.controls.password.errors?.pattern">
            {{ 'error_password_pattern' | translate }}
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword" class="sr-only">{{'content_change_password_confirm_new_password' | translate }}</label>
          <input type="text" id="confirmPassword" class="form-control"
            placeholder="{{'content_change_password_confirm_new_password' | translate }}" autocomplete="off"
            formControlName="confirmPassword"
            [ngClass]="{'is-invalid' : form.controls['confirmPassword'].dirty && form.controls['confirmPassword'].invalid }">
          <div class="invalid-feedback" *ngIf="form.controls.confirmPassword.errors?.Match">
            {{ 'error_password_matching' | translate }}
          </div>
        </div>
        <button class="btn btn-lg btn-primary w-100" type="submit" [disabled]="form.invalid">{{ 'reset_password' | translate }}</button>
      </form>

      <div class="alert alert-success text-center" *ngIf="success">
        {{ 'success' | translate }}
         <a routerLink="/log-in">-> {{ 'button_log_in' | translate }}</a>
      </div>
    </div>
  </div>
</div>
