import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../base.component';
import { CustomerAccount } from '../../shared/models/customer-account';
import { CustomerAccountTypes } from '../../shared/models/customer-account-types';

@Component({
  selector: 'app-website-credentials',
  templateUrl: './website-credentials.component.html',
  styleUrls: ['./website-credentials.component.css']
})
export class WebsiteCredentialsComponent extends BaseComponent {
  @Input()
  public credential: CustomerAccount;

  @Input()
  public credentialForm: UntypedFormGroup;

  public get showPort(): boolean {
    return this.credential.customerAccountTypeId === CustomerAccountTypes.FTP;
  }

  public get titleTranslateKey(): string {
    return 'website_access_type.' + CustomerAccountTypes[this.credential.customerAccountTypeId].toLowerCase();
  }

  public get helptextTranslateKey(): string {
    return 'website_access_type.helptext.' + CustomerAccountTypes[this.credential.customerAccountTypeId].toLowerCase();
  }

  public translationExists(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }

  public asExternalUrl(url: string): string {
    url = url || '';
    if (url.toLowerCase().indexOf('http') !== 0) {
      return `http://${url}`;
    }

    return url;
  }

  constructor(private translateService: TranslateService) {
    super();
  }
}
