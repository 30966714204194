import { Component, OnInit, Input } from '@angular/core';
import { RequiredActionService } from '../../../../shared/required-action.service';
import { BaseComponent } from '../../../../base.component';
import { ImplementOnsiteCopy } from '../../../../shared/models/required-actions/implement-onsite-copy';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../../../shared';

@Component({
  selector: 'app-implement-onsite-copy',
  templateUrl: './implement-onsite-copy.component.html',
  styleUrls: ['./implement-onsite-copy.component.css']
})
export class ImplementOnsiteCopyComponent extends BaseComponent implements OnInit {
  @Input() requiredAction: ImplementOnsiteCopy;

  public isDescriptionCollapsed: boolean;
  public isUrlCollapsed: boolean;
  public isCsr: boolean;

  constructor(private requiredActionService: RequiredActionService,
    private sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.isDescriptionCollapsed = true;
    this.isUrlCollapsed = true;
    this.sessionService.isCsr.subscribe(isCsr => this.isCsr = isCsr);
  }

  public downloadAndClose() {
    window.open(this.requiredAction.hyperlink);
    return this.requiredActionService.completeTicket(this.requiredAction.completeTicketPayload)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.requiredActionService.loadRequiredActions();
      });
  }
}
