import { BaseComponent } from '../../../base.component';
import { Component, computed, input } from '@angular/core';
import { LocationService } from '../../location.service';
import { takeUntil } from 'rxjs/operators';
import { BusinessCitationStatuses } from '../../models/enums/business-citation-statuses.enum';

@Component({
  selector: 'app-google-my-business-status',
  templateUrl: './google-my-business-status.component.html',
  styleUrls: ['./google-my-business-status.component.scss']
})
export class GoogleMyBusinessStatusComponent extends BaseComponent {

  googleMyBusinessStatus = input<number>();
  hasChartData = input<boolean>();
  locationId = input<number>();

  googleConnectionProcessing = computed<boolean>(() => this.googleMyBusinessStatus() === BusinessCitationStatuses.Updating && !this.hasChartData());
  noGoogleConnection = computed<boolean>(() => this.googleMyBusinessStatus() === BusinessCitationStatuses.Unknown);
  noDataProvided = computed<boolean>(() => this.googleMyBusinessStatus() === BusinessCitationStatuses.Synced && !this.hasChartData());
  isVisible = computed<boolean>(() => this.googleConnectionProcessing() || this.noGoogleConnection() || this.noDataProvided());

  constructor(private locationService: LocationService) {
    super();
  }

  refresh() {
    window.location.reload();
  }
  
  connectGoogleBusiness(): void {
    this.locationService.connectGoogleMyBusiness(this.locationId())
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        window.open(x, '_blank', 'popup=true,width=800,height=600');
      });
  }
}
