import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared';

@Injectable()
export class OnlinePresenceService {
  private baseUrl: string;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  public summary(): Observable<{ monthToDate: number, lifeToDate: number }> {
    return this.sessionService.account
      .pipe(
        mergeMap((x) => {
          return !!x ?
            this.http.get<{ monthToDate: number, lifeToDate: number }>((`${this.baseUrl}account/${x.id}/online_presence/summary`)) :
            of(null);
        })
      );
  }
}
