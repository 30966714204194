<div class="container-fluid">
  <div class="row row-offcanvas row-offcanvas-left" [class.active]="!isCollapsed" *ngIf="!underMaintenance">
    <app-navigation style="z-index: 5000" [hasExternalToolbar]="hasExternalToolbar" class="col-6 col-md-2 bg-dark sidebar sidebar-offcanvas" (navigated)="onNavigated()"></app-navigation>
    <app-external-toolbar *ngIf="hasExternalToolbar" [config]="externalToolbarConfig"></app-external-toolbar>
    <main id="main" class="col-12 ms-sm-auto col-md-10 pt-0 pe-0 ps-0 main-canvas" role="main">
      <app-loading-overlay></app-loading-overlay>
      <app-header *ngIf="isLoaded" [(isCollapsed)]="isCollapsed"></app-header>
      <div *ngIf="isLoaded" class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  <router-outlet *ngIf="underMaintenance"></router-outlet>
</div>
