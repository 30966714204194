


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { KeywordRank, KeywordRankHistory } from './models';
import { SessionService } from './session.service';

@Injectable()
export class KeywordRankService {
  private baseUrl: string;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.baseUrl = environment.baseUrl;
  }

  public getKeywordRanks(): Observable<KeywordRank[]> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ?
            this.http.get<KeywordRank[]>(`${this.baseUrl}account/${x.id}/keyword/ranks`) :
            of([]);
        })
      );
  }

  public getKeywordRankHistory(rank: KeywordRank): Observable<KeywordRankHistory[]> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ? this.http
            .post<KeywordRankHistory[]>(
              `${this.baseUrl}account/${x.id}/keyword/ranks/history`,
              {
                KeywordId: rank.keywordId, AreaId: rank.areaId, IsAreaFirst: rank.isAreaFirst
              }) : of([]);
        })
      );
  }
}
