import { Injectable } from '@angular/core';

@Injectable()
export class ChartLabelService {

  public getPreviousMondays(count: number): string[] {
    let dates: string[] = [];
    let date = new Date();
    let interval = 1;

    while (dates.length < count) {
      date.setDate(date.getDate() - interval);
      if (date.getDay() === 1) { // Monday
        dates.push((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
        interval = 7;
      }
    }

    return dates.reverse();
  }
}
