/* tslint:disable */
import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'helpPopover',
  exportAs: 'HelpPopover',
  template: `<ng-template style="display: inline;" #popover>
  <div class="text-muted" style="padding: 10px;" [innerHTML]="html"></div>
  </ng-template>
      <span class="fa fa-question-circle text-muted ms-1 small align-text-top" [ngbPopover]="popover" [placement]="placement" triggers="mouseenter:mouseleave"></span>`
})
export class HelpPopoverComponent {
  @Input() html: string;
  @Input() placement: 'auto' | 'top' | 'bottom' | 'left' | 'right';
}
