<div class="card text-center" style="height: 15.5rem;">
    <div class="card-body d-flex flex-column">
        <h4 *ngIf='title' class="card-title text-primary text-center custom-tile-header" style="min-height:2em;">
            {{title}}
            <small *ngIf="help">
                <helpPopover placement='auto' html="{{help}}"></helpPopover>
            </small>
        </h4>
        <div>
            <div>
                <p *ngIf='subtitle' class="text-muted mb-0 mt-0 custom-tile-subheader">{{subtitle}}
                    <helpPopover *ngIf="subtitle && subHelp" html="{{ subHelp }}" placement="bottom"></helpPopover>
                </p>
                <span *ngIf='!subtitle'>&nbsp;</span>
            </div>
            <span *ngIf="!bodyTemplate && val != '-101'" class="tile-value text-success">
                <span *ngIf="icon" class="fa fa-{{icon}} me-2 text-success c-fa custom-tile-icon"></span>{{val | undefinedNumber}}</span>
        </div>
        <!-- TODO: only show above if body is not here -->
        <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
        <div *ngIf="showChange === true" class="text-muted mt-auto">
            <!-- If showChange is true, text should always be green -->
            <span class="text-success">
                <span class="fa fa-caret-up me-2"></span>
                {{change}} {{ 'mtd' | translate }}
            </span>
        </div>
    </div>
</div>

<ng-template #popContent>
    <ng-container *ngTemplateOutlet="helpTemplate"></ng-container>
</ng-template>
