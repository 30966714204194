import { AbstractControl, ValidatorFn, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';

export default class Password {
  private static minLength = 8;
  private static hasNumberRegex: RegExp = /\d/;
  private static hasCapitalCaseRegex: RegExp = /[A-Z]/;
  private static hasLowerCaseRegex: RegExp = /[a-z]/;
  private static hasSpecialCharRegex: RegExp = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  static validators: ValidatorFn[] = [
    Validators.required,
    Validators.minLength(Password.minLength),
    Password.patternValidator(Password.hasNumberRegex, { hasNumber: true }),
    Password.patternValidator(Password.hasCapitalCaseRegex, { hasCapitalCase: true }),
    Password.patternValidator(Password.hasLowerCaseRegex, { hasSmallCase: true }),
    Password.patternValidator(Password.hasSpecialCharRegex, { hasSpecialCharacters: true }),
  ];

  static valid(password: string): boolean {
    return new UntypedFormControl(password, Validators.compose(Password.validators)).valid;
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
}
