<div class="card">
  <div class="card-body">
    <h4 class="card-title custom-tile-header text-primary d-inline me-3">{{ 'top_organic_pages' | translate }}</h4>
    <span class="custom-tile-subheader d-inline">{{ 'top_organic_pages_desc' | translate }}</span>
    <div *ngIf="hasData" class="mt-3">
      <div class="row">
        <div class="col-md-6">
          <canvas baseChart height="170"
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [legend]="false"
            [colors]="chartColors"
            [chartType]="'doughnut'">
          </canvas>
        </div>
        <div class="col-md-6">
          <div *ngFor="let label of chartLabels; let i = index">
            <h6><i [ngStyle]="{ 'color': chartColors[0].backgroundColor[i] }" class="fa fa-circle"></i> {{ label }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
