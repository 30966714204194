<div class="card mt-4">
    <div class="card-body">
      <h4 class="text-success">{{ 'local_listings.title_business_citations_for' | translate}} {{ locationBusinessCitation.location.displayName }} <small><helpPopover style="margin-top: 4px;" placement="auto" html="{{ 'local_listings.status_tooltip' | translate }}"></helpPopover></small></h4>
      <div *hideKey="'BusinessCitations-LocationBusinessCitationsStats'" class="flex-container text-center">
        <div class="flex-box" [ngbPopover]="totalListingsTooltip" placement="bottom" triggers="mouseenter:mouseleave" style="border-right: 1px solid #aaa">
          <h2>{{ totalCitationCount }}</h2>
          <h5>{{ 'local_listings.status_total' | translate }}</h5>
        </div>
        <div class="flex-box" [ngbPopover]="syncStatusTooltip" placement="bottom" triggers="mouseenter:mouseleave">
          <h2>{{ locationBusinessCitation.synced }}</h2>
          <div class="flex-icon-title">
            <i class="fa fa-check-circle text-success" style="margin: 0px 5px 7px 0px"></i>
            <h5>{{ 'local_listings.status_synced' | translate }}</h5>
          </div>
        </div>
        <div class="flex-box" [ngbPopover]="submittedStatusTooltip" placement="bottom" triggers="mouseenter:mouseleave">
          <h2>{{ locationBusinessCitation.submitted }}</h2>
          <div class="flex-icon-title">
            <i class="fa fa-check-circle text-success" style="margin: 0px 5px 7px 0px"></i>
            <h5>{{ 'local_listings.status_submitted' | translate }}</h5>
          </div>
        </div>
        <div class="flex-box" [ngbPopover]="updatingStatusTooltip" placement="bottom" triggers="mouseenter:mouseleave">
          <h2>{{ locationBusinessCitation.updating }}</h2>
          <div class="flex-icon-title">
            <i class="fa fa-history text-success" style="margin: 0px 5px 7px 0px"></i>
            <h5>{{ 'local_listings.status_updating' | translate }}</h5>
          </div>
        </div>
      </div>
      <div *hideKey="'BusinessCitations-LocationBusinessCitationsIcons'" class="p-1 d-flex flex-wrap justify-content-around">
        <ng-container *ngFor="let businessCitation of locationBusinessCitation.businessCitations">
          <ng-container *ngIf="businessCitation.isVisible">
          <div class="m-1">
          <ng-container *ngIf="businessCitation.status !== BusinessCitationStatuses.Synced && businessCitation.isGoogleMyBusiness">
            <ng-template #googleMyBusinessPopContent>{{ 'local_listings.tooltip_google_my_business_not_synced' | translate }}</ng-template>
              <div class="text-center p-1" [ngbPopover]="googleMyBusinessPopContent"  placement="right" container="body" triggers="mouseenter:mouseleave">
                <img class="providerIcon" *ngIf="!businessCitation.url" src="{{assetPath}}{{ businessCitation.publisherId.toLowerCase() }}-error.png" alt="{{businessCitation.publisherId }}"/>
            </div>
          </ng-container>
          <div class="text-center p-1" [ngbPopover]="popover" placement="right left auto" triggers="mouseenter:mouseleave">
            <a *ngIf="businessCitation.url" href="{{ businessCitation.url }}" target="_blank">
                <img class="providerIcon hoverableProviderIcon" src="{{assetPath}}{{ businessCitation.publisherId.toLowerCase() }}.png" alt="{{businessCitation.publisherId }}"/>
            </a>
            <img class="providerIcon" *ngIf="!businessCitation.url" src="{{assetPath}}{{ businessCitation.publisherId.toLowerCase() }}.png" alt="{{businessCitation.publisherId }}"/>
          </div>
          <ng-template style="display: inline;" #popover>
            <strong>{{businessCitation.directoryName}}</strong>
            <div>
              <div>{{businessCitation.directoryTypeString}}</div>
              <div>{{businessCitation.statusString}}</div>
              <div *ngIf="businessCitation.url">{{ 'local_listings.directory_link_available' | translate }} </div>
              <div *ngIf="!businessCitation.url">{{ 'local_listings.directory_link_unavailable' | translate }}</div>
            </div>
          </ng-template>
          </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>