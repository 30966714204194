import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { fadeInOut } from '../../../shared';
import { TermsService } from '../../terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
  animations: [fadeInOut()]
})
export class TermsComponent extends BaseComponent implements OnInit {
  public url: string;

  constructor(private terms: TermsService) {
    super();
  }

  ngOnInit() {
    this.terms.getUrl()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(url => {
        this.url = url;
      });
  }
}
