import { Component, Input, OnInit } from '@angular/core';

import { IWorkItemComponent } from '../models/work-item-component';

@Component({
  selector: 'app-onsite-recommendation',
  templateUrl: './onsite-recommendation.component.html',
  styleUrls: ['./onsite-recommendation.component.css']
})
export class OnsiteRecommendationComponent implements IWorkItemComponent, OnInit {
  @Input() public value: any;
  @Input() public type: number;

  public h1New = '';
  public h1Old = '';

  private h1Pattern = new RegExp('<h1.+?</h1>', 'i');
  private hTagPattern = new RegExp('<h2|<h3|<h4|<h5|<h6|</h2>|</h3>|</h4>|</h5>|</h6>', 'i');
  private tagRemovalPattern = new RegExp('<([^>]*)>', 'ig');

  ngOnInit() {
    this.h1New = this.h1Pattern.test(this.value.h1New)
      ? this.removeH1Tags(this.h1Pattern.exec(this.value.h1New)[0])
      : this.value.h1New.trim();

    const containsH1 = this.h1Pattern.test(this.value.h1Old);
    const containsOtherHTags = this.hTagPattern.test(this.value.h1Old);

    if (containsH1) {
      this.h1Old = this.removeH1Tags(this.h1Pattern.exec(this.value.h1Old)[0]);
    } else if (!containsH1 && !containsOtherHTags) {
      this.h1Old = this.value.h1Old.trim();
    }
  }

  private removeH1Tags(value: string): string {
    return value.replace(this.tagRemovalPattern, '');
  }
}
