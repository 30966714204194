import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';

import { BaseComponent } from '../../../base.component';
import { AnalyticsService } from '../analytics.service';
import { DataPoint } from '../models/data-point';
import { ChartColorService } from '../../../shared/chart-colors.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-by-day',
  templateUrl: './by-day.component.html',
  styleUrls: ['./by-day.component.css'],
  providers: [TranslatePipe]
})
export class ByDayComponent extends BaseComponent implements OnInit {
  public hasData = false;
  public chart: BaseChartDirective;
  public chartData: Array<any> = [{ data: [], label: '' }];
  public chartLabels: Array<string> = [];
  public chartOptions: any = {
    pointStyle: 'circle',
    responsive: true,
    tooltips: { enabled: true },
    tension: 0,
    elements: { point: { radius: 3 } },
    scales: {
      xAxes: [{
        display: true,
        ticks: { fontSize: 9 }
      }],
      yAxes: [{ display: true }]
    }
  };

  public chartColors: Array<any>;

  constructor(private service: AnalyticsService, private datePipe: DatePipe, private translatePipe: TranslatePipe,
    private chartColorService: ChartColorService) {
    super();
   }

  ngOnInit() {
    this.getVisitsByDay();

    this.chartColorService.filledLineWithPoints
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        const lineChartProperties = {
          maintainAspectRatio: false,
          pointBackgroundColor: '#fff',
          pointHoverColor: 'rgba(148,159,177,1)',
          pointBorderWidth: 1,
          pointStyle: 'circle',
          pointRadius: 3,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        };

          this.chartColors = [ {...chartColors[0], ...lineChartProperties}];
      });
  }

  private getVisitsByDay(): void {
    this.service.visitsByDate
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x || !x.wasSuccessful) {
          return;
        }

        this.loadData(x.data);
      });
  }

  private loadData(dataPoints: Array<DataPoint>): void {
    // This data refresh is a little hacky, but dynamic data/label refresh isn't supported natively.
    // The solution courtesy: https://github.com/valor-software/ng2-charts/issues/547
    this.hasData = false;
    this.chartData = [{ data: dataPoints.map(y => y.value), label: this.translatePipe.transform('visits_by_day') }];

    const dashRegex = new RegExp('-', 'g');
    this.chartLabels = dataPoints.map(y => this.datePipe.transform(new Date(y.key.replace(dashRegex, '/')), 'shortDate'));
    setTimeout(() => this.hasData = true, 100);
  }
}
