import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { GoogleService } from '../..';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ensureDomain, queryParams } from '../oAuthUtils';
import { throwError } from 'rxjs';
import { map, mergeMap, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-google-exchange',
  templateUrl: './google-exchange.component.html',
  styleUrls: ['./google-exchange.component.scss']
})
export class GoogleExchangeComponent extends BaseComponent implements OnInit {
  public error: string;

  constructor(
    private google: GoogleService,
    private translate: TranslateService,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    queryParams()
      .pipe(
        takeWhile(params => {
          return ensureDomain(params);
        }),
        map(params => {
          const hasError = params['error'];
          return hasError ? null : params['code'];
        }),
        mergeMap((code) => {
          return !!code ? this.google.complete(code) : throwError(this.translate.instant('error_connecting_account'));
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.router.navigateByUrl('user/google/accounts');
      }, err => {
        this.router.navigateByUrl('user/profile');
      });
  }
}
