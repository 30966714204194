import { SiteConfig } from '../shared/models/site-config';


import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Account, AccountFragment } from '../shared/models';
import { SessionService } from '../shared/session.service';
import { OnsiteRecommendationSettings } from '../shared/models/onsite-recommendation-settings';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AccountService {
  private baseUrl: string;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.baseUrl = environment.baseUrl;
  }

  public list(): Observable<AccountFragment[]> {
    return this.http
      .get(`${this.baseUrl}account/list`)
      .pipe(
        map((accountFragment: AccountFragment[]) => {
          return accountFragment;
        })
      );
  }

  public account(accountId: number): Observable<Account> {
    const query = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<Account>(`${this.baseUrl}account/account`, { params: query });
  }

  public getSiteConfig(): Observable<SiteConfig> {
    return this.http.get<SiteConfig>(`${this.baseUrl}account/config`);
  }

  public default(token: string): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrl}account/`, { Token: token });
  }

  public defaultImpersonation(token: string): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrl}account/impersonation`, { Token: token });
  }

  public onsiteRecommendationSettings(): Observable<OnsiteRecommendationSettings> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return this.http.get<OnsiteRecommendationSettings>(`${this.baseUrl}account/${x.id}/onsiterecommendationsettings`);
        })
      );
  }
}
