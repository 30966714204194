import { BaseComponent } from '../../../base.component';
import { Location } from '../../models/location';
import { Component, Input, OnInit } from '@angular/core';
import { SiteConfig, State } from '../../../shared/models';
import { LocationService } from '../../location.service';
import { SessionService, fadeInOut } from '../../../shared';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

enum Countries {
  US = 1,
  CA = 40,
  MX = 141,
  FR = 76,
  HU = 101
}

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
  providers: [TranslatePipe],
  animations: [fadeInOut()]
})
export class LocationCardComponent extends BaseComponent implements OnInit {

  @Input() states: State[];
  @Input()
  set location(location: Location) {
    this._location = location;
    this.isEditMode = false;
    this.setupForm();
  }

  siteConfig: SiteConfig;
  editLocationForm: UntypedFormGroup;
  isEditMode: boolean = false;
  state: 'in' | 'out' = 'out';

  showContactUs: boolean = false;

  private _location: Location;
  private _editLocation: Location;

  constructor(private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private translatePipe: TranslatePipe,
    private sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.sessionService.siteConfig
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((siteConfig) => {
        this.siteConfig = siteConfig;
        if (this.siteConfig && this.siteConfig.hiddenContent) {
          const contactUsHideKey = 'HEADER-CONTACTUS';
          this.showContactUs = !this.siteConfig.hiddenContent.some(x => x.toUpperCase() === contactUsHideKey);
        }
      });
  }

  toggleEditMode(isEdit: boolean) {
    this.isEditMode = isEdit;

    if (!this.isEditMode) {
      this.setupForm();
    } else {
      this.editLocationForm.enable();
    }
  }

  stateChanged(selection: any) {
    this._editLocation.state = selection.selectedOptions[0].innerText;
  }

  save(event: Event) {
    event.preventDefault();

    this.editLocationForm.get('phone').markAsDirty();
    this.editLocationForm.get('website').markAsDirty();
    this.editLocationForm.get('address').markAsDirty();
    this.editLocationForm.get('address2').markAsDirty();
    this.editLocationForm.get('city').markAsDirty();
    this.editLocationForm.get('stateId').markAsDirty();
    this.editLocationForm.get('zip').markAsDirty();

    this.editLocationForm.get('phone').updateValueAndValidity();
    this.editLocationForm.get('website').updateValueAndValidity();
    this.editLocationForm.get('address').updateValueAndValidity();
    this.editLocationForm.get('address2').updateValueAndValidity();
    this.editLocationForm.get('city').updateValueAndValidity();
    this.editLocationForm.get('stateId').updateValueAndValidity();
    this.editLocationForm.get('zip').updateValueAndValidity();

    if (this.editLocationForm.invalid) {
      return;
    }

    this._editLocation.phone = this.editLocationForm.controls['phone'].value;
    this._editLocation.website = this.editLocationForm.controls['website'].value;
    this._editLocation.address = this.editLocationForm.controls['address'].value;
    this._editLocation.address2 = this.editLocationForm.controls['address2'].value;
    this._editLocation.city = this.editLocationForm.controls['city'].value;
    this._editLocation.stateId = this.editLocationForm.controls['stateId'].value;
    this._editLocation.zip = this.editLocationForm.controls['zip'].value;

    this.locationService.saveLocation(this._editLocation)
      .subscribe(() => {
        this._location.phone = this._editLocation.phone;
        this._location.website = this._editLocation.website;
        this._location.address = this._editLocation.address;
        this._location.address2 = this._editLocation.address2;
        this._location.city = this._editLocation.city;
        this._location.state = this._editLocation.state;
        this._location.stateId = this._editLocation.stateId;
        this._location.zip = this._editLocation.zip;
        this._location.displayName = `${this._location.city.toUpperCase()}, ${this._location.state}`;
        this.toggleEditMode(false);
        this.state = 'in';

        setTimeout(() => {
          this.state = 'out';
        }, 5000);
    });
  }

  addressLine2Exists() {
    return this._location.address2 !== null && this._location.address2 !== '';
  }

  private setupForm() {
    this._editLocation = Object.assign({}, this._location);
    const noPOBoxPattern = /^((?!(P\.?\s?O\.?\sBox)).)*$/i;
    const zipPattern = this.getPostalCodePattern(this._location.countryId);

    this.editLocationForm = this.formBuilder.group({
      phone: [this._editLocation.phone, [Validators.required, Validators.minLength(10), Validators.maxLength(20) ]],
      website: [this._editLocation.website, [Validators.maxLength(350)]],
      address: [this._editLocation.address, [Validators.required, Validators.maxLength(255), Validators.pattern(noPOBoxPattern) ]],
      address2: [this._editLocation.address2, [Validators.maxLength(255)]],
      city: [this._editLocation.city, [Validators.required, Validators.maxLength(255) ]],
      stateId: [this._editLocation.stateId, [Validators.required ]],
      zip: [this._editLocation.zip, [Validators.required, Validators.maxLength(10), Validators.pattern(zipPattern) ]]
    });

    this.editLocationForm.disable();
  }

  private getPostalCodePattern(countryId: number): RegExp {
    let usZipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    let canadaZipPattern = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}[\s]{1}\d{1}[A-Z]{1}\d{1}$/i;
    let fourDigitsPattern = /\d{4}/;
    let fiveDigitsPattern = /\d{5}/;
    let anythingPattern = /(.*?)/;

    switch (countryId) {
      case Countries.US:
        return usZipPattern;
      case Countries.CA:
        return canadaZipPattern;
      case Countries.MX:
        return usZipPattern;
      case Countries.FR:
        return fiveDigitsPattern
      case Countries.HU:
        return fourDigitsPattern
      default:
        return anythingPattern;
    }
  }
}
