export enum LanguageAbbreviations {
  english = 'en',
  englishAU = 'en-AU',
  englishUK = 'en-UK',
  spanish = 'es',
  deutsch = 'de',
  french = 'fr',
  frenchCanada = 'fr-CA',
  netherlands = 'nl',
  suomi = 'fi'
}
