import { Component, OnInit, Input } from '@angular/core';
import { OnsiteBlogApproval } from '../../../../shared/models/required-actions/onsite-blog-approval';

@Component({
  selector: 'app-onsite-blog-approval',
  templateUrl: './onsite-blog-approval.component.html',
  styleUrls: ['./onsite-blog-approval.component.css']
})
export class OnsiteBlogApprovalComponent implements OnInit {
  @Input() requiredAction: OnsiteBlogApproval;

  public isDescriptionCollapsed: boolean;

  constructor() { }
  ngOnInit() {
    this.isDescriptionCollapsed = true;
  }
}
