import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../base.component';
import { fadeInOut } from '../../shared/animations';
import { KeywordRankService } from '../../shared/keyword-rank.service';
import { KeywordRank, Account } from '../../shared/models/';
import { TranslatePipe } from '@ngx-translate/core';
import { SessionService } from '../../shared/index';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsStore } from '../analytics-store.service';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-seo-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.css'],
  animations: [fadeInOut()],
  providers: [DatePipe, TranslatePipe]
})
export class KeywordsComponent extends BaseComponent implements OnInit {
  public keywordRanks: KeywordRank[] = [];
  public sortColumn = 'currentRank';
  public sortOrder = 1;
  public someKeywordsConvertedToHalo = false;
  public someKeywordsHaveSnippets = false;
  public account: Account;

  constructor(
    private analytics: AnalyticsStore,
    private keywordRankService: KeywordRankService,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.analytics.reload();

    this.keywordRankService
      .getKeywordRanks()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        this.keywordRanks = x;
        this.someKeywordsConvertedToHalo = x.some(y => y.hasBeenActive);
        this.someKeywordsHaveSnippets = x.some(y => y.includesFeaturedSnippet);
      });

    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });
  }

  public sort(column: string): void {
    const order: number = this.sortColumn === column ? -this.sortOrder : 1;
    this.sortOrder = order;
    this.sortColumn = column;

    this.keywordRanks.sort((obj1, obj2) => {
      if (this.sortColumn === 'firstPageUrl') {
        return this.sortIfExists(obj1, obj2, (x) => x.firstPageUrl, this.sortOrder);
      } else if (typeof (obj1[this.sortColumn]) === 'string') {
        return this.sortAlphabetically(obj1, obj2, (x) => x[this.sortColumn] as string, this.sortOrder);
      }

      return this.sortNumerically(obj1, obj2, (x) => x[this.sortColumn] as number, this.sortOrder);
    });
  }

  public export() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: 'local',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: [
        this.translatePipe.transform('table_header_type'),
        this.translatePipe.transform('table_header_keyword'),
        this.translatePipe.transform('table_header_start_date'),
        this.translatePipe.transform('table_header_start_rank'),
        this.translatePipe.transform('table_header_current_rank'),
      ]
    };

    const data: any = this.keywordRanks.map(x => {
      return {
        type: x.isTracking
          ? this.translatePipe.transform('keyword_type_tracking') + (!!x.hasBeenActive ? ' *' : '')
          : this.translatePipe.transform('keyword_type_main'),
        keyword: x.keyword,
        startDate: x.startDate ? this.datePipe.transform(x.startDate, 'yMd') : '',
        startRank: x.startRank,
        currentRank: x.currentRank
      };
    });

    data.push({ type: '* ' + this.translatePipe.transform('keyword_was_active') });

    // tslint:disable-next-line
    new ngxCsv(data, 'keyword-ranks', options);
  }

  private sortAlphabetically<T>(item1: T, item2: T, value: (y: T) => string, requestedOrder: number) {
    const first = value(item1).toLowerCase();
    const second = value(item2).toLowerCase();

    if (!first && !second) { return 0; }

    return (first < second) ? -requestedOrder : (first > second) ? requestedOrder : 0;
  }

  private sortIfExists<T>(item1: T, item2: T, value: (y: T) => any, requestedOrder: number) {
    const first = !!value(item1);
    const second = !!value(item2);

    if (first && second) { return 0; }

    return first && !second ? requestedOrder : -requestedOrder;
  }

  private sortNumerically<T>(item1: T, item2: T, value: (y: T) => number, requestedOrder: number) {
    const first = value(item1);
    const second = value(item2);

    if (!first && !second) { return 0; }

    return (first < second) ? -requestedOrder : (first > second) ? requestedOrder : 0;
  }
}
