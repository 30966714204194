<div class="card">
  <div class="card-body p-2">
    <form class="row mt-1">
      <div class="col ms-1 mb-1">
        <div class="input-group">
          <input class="form-control" readonly placeholder="{{ 'start' | translate }}..." name="dt1" [(ngModel)]="start" ngbDatepicker #dt1="ngbDatepicker">
          <button class="btn btn-success" (click)="dt1.toggle()" type="button"><i class="fa fa-calendar-alt"></i></button>
        </div>
      </div>
      <div class="col ms-1 mb-1">
        <div class="input-group">
          <input class="form-control" readonly placeholder="{{ 'end' | translate }}..." name="dt2" [(ngModel)]="end" ngbDatepicker #dt2="ngbDatepicker">
          <button class="btn btn-success" (click)="dt2.toggle()" type="button"><i class="fa fa-calendar-alt"></i></button>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary w-100 ms-1 mb-1" (click)="applyDates()">{{ 'button_view' | translate }}</button>
      </div>
    </form>
    <div *ngIf="!isValid">
      <span class="bold text-danger">{{ errorName | translate }}</span>
    </div>
  </div>
</div>
