import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from '../../../base.component';
import { SessionService } from '../../../shared';
import { GoogleService } from '../../google.service';
import { Profile } from '../models/profile';
import { Account } from '../../../shared/models/account';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-google-account',
  templateUrl: './google-account.component.html',
  styleUrls: ['./google-account.component.css']
})
export class GoogleAccountComponent extends BaseComponent implements OnInit {
  public account: Account;
  public profile: Profile;

  constructor(private modal: NgbModal, private google: GoogleService, public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.load();
  }

  public connect() {
    this.google.connect();
  }

  public confirmDisconnect(content: ElementRef) {
    this.modal.open(content)
      .result
      .then((result: boolean) => {
        if (!result) {
          return;
        }

        this.disconnect();
      });
  }

  private disconnect() {
    this.google
      .disconnect()
      .subscribe(() => {
        this.account.hasGoogleAnalyticsAccess = false;
        this.sessionService.setAccount(this.account);
      });
  }

  private load() {
    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });

    this.google.account()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(profile => {
        if (profile) {
          this.profile = profile;
        }
      });
  }
}
