<div class="list-group-item action" *hideKey="'Seo-Dashboard-RequiredAction-ImplementOnsiteCopy'">
    <div class="d-flex flex-wrap mb-2">
      <div class="mb-1 text-danger custom-required-actions-item-title" style="min-width: 100%">
        <i class="fa fa-exclamation-circle fa-2x text-danger float-start pe-2"></i>{{ 'ra_implement_onsite_copy' | translate }}
      </div>
      <div class="text-muted ms-4 mb-2" style='min-width: 100%'>
        <small>
          <span *ngIf="isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
            <i class="fa fa-plus-circle customer-required-actions-text"></i> {{ 'view_details' | translate }}
          </span>
          <span *ngIf="!isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
            <i class="fa fa-minus-circle customer-required-actions-text"></i> {{'ra_implement_onsite_copy_desc' | translate: {date: requiredAction.date | date:'shortDate'} }}
          </span>
        </small>
      </div>
      <div class="text-muted ms-4 mb-2" style='min-width: 100%'>
        <small>
          <span *ngIf="isUrlCollapsed" (click)="isUrlCollapsed = !isUrlCollapsed" class="clickable customer-required-actions-text font-italic">
            <i class="fa fa-plus-circle customer-required-actions-text"></i> {{ 'URL' | translate }}
          </span>
          <span class="clickable customer-required-actions-text font-italic" *ngIf="!isUrlCollapsed" (click)="isUrlCollapsed = !isUrlCollapsed">
            <i class="fa fa-minus-circle customer-required-actions-text"></i> {{ requiredAction.displayUrl }}
          </span>
        </small>
      </div>
      <div class="ms-auto" style="min-width: 38%" *hideKey="'Seo-Dashboard-RequiredAction-ImplementOnsiteCopy-Button'">
        <button *ngIf="requiredAction.hyperlink" (click)='downloadAndClose()' target="_blank" class="btn btn-primary btn-sm align-self-start w-100" [disabled]="isCsr">
          {{ 'download_and_close' | translate }}
        </button>
      </div>
    </div>
  </div>
