import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Token, User } from './models';
import { SessionService } from './session.service';
import { map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class UserService {
  private baseUrl: string;

  constructor(private http: HttpClient, private sessionService: SessionService) {
    this.baseUrl = environment.baseUrl;
  }

  accessTokenLogin(token: string): Observable<User> {
    return this.exchange(token)
      .pipe(
        mergeMap(() => {
          return this.user();
        })
      );
  }

  nonceLogin(token: string): Observable<User> {
    return this.nonceExchange(token)
      .pipe(
        mergeMap(() => {
          return this.user();
        })
      );
  }

  impersonatedLogin(token: string): Observable<User> {
    return this.impersonateExchange(token)
      .pipe(
        mergeMap(() => {
          return this.user();
        })
      );
  }

  changePassword(form: ChangePasswordForm): Observable<{}> {
    return this.sessionService.user
      .pipe(
        mergeMap(x => {
          return this.http
            .post(`${this.baseUrl}user/password/change`, {
              Email: x.username,
              CurrentPassword: form.currentPassword,
              NewPassword: form. newPassword,
              ConfirmNewPassword: form.confirmNewPassword
            });
        })
      );
  }

  contact(name: string, email: string, phone: string, message: string) {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return !!x ?
            this.http.post(`${this.baseUrl}user/contact`, { AccountId: x.id, Name: name, Email: email, Phone: phone, Message: message }) :
            of(null);
        })
      );
  }

  forgot(email: string): Observable<{}> {
    return this.http
      .post(`${this.baseUrl}user/forgot-password`, { Email: email });
  }

  resetPassword(email: string, resetToken: string, password: string, confirmPassword: string): Observable<any> {
    return this.http.post(`${this.baseUrl}user/password/reset`, {
      email,
      resetToken,
      password,
      confirmPassword
    });
  }

  login(username: string, password: string): Observable<User> {
    return this.token(username, password)
      .pipe(
        mergeMap(() => {
          return this.user();
        })
      );
  }

  private exchange(token: string): Observable<string> {
    return this.http
      .post(`${this.baseUrl}user/login/exchange`, { Token: token })
      .pipe(
        map(this.parse.bind(this)),
        map(this.setToken.bind(this))
      );
  }

  private nonceExchange(token: string): Observable<string> {
    return this.http
      .post(`${this.baseUrl}user/login/exchange/nonce`, { Token: token })
      .pipe(
        map(this.parse.bind(this)),
        map(this.setToken.bind(this))
      );
  }

  private impersonateExchange(token: string): Observable<string> {
    return this.http
      .post(`${this.baseUrl}user/login/exchange/impersonate`, { Token: token })
      .pipe(
        map(this.parse.bind(this)),
        map(this.setToken.bind(this))
      );
  }

  private parse(res: Token): Token {
    if (res.isError) {
      throw res.error;
    }

    return res;
  }

  private token(username: string, password: string): Observable<string> {
    return this.http
      .post(`${this.baseUrl}user/login`, { Username: username, Password: password })
      .pipe(
        map(this.parse.bind(this)),
        map(this.setToken.bind(this))
      );
  }

  private setToken(token: Token): string {
    this.sessionService.setToken(token);
    return token.accessToken;
  }

  private user(): Observable<User> {
    return this.http
      .get(`${this.baseUrl}user`)
      .pipe(
        map((user: User) => {
          this.sessionService.setUser(user);
          return user;
        })
      );
  }
}

export interface ChangePasswordForm { currentPassword: string; newPassword: string; confirmNewPassword: string; }
