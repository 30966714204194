import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../../base.component';
import { sum } from '../../../shared/sum';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsResult } from '../../analytics/models/analytics-result';

@Component({
  selector: 'app-traffic',
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.css']
})
export class TrafficComponent extends BaseComponent implements OnInit {
  public totalVisits: number;
  public MTD: number;
  public showChange: boolean;

  constructor(private service: AnalyticsService) {
    super();
  }

  ngOnInit() {
    this.getTotalRollingVisits();
  }

  public getTotalRollingVisits(): void {
    this.service.visitsRolling
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((x: { yearToDate: AnalyticsResult, monthToDate: AnalyticsResult }) => {
        if (!x || !x.yearToDate.wasSuccessful || !x.monthToDate.wasSuccessful) {
          return;
        }

        const sumYearToDate = sum(y => parseInt(y.value, 10), x.yearToDate.data);
        const sumMonthToDate = sum(y => parseInt(y.value, 10), x.monthToDate.data);
        this.MTD = sumMonthToDate;
        this.showChange = sumMonthToDate !== 0;
        this.totalVisits = sumYearToDate;
      });
  }
}
