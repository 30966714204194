export class HttpError extends Error {
    __proto__: Error;
    message: string;
    displayMessageKey: string;
    constructor(message: string, displayMessageKey: string) {
        const proto = new.target.prototype;
        super();
        this.__proto__ = proto;
        this.message = message;
        this.displayMessageKey = displayMessageKey;
    }
}
