import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Base class for components that have subscriptions.
 * This includes a strategy for cleaning up memory usage.
 * We use strategy 3 from this blog http://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/.
 */
@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    protected unsubscribe = new Subject<void>();

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
