import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from '.';
import { LanguageAbbreviations } from './models/language-abbreviations.enum';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  constructor(private session: SessionService) { }

  transform(value: any, format: string) {
    if (!value) { return ''; }

    if (!format) {
      // these locales do not use double digit "days"
      switch (this.session.locale) {
        case LanguageAbbreviations.english:
          format = 'MM/dd/yy';
          break;
        case LanguageAbbreviations.spanish:
          format = 'dd/MM/yy';
          break;
        case LanguageAbbreviations.suomi:
          format = 'dd.MM.yy';
          break;
        default:
          format = 'shortDate';
          break;
      }
    }

    return formatDate(value, format, this.session.locale);
  }

}
