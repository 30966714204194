export enum RequiredActionTypes {
    CompleteOnboarding = 1,
    GooglePin = 2,
    ImplementOnsiteBlog = 3,
    ImplementOnsiteCopy = 4,
    ImplementOnsiteRecommendations = 5,
    OnsiteBlogApproval = 6,
    OnsiteRecommendationApproval = 7,
    WebsiteCopyApproval = 8,
    WebsiteAccess = 9,
    SeoContentSurvey = 10
}

