<div class="row">
    <div class="card mt-2 col-md-6 offset-md-3">
        <div class="card-body text-center">
            <i class="fa fa-cogs fa-5x"></i>
            <h1>Website Under Maintenance</h1>
            <div>
                <strong>Estimated Duration: </strong>
                <span>{{minutes}}</span>
            </div>
            <br />
            <p>
                We're performing a system update at the moment and will be back up as soon as possible. We apologize for any inconvenience this may cause.
            </p>
        </div>
    </div>
</div>