import * as moment from 'moment';

export function previousMonths(months: number) {
    const results = [];
    let x = 0;
    do {
        const d = moment().startOf('month');
        d.subtract(x, 'months');
        results.push(d.toDate());
        x++;
    } while (x < months);

    return results;
}

export function addMonth(date: Date, value: number) {
    const d = moment(date);
    d.add(1, 'months');
    return d.toDate();
}
