import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  private _type: number;

  public name: string;
  public description: string;

  constructor() { }

  get type(): number {
    return this._type;
  }

  @Input()
  set type(val: number) {
    this._type = val;
    this.name = `${this.type}task`;
    this.description = `${this.type}taskdescription`;
  }
}
