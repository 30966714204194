import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../../base.component';
import { AnalyticsService } from '../analytics.service';
import { DataPoint } from '../models/data-point';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-by-page',
  templateUrl: './by-page.component.html',
  styleUrls: ['./by-page.component.css']
})
export class ByPageComponent extends BaseComponent implements OnInit {
  public hasData = false;
  public chartData: Array<any> = [{ data: [], label: '' }];
  public chartLabels: Array<string> = [];
  public chartOptions: any = {
    responsive: true,
    tooltips: { enabled: true },
    legend: { position: 'right' }
  };
  public chartColors: Array<any>;

  constructor(private service: AnalyticsService, private chartColorService: ChartColorService) {
    super();
   }

  ngOnInit() {
    this.chartColorService.pie
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
      });
    this.getVisitsByPage();
  }

  private getVisitsByPage(): void {
    this.service.visitsByPage
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        if (!x || !x.wasSuccessful) {
          return;
        }

        this.loadData(x.data);
      });
  }

  private loadData(dataPoints: Array<DataPoint>): void {
    this.hasData = false;
    this.chartData = [{ data: dataPoints.map(y => y.value), label: 'Visits by Page' }];
    this.chartLabels = dataPoints.map(y => y.key);
    setTimeout(() => this.hasData = true, 0);
  }
}
