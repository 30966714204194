import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as StackTrace from 'stacktrace-js';

import { UnauthorizedError } from './shared/errors/unauthorized-error';
import { LogService } from './shared/log.service';
import { HttpError } from './shared/errors/http-error';

@Injectable()
export class AppExceptionHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  public handleError(error): any {
    if (error instanceof UnauthorizedError) {
      return;
    }

    const router = this.injector.get(Router);
    const logger = this.injector.get(LogService);
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const logMessage = `${url} - ${error.message ? error.message : error.toString()}`;
    const displayMessageKey = (error instanceof HttpError) ? error.displayMessageKey : undefined;

    StackTrace.fromError(error)
      .then(stackframes => {
        const stackString = stackframes
          .splice(0, 20)
          .map((sf) => {
            return sf.toString();
          }).join(' ');

        logger.logError({ message: logMessage, stackTrace: stackString });
        router.navigateByUrl('error', {state: { 'message': displayMessageKey }});
        super.handleError(error);
      })
      .catch((err) => {
        logger.logError({ message: logMessage, stackTrace: '' });
        router.navigateByUrl('error', {state: { 'message': displayMessageKey }});
        super.handleError(error);
      });
  }
}
