import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CompleteOnboarding } from '../../../../shared/models/required-actions/complete-onboarding';

@Component({
  selector: 'app-complete-onboarding',
  templateUrl: './complete-onboarding.component.html',
  styleUrls: ['./complete-onboarding.component.css']
})
export class CompleteOnboardingComponent implements OnInit {
  @Input() requiredAction: CompleteOnboarding;

  public isDescriptionCollapsed: boolean;
  private redirectPath = '/user/contact-us';

  constructor(private router: Router) { }
  ngOnInit() {
    this.isDescriptionCollapsed = true;
  }

  public redirect() {
    this.router.navigate([this.redirectPath]);
  }
}
